import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from './Footer.js';
import NavigationBar from './NavigationBar.js';
import { Center } from '@chakra-ui/react';
import { FaArrowRightLong } from "react-icons/fa6";
import {  AGENT, BUYER } from '../utils/constants.js';

import './HomePage.css';
import './Generic.css';

function HomePage(props) {

    const navigate = useNavigate();

    const [userTypeView, setUserTypeView] = useState(BUYER);
    const [displayToggleButtons, setDisplayToggleButtons] = useState(false); 

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 275) {
                setDisplayToggleButtons(true);
            } else {
                setDisplayToggleButtons(false);
            }
        })
    });
    
    const ToggleButtons = () => {
        return (
            <div className="home_page__toggle_buttons" style={{
                backgroundColor: "transparent !important",
                borderRadius: "25px",
                position: "fixed",
                right: "30px",
                top: "30px",
                visibility: displayToggleButtons === true ? "block" : "hidden",
                opacity: displayToggleButtons === true ? "1.0" : "0.0",
            }}>
                <div className="home_page__toggle_user_type_button"
                    onMouseDown={() => setUserTypeView(BUYER)}
                    style={{
                        backgroundColor: userTypeView === BUYER ? "#05b96b" : "",
                        color: userTypeView === BUYER ? "white" : ""
                    }}
                >
                    {BUYER}
                </div>
                <div className="home_page__toggle_user_type_button"
                    onMouseDown={() => setUserTypeView(AGENT)}
                    style={{
                        backgroundColor: userTypeView === AGENT ? "#05b96b" : "",
                        color: userTypeView === AGENT ? "white" : ""
                    }}
                > 
                    {AGENT}
                </div>
            </div>
        );
    }

    return (
        <div style={{
            backgroundColor: "#f6f6f6",
        }}>
            <div className="home-page">
                <NavigationBar isUserPage={false} userType={userTypeView}/>
                <ToggleButtons/>
                <div className="home_page__main_box">
                    <p className="home_page__main_title">
                        How can we supercharge your Real Estate journey?
                    </p>
                    <p className="home_page__main_subtitle">
                        The marketplace to connect buyers and agents
                    </p>
                    <Center style={{
                        margin: "15px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                            float: "left",
                        }}>
                            <div className={"home_page__user_type_button hover" + (userTypeView === BUYER ? " positive_button" : " negative_button")}
                                onMouseDown={() => setUserTypeView(BUYER)}
                            >
                                {BUYER}
                            </div>
                            <div className={"home_page__user_type_button hover" + (userTypeView === AGENT ? " positive_button" : " negative_button")}
                                onMouseDown={() => setUserTypeView(AGENT)}
                            > 
                                {AGENT}
                            </div>
                        </div>
                    </Center>
                    <div style={{
                        display: "flex",
                        margin: "30px 0px 0px 0px",
                        width: "100%",
                    }}>
                        <img alt="background_image" className="home_page__background_image" src={userTypeView === BUYER ? require("../Images/HomePageBuyerDashboardBackdrop.png") : require("../Images/HomePageAgentDashboardBackdrop.png")}></img>
                    </div>
                </div>
                <Center className="home_page__subtitle_parent_block">
                    {
                        userTypeView === BUYER ?
                        <div className="home_page__subtitle_block">
                            <p className="home_page__subtitle_colored">
                                Simplify
                            </p>
                            <p className="home_page__subtitle_black">
                                the home buying process
                            </p>
                        </div>
                        :
                        <div className="home_page__subtitle_block">
                            <p className="home_page__subtitle_colored">
                                Customer outreach
                            </p>
                            <p className="home_page__subtitle_black">
                                like never before
                            </p>
                        </div>
                    }
                </Center>
                <Center style={{
                    margin: "0px 0px 0px 0px",
                }}>
                    <div className="home_page__card_parent_box">
                        {
                            userTypeView === BUYER ?
                            <>
                                <Center className="home_page__card_box">
                                    <img alt="property_icon" src={require('../Images/HomePagePanelHouseIcon.png')} className="home_page__card_box_icon"/>
                                    <p className="home_page__card_box_text">
                                        Add your properties and manage them throughout the process
                                    </p>
                                </Center>
                                <Center className="home_page__card_box">
                                    <img alt="agent_icon" src={require('../Images/HomePagePanelAgentIcon.png')} className="home_page__card_box_icon"/>
                                    <p className="home_page__card_box_text">
                                        Connect and work with agents directly
                                    </p>
                                </Center>
                                <Center className="home_page__card_box">
                                    <img alt="model_icon" src={require('../Images/HomePagePanelModelIcon.png')} className="home_page__card_box_icon"/>
                                    <p className="home_page__card_box_text">
                                        Utilize our powerful proprietary models to help you make the best decision
                                    </p>
                                </Center>
                            </> :
                            <>
                            <Center className="home_page__card_box">
                                <img alt="users_icon" src={require('../Images/HomePagePanelUsersIcon.png')} className="home_page__card_box_icon"/>
                                <p className="home_page__card_box_text">
                                    Reach hundreds of buyers within your area all at once
                                </p>
                            </Center>
                            <Center className="home_page__card_box">
                                <img alt="offer_icon" src={require('../Images/HomePagePanelOfferIcon.png')} className="home_page__card_box_icon"/>
                                <p className="home_page__card_box_text">
                                    Submit competitive offers to buyers to stand out
                                </p>
                            </Center>
                            <Center className="home_page__card_box">
                                <img alt="lightning_icon" src={require('../Images/HomePagePanelLightningIcon.png')} className="home_page__card_box_icon"/>
                                <p className="home_page__card_box_text">
                                    Supercharge your career and take it to the next level
                                </p>
                            </Center>
                        </>
                        }
                    </div>
                </Center>
                <Center 
                    onMouseDown={() => navigate("/auth/signup")}
                    className="home_page__start_button_parent"
                >
                    <p className="home_page__start_button_text">
                        Ready to start? Sign Up
                    </p>
                    <FaArrowRightLong style={{
                        color: "white",
                        height: "40px",
                        margin: "5px 0px 0px 10px",
                        width: "40px"
                    }}/>
                </Center>
            </div>
            <Footer/>
        </div>

    );
}

export default HomePage;