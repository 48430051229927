export const AGENT = "Agent";
export const BUYER = "Buyer";
export const BOTH = "Both";

export const FIRST_NAME = "first_name";
export const LAST_NAME = "last_name";
export const EMAIL = "email";
export const PHONE_NUMBER = "phone_number";
export const USER_TYPE = "user_type";
export const PROFILE_PICTURE_URL = "profile_picture_url";

export const ID = "id";
export const FIRST_TIME_BUYER = "first_time_buyer";
export const CREDIT_SCORE = "credit_score";
export const MAX_PRICE = "max_price";
export const MIN_PRICE = "min_price";
export const PRE_APPROVED = "pre_approved";
export const MOVE_BEFORE_DATE = "move_before_date";
export const IS_VERIFIED = "is_verified";

// REST Methods.
export const DELETE = 'delete';
export const POST = 'post';
export const GET = 'get';
export const PUT = 'put';
export const PATCH = 'patch';

export const DEFAULT_GET_TIMEOUT = 10000;
export const DEFAULT_DELETE_TIMEOUT = 10000;
export const DEFAULT_POST_TIMEOUT = 15000;
export const DEFAULT_PATCH_TIMEOUT = 15000;
export const DEFAULT_PUT_TIMEOUT = 10000;

// Common fields for forms.
export const FORM_FIRST_NAME = "first_name";
export const FORM_LAST_NAME = "last_name";
export const FORM_EMAIL = "email";
export const FORM_PHONE_NUMBER = "phone_number";
export const FORM_PASSWORD = "password";
export const FORM_CONFIRM_PASSWORD = "confirm_password";
export const FORM_USER_TYPE = "user_type";

// Loan form fields.
export const LOAN_TYPE = "loan_type";
export const PREAPPROVAL_AMOUNT = "preapproval_amount";
export const DOWN_PAYMENT_PERCENT = "down_payment_percent";
export const INTEREST_RATE = "interest_rate";
export const APR = "apr";
export const POINTS = "points";
export const FULLY_INDEXED_INTEREST_RATE = "fully_indexed_interest_rate";
export const UPFRONT_MIP = "upfront_mip";
export const IS_DEFAULT = "is_default";

// Different Loan Types.
export const THIRTY_YEAR_FIXED = "ThirtyYearFixed"
export const FIFTEEN_YEAR_FIXED = "FifteenYearFixed"
export const FIVE_ONE_ARM = "FiveOneArm"
export const SEVEN_ONE_ARM = "SevenOneArm"
export const TEN_ONE_ARM = "TenOneArm"
export const THIRTY_YEAR_FHA = "ThirtyYearFHA"

// Different property types.
export const APARTMENT = 'Apartment';
export const SINGLE_FAMILY_HOME = 'SingleFamilyHome';
export const TOWNHOME = 'Townhome';
export const DUPLEX = 'Duplex';
export const MULTIPLEX = 'Multiplex';
export const CONDOMINIUM = 'Condominium';
export const TRAILER = 'Trailer';
export const MANUFACTURED = 'Manufactured';

// Property form fields.
export const ADDRESS_LINE_ONE = "address_line_one";
export const ADDRESS_LINE_TWO = "address_line_two";
export const ADDRESS_CITY = "address_city";
export const ADDRESS_STATE = "address_state";
export const ADDRESS_ZIP_CODE = "address_zip_code";
export const PURCHASE_PRICE = "purchase_price";
export const PROPERTY_TYPE = "property_type";
export const MONTHLY_HOA_FEE = "monthly_hoa_fee";
export const ADDITIONAL_ANNUAL_FEES = "additional_annual_fees";
export const RENOVATION_COSTS = "renovation_costs";
export const MAINTENANCE_PERCENT = "maintenance_percent";
export const IS_FAVORITE = "is_favorite";

export const HOMES = "homes";
export const LOANS = "loans";
export const AGENTS = "agents";
export const MESSAGE = "message";
export const MESSAGES = "messages";
export const PROFILE = "profile";
export const LEADS = "leads";
export const CLIENTS = "clients";
export const TRANSACTIONS = "transactions";
export const SETTINGS = "settings";

export const DATE_MONTH = "month";
export const DATE_DAY = "day";
export const DATE_YEAR = "year";

export const REPRESENTATION = "representation";
export const TRANSACTION_DATE = "transaction_date";

export const BODY = "body";
export const CONNECTION = "connection";
export const STATUS = "status";
export const STATUSES = "statuses";

export const CREATED_AT = "created_at";

export const ABOUT_DESCRIPTION = "about_description";
export const FIRM = "firm";
export const LICENSE_NUMBER = "license_number";
export const LICENSE_TYPE = "license_type";
export const REPRESENTATION_TYPE = "representation_type";
export const SALE_EXPERTISE = "sale_expertise";
export const ASSET_SPECIALTY = "asset_specialty";
export const ASSET_INTERESTS = "asset_interests";
export const PROFILE_DESCRIPTION = "profile_desription";
export const REPRESENTATION_STYLE = "representation_style";
export const WORKING_STYLE = "working_style";
export const NUMBER_OF_AREAS_OF_EXPERTISE = "number_of_areas_of_expertise";
export const NUMBER_OF_INTERESTED_AREAS = "number_of_interested_areas";

export const NOT_INTERESTED_BY_AGENT = "NotInterestedByAgent";
export const NOT_INTERESTED_BY_BUYER = "NotInterestedByBuyer";
export const NOT_INTERESTED_BY_AGENT_REMOVED = "NotInterestedByAgentRemoved";
export const NOT_INTERESTED_BY_BUYER_REMOVED = "NotInterestedByBuyerRemoved";
export const CONNECTION_REQUEST_BY_BUYER_PENDING = "ConnectionRequestByBuyerPending";
export const CONNECTION_REQUEST_BY_AGENT_PENDING = "ConnectionRequestByAgentPending";
export const CONNECTION_REQUEST_BY_BUYER_ACCEPTED = "ConnectionRequestByBuyerAccepted";
export const CONNECTION_REQUEST_BY_AGENT_ACCEPTED = "ConnectionRequestByAgentAccepted";
export const CONNECTION_REQUEST_BY_BUYER_CANCELED = "ConnectionRequestByBuyerCanceled";
export const CONNECTION_REQUEST_BY_AGENT_CANCELED = "ConnectionRequestByAgentCanceled";
export const CONNECTION_REMOVED_BY_BUYER = "ConnectionRemovedByBuyer";
export const CONNECTION_REMOVED_BY_AGENT = "ConnectionRemovedByAgent";
export const BLOCKED_BY_BUYER = "BlockedByBuyer";
export const BLOCKED_BY_AGENT = "BlockedByAgent";

export const CONNECTIONS_CONNECTED = "connections_connected";
export const CONNECTIONS_PENDING = "connections_pending";
export const CONNECTIONS_CONTACTED = "connections_contacted";
export const CONNECTIONS_NOT_INTERESTED = "connections_not_interested";

export const COMMISSION_PERCENTAGE = "commission_percentage";
export const NUMBER_OF_MONTHS = "number_of_months";
export const RETAINER_FEE_DOLLARS = "retainer_fee_dollars";
export const RETAINER_PART_OF_COMMISSION = "retainer_part_of_commission";
export const IS_EXCLUSIVE = "is_exclusive";

export const SHORT_SALE = "short_sale";
export const FORECLOSURE = "foreclosure";

export const AGENCY_AGREEMENT = "AgencyAgreement";
export const PURCHASE_AND_SALE_AGREEMENT = "PurchaseAndSaleAgreement";
export const CLOSING_DISCLOSURE = "ClosingDisclosure";

// Default offer from ResiRoots.
export const RESIROOTS_DEFAULT_OFFER = {
    "commission_percentage": 3,
    "number_of_months": 12,
    "retainer_fee_dollars": 500,
    "retainer_part_of_commission": false,
    "representation": "ClientFiduciary",
    "is_exclusive": true,
}

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 255;

export const COORDINATES = "coordinates";
export const LONGITUDE = "longitude";
export const LATITUDE = "latitude";

export const NOTIFICATION_OFFER = "Offer";