import React, { useEffect,  useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import {
    Avatar,
    Center,
    Flex,
    Image,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react'

import { axiosGetBuyer } from '../../common/methods/methods.js';
import { checkRequireBuyerSignUp } from '../../../utils/utility.js';

import { MdEdit } from 'react-icons/md';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';

import {
    FIRST_NAME,
    EMAIL,
    LAST_NAME,
    GET,
    ID,
    PATCH,
    PHONE_NUMBER,
    PUT,
    PROFILE,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT,
    DEFAULT_PUT_TIMEOUT,
} from '../../../utils/constants.js';
import { loadMime } from '../../../utils/utility.js';

import BuyerDashboardParentPage from '../utils/BuyerDashboardParentPage.js';
import AboutPanel from './AboutPanel.js';
import MainPanel from '../../common/MainPanel.js';
import IntroPanel from './IntroPanel.js';
import { chaoticOrbit } from 'ldrs'

import BuyerSignUpInformationModal from '../utils/BuyerSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import './Page.css';
import '../../Generic.css';

function BuyerProfileDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [showEditProfilePictureButton, setShowEditProfilePictureButton] = useState(false);
    const [profilePictureUploadLoading, setProfilePictureUploadLoading] = useState(false);
    const [buyer, setBuyer] = useState(null);

    const [triggerFetchUser, setTriggerFetchUser] = useState(false);
    const [triggerFetchBuyerProfile, setTriggerFetchBuyerProfile] = useState(false);

    const [userLoading, setUserLoading] = useState(true);
    const [buyerLoading, setBuyerLoading] = useState(true);

    const [finishSignUpFormBuyer, setFinishSignUpFormBuyer] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    const toast = useToast();
    
    chaoticOrbit.register()

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let user = response.data;
            setUser(user);
            setUserLoading(false);
            setTimeout(function() {
                setProfilePictureUploadLoading(false);
            }, 500);
        }).catch(error => {
            // TODO
        });
    }, [userId, triggerFetchUser]);

    useEffect(() => {
        axiosGetBuyer(userId).then(buyer => {
            if (buyer && checkRequireBuyerSignUp(buyer) === true) {
                setFinishSignUpFormBuyer(buyer);
            } 
            setBuyer(buyer);
            setBuyerLoading(false);
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId, triggerFetchBuyerProfile]);

    // useEffect(() => {
    //     axios({
    //         method: GET,
    //         url: '/api/v1/user/buyer/profile/' + userId,
    //         timeout: DEFAULT_GET_TIMEOUT,
    //     }).then(response => {
    //         let buyer = response.data;
    //         setBuyer(buyer);
    //     }).catch(error => {
    //         // TODO
    //     });
    //     setBuyerLoading(false);
    // }, [userId, triggerFetchBuyerProfile])


    const handleUpdateBuyerProfile = (data, toastTitle, toastDescription, callback) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/buyer/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            setTriggerFetchBuyerProfile(!triggerFetchBuyerProfile);
            toast({
                title: toastTitle,
                description: toastDescription,
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
            callback();
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleUpdateUserProfilePicture = (key) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: {
                "profile_picture_url_key": key
            },
        }).then(response => {
            setTriggerFetchUser(!triggerFetchUser);
        }).catch(error => {
            // TODO
        }, []);
    }
    /*
     * Name: handleProfilePictureEdit
     * Description: This function triggers everytime we detect an input change
     *              on our <input type="file"> element for our profile picture.
     *              It first sets the image src to display the new picture,
     *              then it attempts to upload the image to our server. It
     *              does this by first determining the MIME type, then sending
     *              a request to the server for a GCS PUT signed URL. Once it
     *              has the URL, it uploads the file to that URL.
     */
    const handleProfilePictureInputChange = (e) => {
        
        setProfilePictureUploadLoading(true);
        let file = e.target.files[0];
        // Set our display as the new picture.
        loadMime(file, function(fileMimeType) {
            // Handle saving the file to our server in the background.
            axios({
                method: GET,
                url: '/api/v1/user/profile/picture/uploadURL?id=' + userId + "&mime_type=" + fileMimeType,
                timeout: DEFAULT_GET_TIMEOUT,
            }).then(response => {
                let uploadProfilePicture = response.data;
                let uploadSignedURL = uploadProfilePicture["upload_signed_url"];
                let storageKey = uploadProfilePicture["storage_key"];
                axios({
                    method: PUT,
                    url: uploadSignedURL,
                    timeout: DEFAULT_PUT_TIMEOUT,
                    data: file,
                    headers: {
                        'Content-Type': fileMimeType
                    }
                }).then(response=> {
                    handleUpdateUserProfilePicture(storageKey);
                }).catch(error=> {
                    // TODO
                });
                e.target.value = '';
            }).catch(error => {
                // TODO
            });
        }); 
    }

    if (userLoading || buyerLoading) return;

    return (
        <BuyerDashboardParentPage key={user} user={user} tab={PROFILE} buyer={buyer}>
            {
                finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormBuyer ?
                        <BuyerSignUpInformationModal
                            user={finishSignUpFormBuyer}
                            setFinishSignUpFormBuyer={setFinishSignUpFormBuyer}
                        />: null
                    }
                    <MainPanel>
                        <Flex gap={6}>
                            <Stack gap={5} style={{marginTop: "25px"}}>
                                <Center>
                                    <div 
                                        onMouseEnter={() => {
                                            setShowEditProfilePictureButton(true);
                                        }}
                                        onMouseLeave={() => {
                                            setShowEditProfilePictureButton(false);
                                        }}
                                        style={{
                                            borderRadius: "50%",
                                            position: "relative",
                                            pointerEvents: profilePictureUploadLoading ? "none" : "",
                                            textAlign: "center",
                                            width: "125px",
                                        }}
                                    >
                                        <div style={{
                                            height: "125px",
                                            opacity: profilePictureUploadLoading ? "0.3" : "1.0",
                                            width: "125px",
                                        }}>
                                            {
                                                profilePictureUploadLoading ?
                                                // Default values shown
                                                <l-chaotic-orbit
                                                    size="35"
                                                    speed="1.5" 
                                                    color="black" 
                                                    style={{
                                                        position: "absolute",
                                                        left: "calc(50% - 17.5px)",
                                                        top: "calc(50% - 17.5px)",
                                                    }}
                                                ></l-chaotic-orbit> : null
                                            }
                                            <div style={{
                                                backgroundColor: "#efefef",
                                                borderRadius: "50%",
                                                height: "125px",
                                                margin: "0px 0px 0px 0px",
                                                width: "125px",
                                            }}>
                                                {
                                                    user && user["profile_picture_url"] ?
                                                    <Image
                                                        borderRadius='full'
                                                        src={user["profile_picture_url"]}
                                                        alt={user[FIRST_NAME] + " " + user[LAST_NAME]}
                                                        style={{
                                                            border: "3px solid white",
                                                            objectFit: "cover",
                                                            overflow: "hidden",
                                                            height: "125px",
                                                            width: "100%",
                                                        }}
                                                    /> :
                                                    <Avatar 
                                                        boxSize='125px'    
                                                    />
                                                }
                                            </div>
                                        </div>
                                        {
                                            showEditProfilePictureButton ?
                                            <div style={{
                                                position: "relative",
                                                width: "100%",
                                            }}>
                                                <label htmlFor="upload-profile-picture">
                                                    <MdEdit
                                                        component="label"
                                                        style={{
                                                            backgroundColor: "#dddddd",
                                                            borderRadius: "25px",
                                                            color: "black",
                                                            cursor: "pointer",
                                                            left: "125px",
                                                            margin: "-30px 0px 0px 20px",
                                                            padding: "5px",
                                                            height: "30px",
                                                            width: "30px",
                                                            position: "absolute",
                                                        }}
                                                    />
                                                    
                                                </label>

                                            </div> : null
                                        }
                                        <input 
                                            accept="image/png,image/jpg,image/jpeg"
                                            id="upload-profile-picture"
                                            onChange={handleProfilePictureInputChange}
                                            style={{
                                                display: "none",
                                            }}
                                            type="file"
                                        ></input>
                                    </div>
                                </Center>
                                <div>
                                    <Center>
                                        <Text fontWeight='bold' fontSize='2xl'>
                                            {user[FIRST_NAME]} {user[LAST_NAME]}
                                        </Text>
                                    </Center>
                                    <Stack gap={1}>
                                        <Center>
                                            <Flex align='center' gap={2}>
                                                <EmailIcon style={{
                                                    color: "grey",
                                                    height: "15px",
                                                    width: "15px"
                                                }}/>
                                                <Text color='#5a5a5a' fontSize='sm'>
                                                    {user[EMAIL]}
                                                </Text>
                                            </Flex>
                                        </Center>
                                        <Center>
                                            <Flex align='center' gap={2}>
                                                <PhoneIcon style={{
                                                    color: "grey",
                                                    height: "15px",
                                                    width: "15px"
                                                }}/>
                                                <Text color='#5a5a5a' fontSize='sm'>
                                                    {user[PHONE_NUMBER]}
                                                </Text>
                                            </Flex>
                                        </Center>
                                    </Stack>
                                </div>
                                <IntroPanel buyer={buyer} handleUpdateUserProfile={handleUpdateBuyerProfile} user={user}/>
                            </Stack>
                            <AboutPanel buyer={buyer} handleUpdateUserProfile={handleUpdateBuyerProfile} user={user}/>
                        </Flex>
                    </MainPanel>
                </>
            }
        </BuyerDashboardParentPage>
    );
}

export default BuyerProfileDashboardPage;