import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import {
    useToast,
} from '@chakra-ui/react';

import {
    AGENTS,
    GET,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_POST_TIMEOUT,
    ID,
    CONNECTION_REQUEST_BY_AGENT_ACCEPTED,
    CONNECTION_REQUEST_BY_BUYER_CANCELED,
    NOT_INTERESTED_BY_BUYER,
    POST,
    NOT_INTERESTED_BY_BUYER_REMOVED,
    CONNECTION_REMOVED_BY_BUYER,
    IS_VERIFIED
} from '../../../utils/constants.js';

import { axiosGetUser, axiosGetBuyer } from '../../common/methods/methods.js';
import { checkRequireBuyerSignUp } from '../../../utils/utility.js';
import { WarningIcon } from '@chakra-ui/icons';

import './Page.css';
import '../../Generic.css';
import BuyerDashboardParentPage from '../utils/BuyerDashboardParentPage.js';
import MainPanel from '../../common/MainPanel.js';
import MyAgentPage from './MyAgentPage.js';
import AgentsFeed from './AgentsFeed.js';
import BuyerSignUpInformationModal from '../utils/BuyerSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

function BuyerAgentsDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [buyer, setBuyer] = useState(null);
    const [buyerLoading, setBuyerLoading] = useState(true);

    const [workingAgent, setWorkingAgent] = useState(null);
    const [workingAgentLoading, setWorkingAgentLoading] = useState(true);


    const [triggerFetchConnections, setTriggerFetchConnections] = useState(false);
    const [triggerFetchAgents, setTriggerFetchAgents] = useState(false);

    const [triggerFetchActiveAgent, setTriggerFetchActiveAgent] = useState(false);

    const [finishSignUpFormBuyer, setFinishSignUpFormBuyer] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const [showOffers, setShowOffers] = useState(searchParams.get('show_offers'));

    const toast = useToast();

    const urlParams = useParams();
    const userId = urlParams[ID];

    // Controls how many agents to show per page.
    // const NUMBER_OF_AGENTS_PER_PAGE = 5;

    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (null !== user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            setUserLoading(false);
        });
    }, [userId]);

    useEffect(() => {
        axiosGetBuyer(userId).then(buyer => {
            if (buyer && checkRequireBuyerSignUp(buyer) === true) {
                setFinishSignUpFormBuyer(buyer);
            } 
            setBuyer(buyer);
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        }).finally (() => {
            setBuyerLoading(false);
        });
    }, [userId]);
    
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/agent/active/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setWorkingAgent(response.data);
                setWorkingAgentLoading(false);
            }
        }).catch(error => {
            // No active agent found. Not an error.
            if (error.response.status === 404) {
                setWorkingAgent(null);
            }
            setWorkingAgentLoading(false);
        })
    }, [userId, triggerFetchActiveAgent])

    const handleAddConnection = (agentId, status, callback) => {
        console.log("here");
        axios({
            method: POST,
            url: '/api/v1/user/connection/' + userId + '?user_type=Buyer',
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                buyer_id: userId,
                agent_id: agentId,
                statuses: [{
                    status: status,
                }]
            }
        }).then(response => {
            if (200 === response.status) {
                let title;
                let description;
                switch(status) {
                    case CONNECTION_REQUEST_BY_BUYER_CANCELED:
                        title = 'Request Canceled';
                        description = 'Successfully canceled your request';
                        break;
                    case CONNECTION_REQUEST_BY_AGENT_ACCEPTED:
                        title = 'Offer Accepted';
                        description = "You're now working with your new agent!";
                        break;
                    case NOT_INTERESTED_BY_BUYER:
                        title = "Marked as not interested";
                        description = "You marked this agent as not interested. You won't see them again in your feed";
                        break;
                    case NOT_INTERESTED_BY_BUYER_REMOVED:
                        title = "Removed from not interested";
                        description = "You removed this agent from not interested. They'll start appearing in your feed again";
                        break;
                    case CONNECTION_REMOVED_BY_BUYER:
                        title = "Unmatched with this agent"
                        description = "You've unmatched with this agent successfully";
                        break;
                    default:
                        title = "Success"
                        description = "We've updated your changes"
                }
                setTriggerFetchConnections(!triggerFetchConnections);
                setTriggerFetchAgents(!triggerFetchAgents);
                // setShowContactAgentModal(false);
                callback();
                toast({
                    title: title,
                    description: description,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).catch(error => {
            toast({
                title: "Error",
                description: `${error}`,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        })
    }

    const handleResendVerificationEmail = () => {

        axios({
            method: POST,
            url: '/api/v1/account-verification/resend/' + userId,
            timeout: DEFAULT_POST_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                toast({
                    title: "Account verification email resent",
                    description: "We've re-sent your account verification email. Do not share your link with anyone else.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).catch(error => {
            if (500 === error.response.status) {
                toast({
                    title: "Internal Server Error",
                    description: "We've encountered an error sending out a new account verification email. Please try again in 1 minute",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            } else if (429 === error.response.status) {
                toast({
                    title: "Too many requests",
                    description: "Please wait 1 minute between requesting a new account verification email",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            }
        })
    }

    if (userLoading || buyerLoading) return;
    
    return (
        <BuyerDashboardParentPage user={user} tab={AGENTS} buyer={buyer}>
            {
                workingAgentLoading || finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormBuyer ?
                        <BuyerSignUpInformationModal
                            user={finishSignUpFormBuyer}
                            setFinishSignUpFormBuyer={setFinishSignUpFormBuyer}
                        />: null
                    }
                    <MainPanel>
                        {
                            user && user[IS_VERIFIED] === false ?
                            <div className="home_page__verify_email_parent">
                                <WarningIcon style={{
                                    height: "20px",
                                    margin: "auto 10px auto 0px",
                                    width: "20px",
                                }}/>
                                <p style={{
                                    lineHeight: "50px",
                                    overflow: "hidden",
                                    textOverflow: "ellipses",
                                }}>
                                    Please verify your account. Didn't get a verification email?
                                </p>
                                <p 
                                    onMouseDown={() => handleResendVerificationEmail()}
                                    style={{
                                        cursor: "pointer",
                                        lineHeight: "50px",
                                        margin: "0px 0px 0px 10px",
                                        textDecoration: "underline",
                                        overflow: "hidden",
                                        textOverflow: "ellipses",
                                    }}
                                >
                                    Re-send verification email
                                </p>
                            </div> :
                            null
                        }
                        {
                            workingAgent !== null ?
                            <MyAgentPage
                                userId={userId}
                                workingAgent={workingAgent}
                                handleAddConnection={handleAddConnection}
                                setTriggerFetchActiveAgent={() => setTriggerFetchActiveAgent(!triggerFetchActiveAgent)}
                            /> :
                            <AgentsFeed 
                                user={user}
                                handleAddConnection={handleAddConnection} 
                                triggerFetchConnections={triggerFetchConnections}
                                triggerFetchAgents={triggerFetchAgents}
                                setTriggerFetchConnections={() => setTriggerFetchConnections(!triggerFetchConnections)}
                                showOffers={showOffers}
                            />
                        }
                    </MainPanel>   
                </>
            }
        </BuyerDashboardParentPage>
    );
}

export default BuyerAgentsDashboardPage;