import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import {
    Menu,
    MenuButton,
    Avatar,
    AvatarBadge,
    MenuList,
    Link,
    MenuItem,
    IconButton,
    PopoverTrigger,
    Popover,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Heading,
    Icon,
    Flex,
    Stack,
    Text,
    Center,
} from '@chakra-ui/react';
import { StreamChat } from 'stream-chat';
import { FaArrowRightLong, FaBell, FaBolt, FaCircleExclamation } from 'react-icons/fa6';

import { getChatTokenAndRedirectPage } from '../../common/methods/methods.js';
import {
    AGENT,
    BOTH,
    GET,
    BUYER,
    DEFAULT_PATCH_TIMEOUT,
    FIRST_NAME,
    ID,
    USER_TYPE,
    PATCH,
    DEFAULT_GET_TIMEOUT,
    NOTIFICATION_OFFER
} from '../../../utils/constants.js';
import ReactTimeAgo from 'react-time-ago'

import '../../Generic.css';
import './AgentViewNavigationBar.css';
import { useAuth } from '../../auth/useAuth.js';

const chatClient = StreamChat.getInstance("ufb64fqzh9zr");

function AgentViewNavigationBar(props) {

    const navigate = useNavigate();

    const user = props.user;
    const agent = props.agent;

    const [viewType] = useState(props.viewType);
    const [ displaySwitchButton ] = useState(props.displaySwitchButton)
    const [notifications, setNotifications] = useState([]);

    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

    let userId = user[ID];

    const { logout } = useAuth();
    
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/notifications/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                let notifications = response.data;
                setNotifications(notifications);
                let hasUnreadNotifications = notifications && notifications.length > 0 && agent.notifications_last_read_at < notifications[0].created_at;
                console.log(hasUnreadNotifications);
                console.log(agent);
                console.log(notifications[0]);
                setHasUnreadNotifications(hasUnreadNotifications);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const handleNotificationsOpened = () => {
        let notificationsLastReadAt = new Date(Date.now()).toISOString();
        axios({
            method: PATCH,
            url: '/api/v1/user/agent/notifications/last_read_at/' + user[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: {
                // Timezone in UTC.
                notifications_last_read_at: notificationsLastReadAt,
            }
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setHasUnreadNotifications(false);
        });
    }

    const renderNotificationIcon = (notificationType) => {
        switch (notificationType) {
            case NOTIFICATION_OFFER:
                return <FaBolt/>;
            default:
                return <FaBolt/>
        }
    }

    const renderNotificationCallToAction = (notification) => {
        switch (notification.type) {
            case NOTIFICATION_OFFER:
                // window.location.href = '/user/agent/dashboard/agents/' + user[ID] + '?show_offers=true';
            default:
                // navigate('/user/agent/dashboard/agents/' + user[ID] + '?show_offers=true');
        }
    }

    const renderNotifications = () => {

        let renderedNotifications = [];

        let notificationsLastReadAt = agent.notifications_last_read_at;
        
        let NOTIFICATIONS_TO_SHOW = 10;

        for (let i = 0; i < notifications.length && i < NOTIFICATIONS_TO_SHOW; i++) {
            let notification = notifications[i];

            // User has not seen this notification yet.
            let unread = notification.created_at > notificationsLastReadAt;

            renderedNotifications.push(
                <Flex   
                    onMouseDown={() => renderNotificationCallToAction(notification)}
                    className='view_navigation_bar_notification'
                    align='center' 
                    gap='3' 
                    backgroundColor={unread ? '#f4f4f4' : 'transparent'} 
                    style={{padding: "7.5px 15px 7.5px 15px"}}
                >
                    <Avatar color='white' backgroundColor='#05b96b' boxSize='7' icon={renderNotificationIcon(notification.type)} style={{padding: "6px"}}>
                    </Avatar>
                    <Stack gap='0'>
                        <Text color='gray' fontWeight='bold'>
                            {notification.text}
                        </Text>
                        <Text color='gray' fontSize='xs' style={{marginTop: "-2.5px"}}>
                            <ReactTimeAgo date={notification.created_at} locale="en-US"/>
                        </Text>
                    </Stack>
                </Flex>
            );
        }
        return (
            <>
                {renderedNotifications}
            </>
        );
    }

    const renderNoNotifications = () => {
        return (
            <Stack style={{paddingTop: "20px", paddingBottom: "20px"}}>
                <Center>
                    <Flex align='center' gap='2'>
                        <Icon as={FaCircleExclamation} color='gray' size='sm'/>
                        <Text color='gray' fontSize='sm'>
                            No notifications
                        </Text>
                    </Flex>
                </Center>
            </Stack>
        );
    }

    const renderNotificationsPopover = () => {
        return (
            <Popover 
                onOpen={handleNotificationsOpened}
                placement="top-start" size='sm' style={{
                    backgroundColor: "grey",
                }}
            >
                <PopoverTrigger>
                    <div style={{ margin: "10px 10px 10px 0px"}}>
                        <Avatar
                            size='sm'
                            color='white' 
                            icon={<FaBell fontSize='1.7em'/>} 
                            style={{backgroundColor: "transparent", padding: "none !important"}} 
                            className='hover'
                        >
                            <AvatarBadge border='none' boxSize='0.7em' bg={hasUnreadNotifications ? 'red' : 'transparent'}/>
                        </Avatar>
                    </div>
                </PopoverTrigger>
                <PopoverContent style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader style={{
                        borderBottom: "none",
                        marginTop: "5px",
                    }}>
                        <Flex align='center' gap='2'>
                            <Icon as={FaBell}/>
                            <Heading fontWeight='bold' fontSize='md'>
                                Notifications
                            </Heading>
                        </Flex>
                    </PopoverHeader>
                    <PopoverBody style={{
                        backgroundColor: "white",
                        borderRadius: "0px 0px 8px 8px",
                        padding: "10px 0px 15px 0px",
                        width: "500px !important"
                    }}>
                        {
                            notifications && notifications.length > 0 ?
                            renderNotifications() :
                            renderNoNotifications()
                        }
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    }

    return (
        <div className="view_navigation_bar">
            <Flex>
                <Link
                    style={{
                        textDecoration: "none"
                    }}
                    href={"/user/buyer/dashboard/homes/" + userId}
                    className="view_navigation_bar-logo-box"
                >
                    {/* <img 
                        alt="rezied_logo"
                        className="view_navigation_bar-logo" 
                        src={require("../../../Images/logo/logo_500_500_green_background.png")}
                    /> */}
                    <Text className="view_navigation_bar-logo-title" style={{paddingLeft: "25px"}}>rezied</Text>
                </Link>
                <div style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    height: "20px",
                    margin: "calc((50px - 20px)/2) 0px calc((50px - 20px)/2) 15px",
                    padding: "2.5px 7.5px 2.5px 7.5px",
                }}>
                    <p style={{
                        color: "#05b96b",
                        fontSize: "0.7em",
                        fontWeight: "bold",
                        lineHeight: "15px",
                    }}>
                        {viewType}
                    </p>
                </div>
            </Flex>
            <div style={{
                display: "flex",
                zIndex: "30",
            }}>
                
                {renderNotificationsPopover()}
                {
                    displaySwitchButton ? 
                    (user[USER_TYPE] !== BOTH ? null :
                    <div className="view_navigation_bar__switch_button hover" onMouseDown={() => getChatTokenAndRedirectPage(chatClient, userId, user[FIRST_NAME], BUYER, "/user/buyer/dashboard/agents/" + userId)}>
                        Buyer view
                        <FaArrowRightLong className="view_navigation_bar__switch_button_icon"/>
                    </div>)
                    : null
                }
                <Menu 
                    placement={"bottom-end"}
                >
                    <MenuButton
                        style={{
                            backgroundColor: "transparent",
                            color: "grey",
                            margin: "calc((50px - 40px)/2) 25px calc((50px - 40px)/2) 0px",
                        }}
                        as={IconButton}
                        aria-label='Options'
                        rightIcon={
                            <Avatar src={user && user["profile_picture_url"] && user["profile_picture_url"].length > 0 ? user["profile_picture_url"] : ''} size='sm'>
                                <AvatarBadge boxSize='1.0em' bg='green.500' />
                            </Avatar>
                        }
                    />
                    <MenuList style={{
                        border: "none",
                        borderRadius: "4px !important",
                        boxShadow: "0 2px 3px -3px #eeeeee, 0 3px 3px 2px #eeeeee, 0 1px 3px 1px rgba(13, 13, 13, 0.12)",
                        margin: "-7.5px 0px 0px 0px",
                        paddingBottom: "5px",
                        paddingTop: "0px"
                    }}>
                        <MenuItem className="view_navigation_bar__menu_logo_element">
                            Rezied
                        </MenuItem>
                        {/* <Link className="view_navigation_bar__link">
                            <MenuItem className="view_navigation_bar__link_menu_item">
                                Help & Support
                            </MenuItem>
                        </Link> */}
                        <Link href={"/contact-us/" + userId + "?view_type=" + viewType} className="view_navigation_bar__link">
                            <MenuItem className="view_navigation_bar__link_menu_item">
                            {/* <MenuItem icon={<FaExclamationCircle />}> */}
                                Contact Us
                            </MenuItem>
                        </Link>
                        <Link className="view_navigation_bar__link" href={"/"} onMouseDown={() => {
                            logout().then(() => {
                                localStorage.removeItem("user");
                                localStorage.removeItem("userProfilePictureURL");
                                navigate('/');
                            });
                        }}>
                            <MenuItem className="view_navigation_bar__link_menu_item">
                            {/* <MenuItem icon={<FaSignOutAlt />}> */}
                                Sign Out
                            </MenuItem>
                        </Link>
                    </MenuList>
                </Menu>
            </div>
        </div>
    );
}

export default AgentViewNavigationBar;