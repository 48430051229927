import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Radar from 'radar-sdk-js';

import {
    Center,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    useToast
} from '@chakra-ui/react';

import {
    DELETE,
    GET,
    ID,
    POST,
    DEFAULT_DELETE_TIMEOUT,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_POST_TIMEOUT
} from '../../../utils/constants.js';

import { Search2Icon } from '@chakra-ui/icons';
import { MdClose } from 'react-icons/md';
import { FaMapLocationDot } from 'react-icons/fa6';

import '../../Generic.css';

function AreasOfExpertise(props) {

    const user = props.user;
    const triggerFetchExpertiseAreasCallback = props.triggerFetchExpertiseAreasCallback;

    const [inputValue, setInputValue] = useState('');
    const [expertiseAreasAutocompleteAddresses, setExpertiseAreasAutocompleteAddresses] = useState(null);
    const [selectedExpertiseAreaTab, setSelectedExpertiseAreaTab] = useState(null);

    const toast = useToast();

    const handleAreasOfExpertiseInputChange = (e) => {
        let val = e.target.value;
        setInputValue(val);
        if (val.length <= 2) {
            setExpertiseAreasAutocompleteAddresses(null);
            return;
        }
        Radar.autocomplete({
            query: val,
            limit: 20,
            country: 'US',
        }).then(response => {
            // We want to let users search for the following.
            // 1. Locality's
            // 2. Zip Codes
            let addresses = response.addresses.filter(e =>
                e["formattedAddress"].includes("USA") && (e["layer"] === "locality" || 
                (e["postalCode"] && e["postalCode"].includes(val)))
            );
            setExpertiseAreasAutocompleteAddresses(addresses);
        }).catch(error => {
            // TODO
        })
    }

    const handleAddExpertiseArea = (address) => {
        
        axios({
            method: POST,
            url: '/api/v1/user/agent/expertise_area/' + user[ID],
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                agent_id: user[ID],
                coordinates: {
                    longitude: parseFloat(address["longitude"]),
                    latitude: parseFloat(address["latitude"]),
                },
                formatted_address: address["formattedAddress"],
            },
        }).then(response => {
            if (200 === response.status) {
                let expertiseArea = response.data;
                setSelectedExpertiseAreaTab(expertiseArea);
                setExpertiseAreasAutocompleteAddresses(null);
                setInputValue("");
                triggerFetchExpertiseAreasCallback();
            }
        }).catch(error => {
            if (409 === error.response.status) {
                setExpertiseAreasAutocompleteAddresses(null);
                setInputValue("");
                toast({
                    title: 'Duplicate area of expertise',
                    description: "You've already marked that area as expertise",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        })
    }

    const handleDeleteExpertiseArea = (areaId) => {

        axios({
            method: DELETE,
            url: '/api/v1/user/agent/expertise_area/' + user[ID] + '/' + areaId,
            timeout: DEFAULT_DELETE_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                // if (areaId === selectedExpertiseAreaTab[ID]){
                setSelectedExpertiseAreaTab(null);
                // }
                toast({
                    title: 'Deleted area of expertise',
                    description: "We've deleted your area of expertise",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                triggerFetchExpertiseAreasCallback();
            }
        }).catch(error => {
            toast({
                title: 'Unable to delete your area of expertise',
                description: "Please try again or contact us",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        })
    }

    const ExpertiseAreaTabs = () => {

        if (!props.agentExpertiseAreas || props.agentExpertiseAreas.length === 0) {
            return(
                <div>
                    <p style={{
                        color: "grey",
                        fontSize: "0.8em",
                        margin: "15px 0px 0px 0px",
                        textAlign: "center",
                    }}>
                        No Areas of Expertise yet
                    </p>
                </div>
            )
        }

        let renderedTabs = [];
        for (let i = 0; i < props.agentExpertiseAreas.length; i++) {
            renderedTabs.push(
                <div key={props.agentExpertiseAreas[i][ID] + "_tab"} className="interested_area__areas_tab" 
                    onMouseDown={() => {
                        setSelectedExpertiseAreaTab(props.agentExpertiseAreas[i])
                    }}
                    style={{
                        backgroundColor: selectedExpertiseAreaTab && selectedExpertiseAreaTab[ID] === props.agentExpertiseAreas[i][ID] ? "#05b96b" : "#efefef",
                        color: selectedExpertiseAreaTab && selectedExpertiseAreaTab[ID] === props.agentExpertiseAreas[i][ID] ? "white" : "black",
                    }}
                >
                    <p className="interested_area__areas_text">
                        {props.agentExpertiseAreas[i]["formatted_address"]}
                    </p>
                    <MdClose className="interested_area__areas_icon" onMouseDown={(e) => {
                        e.stopPropagation();
                        handleDeleteExpertiseArea(props.agentExpertiseAreas[i][ID]);
                    }}/>
                </div>
            );
        }
        return (
            <div style={{
                margin: "5px 0px 0px 0px",
                overflowY: "scroll",
            }}>
                {renderedTabs}
            </div>
        );
    }
    
    const renderExpertiseAreasAutocompleteAddresses = () => {

        let renderedAddresses = [];

        for (let i = 0; i < expertiseAreasAutocompleteAddresses.length; i++) {
            let formattedAddress = expertiseAreasAutocompleteAddresses[i]["formattedAddress"];
            renderedAddresses.push(
                <div key={i + "" + formattedAddress} className="autocomplete__interested_address"
                    onMouseDown={() => handleAddExpertiseArea(expertiseAreasAutocompleteAddresses[i])}
                style={{
                    cursor: "pointer",
                    padding: "7.5px 10px 7.5px 10px",
                }}>
                    <p style={{
                        fontSize: "0.9em"
                    }}>
                        {formattedAddress}
                    </p>
                </div>
            )
        }
        
        return (
            <div style={{
                backgroundColor: "white",
                border: "1px solid #cccccc",
                borderRadius: "4px",
                position: "absolute",
                width: "100%",
            }}>
                {renderedAddresses}
            </div>
        )
    }

    useEffect(() => {

        if (props.agentExpertiseAreas === null || props.agentExpertiseAreas === undefined || props.agentExpertiseAreas.length === 0) {
            return;
        }

        const selectedExists = selectedExpertiseAreaTab ?
            props.agentExpertiseAreas.filter(e => e[ID] === selectedExpertiseAreaTab[ID]).length > 0 :
            false;

        let areaToShow;
        if (selectedExpertiseAreaTab && selectedExists) {
            areaToShow = selectedExpertiseAreaTab;
        } else if (props.agentExpertiseAreas.length > 0) {
            if (selectedExpertiseAreaTab === null) {
                setSelectedExpertiseAreaTab(props.agentExpertiseAreas[0]);
            }
            areaToShow = props.agentExpertiseAreas[0];
            setSelectedExpertiseAreaTab(areaToShow);
        } else {
            return;
        }
    
        let longitude = parseFloat(areaToShow["coordinates"]["longitude"]);
        let latitude = parseFloat(areaToShow["coordinates"]["latitude"]);

        const map = new Radar.ui.map({
            container: 'map',
            style: 'radar-default-v1',
            center: [longitude, latitude], // NYC
            zoom: 14,
        });

        // add a marker to the map
        Radar.ui.marker({ text: 'Radar HQ' })
            .setLngLat([longitude, latitude])
            .addTo(map);
    }, [props.agentExpertiseAreas, selectedExpertiseAreaTab])

    return (
        <Stack gap={4} style={{marginTop: "15px"}}>
            <div>
                <InputGroup>
                    <InputLeftElement pointerEvents='none'>
                        <Search2Icon color='gray' style={{
                            margin: "-7.5px 0px 0px 0px"
                        }}/>
                    </InputLeftElement>
                    <Input value={inputValue} onChange={handleAreasOfExpertiseInputChange} placeholder="Search for an area or zip code" size='sm' style={{
                        border: "1px solid #cccccc",
                        borderRadius: "12px",
                        width: "100%",
                    }}/>
                </InputGroup>
                {
                    expertiseAreasAutocompleteAddresses && expertiseAreasAutocompleteAddresses.length > 0 ?
                    <div style={{
                        position: "relative",
                    }}>
                        {renderExpertiseAreasAutocompleteAddresses()}
                    </div> : null
                }
                <div style={{
                    height: "10px",
                    margin: "5px 0px 5px 0px"
                }}>
                    <p style={{
                        color: "red",
                        fontSize: "0.7em",
                    }}>
                        {
                            inputValue && inputValue.length > 0 && inputValue.length <= 2 ? 
                            "Please enter at least two characters" : ""
                        }
                    </p>
                </div>
                <ExpertiseAreaTabs/>
            </div>
            {
                props.agentExpertiseAreas !== null && props.agentExpertiseAreas !== undefined && props.agentExpertiseAreas.length > 0 ?
                <div id="map-container" style={{ 
                    borderRadius: "12px",
                    height: '350px',
                }}>
                    <div id="map" style={{ 
                        borderRadius: "8px",
                        height: 'inherit',
                        width: '100%'
                    }}/>
                </div> : 
                <div style={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: "8px",
                    height: '350px',
                    margin: "0px 0px 0px 30px",
                }}>
                    <Center>
                        <FaMapLocationDot style={{
                            color: "grey",
                            height: "30px",
                            top: "calc(50% - 30px)",
                            position: "absolute",
                            width: "30px",
                        }}/>
                        <p style={{
                            color: "grey",
                            margin: "5px 0px 0px 0px",
                            position: "absolute",
                            top: "50%",
                        }}>
                            No Areas of Expertise
                        </p>
                    </Center>
                </div>
            }
        </Stack>
    );
}

function AboutPanel(props) {

    const user = props.user;

    const [agentExpertiseAreas, setAgentExpertiseAreas] = useState(null);
    const [triggerFetchExpertiseAreas, setTriggerFetchExpertiseAreas] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/expertise_areas/' + user[ID],
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let expertise_areas = response.data;
            setAgentExpertiseAreas(expertise_areas);
            setIsLoading(false);
        }).catch(error => {
            // TODO
        });
    }, [user, triggerFetchExpertiseAreas])

    if (isLoading) return;

    return (
        <AreasOfExpertise
            agentExpertiseAreas={agentExpertiseAreas}
            triggerFetchExpertiseAreasCallback={() => setTriggerFetchExpertiseAreas(!triggerFetchExpertiseAreas)}
            user={user}
        />
    );
}

export default AboutPanel;