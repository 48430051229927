import React, { useState } from 'react';
import axios from 'axios';
import {
    Button,
    Input,
    InputLeftElement,
    InputGroup,
    Select,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast
} from '@chakra-ui/react'
import { FaLocationDot } from 'react-icons/fa6';
import {
    POST,
    APARTMENT,
    SINGLE_FAMILY_HOME,
    TOWNHOME,
    DUPLEX,
    MULTIPLEX,
    CONDOMINIUM,
    TRAILER,
    MANUFACTURED,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_CITY,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    PURCHASE_PRICE,
    PROPERTY_TYPE,
    MONTHLY_HOA_FEE,
    ADDITIONAL_ANNUAL_FEES,
    RENOVATION_COSTS,
    MAINTENANCE_PERCENT,
    DEFAULT_POST_TIMEOUT
} from '../../../utils/constants.js';
import {  dollarfyPrice } from '../../../utils/utility.js';
import { dotPulse } from 'ldrs';
import Radar from 'radar-sdk-js';

import './Page.css';
import '../../Generic.css';

function AddHomeModal(props) {

    const userId = props.userId;
    const showAddHomeModal = props.showAddHomeModal;
    const setShowAddHomeModal = props.setShowAddHomeModal;
    const setTriggerFetchProperties = props.setTriggerFetchProperties;

    const [addPropertyForm, setAddPropertyForm] = useState([]);
    const [addPropertyLoading, setAddPropertyLoading] = useState(false);


    // AddPropertyForm error handling.
    const [addPropertyAddressLineOneError, setAddPropertyAddressLineOneError] = useState(null);
    const [addPropertyCityError, setAddPropertyCityError] = useState(null);
    const [addPropertyStateError, setAddPropertyStateError] = useState(null);
    const [addPropertyZipCodeError, setAddPropertyZipCodeError] = useState(null);
    const [addPropertyPurchasePriceError, setAddPropertyPurchasePriceError] = useState(null);
    const [addPropertyMonthlyHOAFeeError, setAddPropertyMonthlyHOAFeeError] = useState(null);
    const [addPropertyAdditionalAnnualFeeError, setAddPropertyAdditionalAnnualFeeError] = useState(null);
    const [addPropertyRenovationCostsError, setAddPropertyRenovationCostsError] = useState(null);
    const [addPropertyMaintenancePercentError, setAddPropertyMaintenancePercentError] = useState(null);

    const toast = useToast();
    
    /*
     * Name: handleAddPropertyFormChange
     * Parameters: event - The calling event from the form.
     * Description: Called every time the Add Property Form input has changed.
     *              It updates the addPropertyForm state.
     */
    const handleAddPropertyFormChange = (event) => {
        if (event.target.name === ADDRESS_LINE_ONE) {
            setAddPropertyAddressLineOneError(null);
        } else if (event.target.name === ADDRESS_CITY) {
            setAddPropertyCityError(null);
        } else if (event.target.name === ADDRESS_STATE) {
            setAddPropertyStateError(null);
        } else if (event.target.name === ADDRESS_ZIP_CODE) {
            setAddPropertyZipCodeError(null);
        }
        addPropertyForm[event.target.name] = event.target.value;
    }

    const handleAddPropertyDollarFormChange = (event) => {
        if (event.target.name === PURCHASE_PRICE) {
            setAddPropertyPurchasePriceError(null);
        } else if (event.target.name === MONTHLY_HOA_FEE) {
            setAddPropertyMonthlyHOAFeeError(null);
        } else if (event.target.name === ADDITIONAL_ANNUAL_FEES) {
            setAddPropertyAdditionalAnnualFeeError(null);
        } else if (event.target.name === RENOVATION_COSTS) {
            setAddPropertyRenovationCostsError(null);
        }
        event.target.value = event.target.value.replace(/[^0-9 \,]/, '');
        event.target.value = event.target.value.replaceAll(/,/g, '');
        // TODO: Does not support currencies that uses commas as decimal point.
        addPropertyForm[event.target.name] = parseFloat(event.target.value.replace(',', ''));
        event.target.value = dollarfyPrice(event.target.value);
        return;
    } 

    const handleAddPropertyPercentFloatFormChange = (event) => {
        if (event.target.name === MAINTENANCE_PERCENT) {
            setAddPropertyMaintenancePercentError(null);
        }
        let val = event.target.value;
        if (event.target.value.length > 5) {
            event.target.value = val.substring(0, 5);
        }
        addPropertyForm[event.target.name] = event.target.value;
        return;
    }

    /*
     * Name: handleAddPropertyFormSubmit
     * Paramters: None
     * Description: Called on button press. Handles the submit functionality of
     *              adding a property. Makes a POST request to our server.
     */
    const handleAddPropertyFormSubmit = () => {

        if (checkAndAlertAddPropertyFormErrors()) {
            return;
        }

        setAddPropertyLoading(true);

        // Since we have a default of SINGLE_FAMILY_HOME in our Select element,
        // handleAddPropertyFormChange never gets triggered for that element if the
        // value is not changed. Set that default here.
        if (addPropertyForm[PROPERTY_TYPE] == null){
            addPropertyForm[PROPERTY_TYPE] = SINGLE_FAMILY_HOME;
        }

        let fullAddress = addPropertyForm[ADDRESS_LINE_ONE];
        if (addPropertyForm[ADDRESS_LINE_TWO] != null) {
            fullAddress += " " + addPropertyForm[ADDRESS_LINE_TWO];
        }
        fullAddress += " " + addPropertyForm[ADDRESS_CITY];
        fullAddress += " " + addPropertyForm[ADDRESS_STATE];
        fullAddress += " " + addPropertyForm[ADDRESS_ZIP_CODE];

        // Fetch our coordinates.

        let longitude;
        let latitude;
        Radar.forwardGeocode({
            query: fullAddress,
            country: 'US',
        }).then(response => {
            if (response && response["addresses"] && response["addresses"].length > 0){
                longitude = response["addresses"][0]["longitude"];
                latitude = response["addresses"][0]["latitude"];
            }
            
            // Make our POST request.
            axios({
                method: POST,
                url: '/api/v1/user/buyer/properties/' + userId,
                timeout: DEFAULT_POST_TIMEOUT,
                data: {
                    address_line_one: addPropertyForm[ADDRESS_LINE_ONE],
                    address_line_two: addPropertyForm[ADDRESS_LINE_TWO],
                    address_city: addPropertyForm[ADDRESS_CITY],
                    address_state: addPropertyForm[ADDRESS_STATE],
                    address_zip_code: addPropertyForm[ADDRESS_ZIP_CODE],
                    purchase_price: parseFloat(addPropertyForm[PURCHASE_PRICE]),
                    property_type: addPropertyForm[PROPERTY_TYPE],
                    monthly_hoa_fee: parseFloat(addPropertyForm[MONTHLY_HOA_FEE]),
                    additional_annual_fees: parseFloat(addPropertyForm[ADDITIONAL_ANNUAL_FEES]),
                    renovation_costs: parseFloat(addPropertyForm[RENOVATION_COSTS]),
                    maintenance_percent: parseFloat(addPropertyForm[MAINTENANCE_PERCENT]),
                    coordinates: {
                        longitude: parseFloat(longitude),
                        latitude: parseFloat(latitude),
                    }
                }
            }).then(response => {
                // Successful response. Close the modal and load the property. Also
                // reset our form.
                if (response.status === 200) {
                    toast({
                        title: 'Added home',
                        description: "Successfully added new home",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        position: 'top'
                    });
                    setTriggerFetchProperties();
                    setShowAddHomeModal(false);
                    setAddPropertyForm([]);
                    setAddPropertyLoading(false);
                }
            }).catch(error => {
                if (400 === error.response.status) {
                    toast({
                        title: 'Invalid property',
                        description: "Unable to save new property. " + error.response.data,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: 'top'
                    });
                } else {
                    toast({
                        title: 'Internal Server Error',
                        description: "We encountered an error. Please try again later " + error.response.data,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: 'top'
                    })
                }
                setAddPropertyLoading(false);
            })
        })
    }

    const checkAndAlertAddPropertyFormErrors = () => {

        if (addPropertyForm === null || addPropertyForm === undefined) {
            return true;
        }

        var hasErrors = false;

        if (!addPropertyForm[ADDRESS_LINE_ONE] || addPropertyForm[ADDRESS_LINE_ONE].length === 0) {
            hasErrors = true;
            setAddPropertyAddressLineOneError("Required");
        }

        if (!addPropertyForm[ADDRESS_CITY] || addPropertyForm[ADDRESS_CITY].length === 0) {
            hasErrors = true;
            setAddPropertyCityError("Required");
        }

        if (!addPropertyForm[ADDRESS_STATE] || addPropertyForm[ADDRESS_STATE].length === 0) {
            hasErrors = true;
            setAddPropertyStateError("Required");
        }

        if (!addPropertyForm[ADDRESS_ZIP_CODE] || addPropertyForm[ADDRESS_ZIP_CODE].length === 0) {
            hasErrors = true;
            setAddPropertyZipCodeError("Required");
        }

        if (!addPropertyForm[PURCHASE_PRICE] || addPropertyForm[PURCHASE_PRICE].length === 0) {
            hasErrors = true;
            setAddPropertyPurchasePriceError("Required and greater than 0");
        } else if (parseFloat(addPropertyForm[PURCHASE_PRICE]) > 1000000000.0) {
            hasErrors = true;
            setAddPropertyPurchasePriceError("Purchase price greater than $1 billion");
        } else if (parseFloat(addPropertyForm[PURCHASE_PRICE]) < 0.0) {
            hasErrors = true;
            setAddPropertyPurchasePriceError("Purchase price negative")
        }

        if (addPropertyForm[MONTHLY_HOA_FEE] === null || addPropertyForm[MONTHLY_HOA_FEE] === undefined) {
            // Do nothing.
        } else if (parseFloat(addPropertyForm[MONTHLY_HOA_FEE]) > 1000000.0) {
            hasErrors = true;
            setAddPropertyMonthlyHOAFeeError("Monthly HOA fee more than $1 million");
        } else if (parseFloat(addPropertyForm[MONTHLY_HOA_FEE]) < 0.0) {
            hasErrors = true;
            setAddPropertyMonthlyHOAFeeError("Monthly HOA fee negative");
        }

        if (addPropertyForm[ADDITIONAL_ANNUAL_FEES] === null || addPropertyForm[ADDITIONAL_ANNUAL_FEES] === undefined) {
            // Do nothing.
        } else if (parseFloat(addPropertyForm[ADDITIONAL_ANNUAL_FEES]) > 100000000.0) {
            hasErrors = true;
            setAddPropertyAdditionalAnnualFeeError("Additional annual fee more than $100 million");
        } else if (parseFloat(addPropertyForm[ADDITIONAL_ANNUAL_FEES]) < 0.0) {
            hasErrors = true;
            setAddPropertyAdditionalAnnualFeeError("Additional annual fee negative");
        }

        if (addPropertyForm[RENOVATION_COSTS] === null || addPropertyForm[RENOVATION_COSTS] === undefined) {
            // Do nothing.
        } else if (parseFloat(addPropertyForm[RENOVATION_COSTS]) > 500000000.0) {
            hasErrors = true;
            setAddPropertyRenovationCostsError("Renovation costs more than $500 million");
        } else if (parseFloat(addPropertyForm[ADDITIONAL_ANNUAL_FEES]) < 0.0) {
            hasErrors = true;
            setAddPropertyRenovationCostsError("Renovation costs negative");
        }

        if (addPropertyForm[MAINTENANCE_PERCENT] === null || addPropertyForm[MAINTENANCE_PERCENT] === undefined) {
            // Do nothing.
        } else if (parseFloat(addPropertyForm[MAINTENANCE_PERCENT]) > 100.0) {
            hasErrors = true;
            setAddPropertyMaintenancePercentError("Maintenance % more than 100%");
        } else if (parseFloat(addPropertyForm[MAINTENANCE_PERCENT]) < 0.0) {
            hasErrors = true;
            setAddPropertyMaintenancePercentError("Maintenance % negative");
        }
        
        return hasErrors;
    }

    return (
        <Modal size='sm' isOpen={showAddHomeModal} onClose={() => setShowAddHomeModal(false)} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Add Home</ModalHeader>
                <ModalCloseButton onClose={() => setShowAddHomeModal(false)} onMouseDown={() => setShowAddHomeModal(false)}/>
                <ModalBody style={{
                    paddingTop: "0",
                }}>
                    <p style={{
                        color: "grey",
                        fontSize: "0.6em",
                        margin: "0px 0px 5px 0px",
                        padding: "0"
                    }}>
                        ADDRESS
                    </p>
                    <Tooltip
                        bg='red'
                        hasArrow
                        label={addPropertyAddressLineOneError}
                        isOpen={addPropertyAddressLineOneError !== null}
                        placement='top-end'
                    >
                        <InputGroup>
                            <InputLeftElement pointerEvents='none' style={{
                                margin: "-4px 0px 0px 0px"
                            }}>
                                <FaLocationDot color='#cccccc' />
                            </InputLeftElement>
                            <Input 
                                size='sm'
                                id="add_home_modal_address"
                                type='text'
                                name={ADDRESS_LINE_ONE}
                                onChange={handleAddPropertyFormChange}
                                placeholder='e.g. 123 Main Street'
                                className="default_input form_input"
                            />
                        </InputGroup>
                    </Tooltip>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <InputGroup>
                            <Input 
                                size='sm'
                                type='text'
                                name={ADDRESS_LINE_TWO}
                                onChange={handleAddPropertyFormChange}
                                placeholder="Address Line Two (Optional)"
                                className="default_input form_input"
                            />
                        </InputGroup>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(44% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                CITY
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyCityError}
                                isOpen={addPropertyCityError !== null}
                                placement='top'
                            >
                                <InputGroup>
                                    <Input
                                        size='sm'
                                        type='text'
                                        name={ADDRESS_CITY}
                                        onChange={handleAddPropertyFormChange}
                                        className="default_input form_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(24% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                STATE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyStateError}
                                isOpen={addPropertyStateError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <Select className="default_input" name={ADDRESS_STATE} onChange={handleAddPropertyFormChange} size='sm'>
                                        <option hidden>-</option>
                                        <option value="AL">AL</option>
                                        <option value="AK">AK</option>
                                        <option value="AR">AR</option>
                                        <option value="AZ">AZ</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="HI">HI</option>
                                        <option value="IA">IA</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="MA">MA</option>
                                        <option value="MD">MD</option>
                                        <option value="ME">ME</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MO">MO</option>
                                        <option value="MS">MS</option>
                                        <option value="MT">MT</option>
                                        <option value="NC">NC</option>
                                        <option value="NE">NE</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NV">NV</option>
                                        <option value="NY">NY</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WI">WI</option>
                                        <option value="WV">WV</option>
                                        <option value="WY">WY</option>
                                    </Select>
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(32% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                ZIP CODE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyZipCodeError}
                                isOpen={addPropertyZipCodeError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <Input
                                        size='sm'
                                        type='text'
                                        name={ADDRESS_ZIP_CODE}
                                        onChange={handleAddPropertyFormChange}
                                        className="default_input form_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                HOME PURCHASE PRICE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyPurchasePriceError}
                                isOpen={addPropertyPurchasePriceError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={PURCHASE_PRICE}
                                            onChange={handleAddPropertyDollarFormChange}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                PROPERTY TYPE
                            </p>
                            <Select 
                                size='sm'
                                name={PROPERTY_TYPE}
                                onChange={handleAddPropertyFormChange}
                                variant='outline'
                                className="default_input"
                                style={{
                                    fontSize: "0.8em",
                                }}
                            >
                                <option value={SINGLE_FAMILY_HOME}>Single Family Home</option>
                                <option value={APARTMENT}>Apartment</option>
                                <option value={CONDOMINIUM}>Condominium</option>
                                <option value={TOWNHOME}>Townhome</option>
                                <option value={DUPLEX}>Duplex</option>
                                <option value={MULTIPLEX}>Multiplex</option>
                                <option value={TRAILER}>Trailer</option>
                                <option value={MANUFACTURED}>Manufactured</option>
                            </Select>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                MONTHLY HOA FEE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyMonthlyHOAFeeError}
                                isOpen={addPropertyMonthlyHOAFeeError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={MONTHLY_HOA_FEE}
                                            onChange={handleAddPropertyDollarFormChange}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                OTHER ANNUAL FEES
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyAdditionalAnnualFeeError}
                                isOpen={addPropertyAdditionalAnnualFeeError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={ADDITIONAL_ANNUAL_FEES}
                                            onChange={handleAddPropertyDollarFormChange}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                RENOVATION COSTS
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyRenovationCostsError}
                                isOpen={addPropertyRenovationCostsError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px",
                                            padding: "0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={RENOVATION_COSTS}
                                            onChange={handleAddPropertyDollarFormChange}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                MAINTENANCE %
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={addPropertyMaintenancePercentError}
                                isOpen={addPropertyMaintenancePercentError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        style={{
                                            margin: "-4px 0px 0px 0px",
                                        }}
                                        children='%'
                                    />
                                    <Input
                                        size='sm'
                                        type='number'
                                        name={MAINTENANCE_PERCENT}
                                        onChange={handleAddPropertyPercentFloatFormChange}
                                        className="default_input form_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onMouseDown={() => setShowAddHomeModal(false)} mr={3} size='sm' className="negative_button hover">Cancel</Button>
                    <Button 
                        className="hover"
                        onMouseDown={() => handleAddPropertyFormSubmit()}
                        style={{
                            backgroundColor: '#05b96b',
                            // boxShadow: "1px 1px 1px 1px #dddddd",
                            borderRadius: "4px",
                            color: 'white'
                        }} size='sm'>
                        {
                            addPropertyLoading ?
                            <l-dot-pulse
                                size="25"
                                speed="1.25" 
                                color="white" 
                            ></l-dot-pulse> :
                            <>Add Home</>
                        }
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default AddHomeModal;