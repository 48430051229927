import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Flex,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';

import { FaRegStar, FaStar, FaTrashAlt } from 'react-icons/fa';
import { FaHouseChimney } from 'react-icons/fa6';
import { MdClose, MdEdit } from 'react-icons/md';
import { dotPulse } from 'ldrs';

import {
    ADDITIONAL_ANNUAL_FEES,
    ADDRESS_CITY,
    ADDRESS_LINE_ONE, ADDRESS_LINE_TWO,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    APARTMENT,
    CONDOMINIUM,
    DEFAULT_DELETE_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT,
    DELETE,
    DUPLEX,
    ID,
    IS_FAVORITE,
    MAINTENANCE_PERCENT,
    MANUFACTURED,
    MONTHLY_HOA_FEE,
    MULTIPLEX,
    PATCH,
    PROPERTY_TYPE,
    PURCHASE_PRICE,
    RENOVATION_COSTS,
    SINGLE_FAMILY_HOME,
    TOWNHOME,
    TRAILER
} from '../../../utils/constants.js';
import { dollarfyPrice, prettifyPropertyType } from '../../../utils/utility.js';

import './PropertyCard.css';

function PropertyCard(props) {

    const user = props.user;
    const property = props.property;
    const callbackTrigger = props.callbackTrigger;
    const isSelectedHome = props.isSelectedHome;
    const setSelectedHome = props.setSelectedHome;

    const toast = useToast();
    dotPulse.register();


    const handleUpdateProperty = (data, callback) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/buyer/properties/' + user[ID] + '/' + property[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            if (200 === response.status) {
                callbackTrigger();
            }
        }).catch(error => {

        });
    }

    return (
        <>
            <Card minW='2xs' onMouseDown={() => setSelectedHome(property)} className="property_card hover" style={{
                backgroundColor: isSelectedHome ? "#05b96b" : "#efefef",
            }}>
                <CardBody className="property_card__body">
                    <Flex justifyContent='space-between'>
                        <Flex align='center' gap={2}>
                            <FaHouseChimney className="property_card__icon" style={{
                                color: isSelectedHome ? "white" : "black",
                            }}/>
                            <Text fontWeight='bold' fontSize='xl' style={{
                                color: isSelectedHome ? "white" : "black",
                            }}>
                                ${dollarfyPrice(property[PURCHASE_PRICE])}
                            </Text>
                        </Flex>
                        {
                            property && property[IS_FAVORITE] === true ? 
                            <FaStar 
                                className="hover"
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleUpdateProperty({is_favorite: false});
                                }}
                                style={{
                                    color: isSelectedHome ? "white" : "black",
                                    height: "20px",
                                    width: "20px"
                            }}/> :
                            <FaRegStar 
                                className="hover"
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleUpdateProperty({is_favorite: true});
                                }}
                                style={{
                                    color: isSelectedHome ? "white" : "black",
                                    height: "20px",
                                    width: "20px",
                            }}/>
                        }
                    </Flex>
                    <Stack spacing='0px'>
                        <Text fontSize='sm' style={{
                            color: isSelectedHome ? "white" : "black",
                        }}>
                            {property[ADDRESS_LINE_ONE]} {property[ADDRESS_LINE_TWO]}
                        </Text>
                        <Text fontSize='sm' style={{
                            color: isSelectedHome ? "white" : "black",
                        }}>
                            {property[ADDRESS_CITY] + ", " + property[ADDRESS_STATE] + " " + property[ADDRESS_ZIP_CODE]}
                        </Text>
                    </Stack>
                </CardBody>
            </Card>
        </>
    );
}

export default PropertyCard;