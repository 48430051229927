import React, { useState } from 'react';
import axios from 'axios';

import {
    ABOUT_DESCRIPTION,
    FIRST_NAME,
    FIRM,
    LICENSE_NUMBER,
    LICENSE_TYPE,
    LAST_NAME,
    EMAIL,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    SALE_EXPERTISE,
    ASSET_SPECIALTY,
    PHONE_NUMBER,
    SHORT_SALE,
    FORECLOSURE,
    SINGLE_FAMILY_HOME,
    APARTMENT,
    TOWNHOME,
    DUPLEX,
    MULTIPLEX,
    CONDOMINIUM,
    TRAILER,
    MANUFACTURED
} from '../../../utils/constants.js';

import {
    Checkbox,
    Heading,
    InputGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    Icon,
    Select,
    ModalFooter,
    Button,
    useToast,
    Stack,
    Text,
    Flex,
    Textarea
} from '@chakra-ui/react';

import { FaCircleInfo } from 'react-icons/fa6';
import { dollarfyPrice } from '../../../utils/utility.js';

function EditProfileModal(props) {

    const user = props.user;
    const agent = props.agent;
    const showEditProfileModal = props.showEditProfileModal;
    const setShowEditProfileModal = props.setShowEditProfileModal;

    const handleUpdateUserProfile = props.handleUpdateUserProfile;

    const [saveProfileLoading, setSaveProfileLoading] = useState(false);
    const [agentForm, setAgentForm] = useState([]);
    const [aboutDescriptionTextareaValue, setAboutDescriptionTextareaValue] = useState('');

    const MAX_ABOUT_DESCRIPTION_LENGTH = 500;

    const prettifyLicenseType = (val) => {
        switch (val) {
            case "ManagingBroker":
                return "Managing Broker";
            case "AssociateBroker":
                return "Associate Broker";
            default:
        }
        return val;
    }

    const renderProfileField = (field) => {
        if (!agent || null === agent[field] || undefined === agent[field] || "" === agent[field]) {
            return "-";
        }
        if (field === LICENSE_TYPE) {
            return prettifyLicenseType(agent[field]);
        }
        return agent[field];
    }

    const renderWorkingStyle = (workingStyle) => {
        if (!agent || !agent[WORKING_STYLE] || null === agent[WORKING_STYLE]) {
            return "-";
        }
        switch(agent[WORKING_STYLE]) {
            case "PartOfTeam":
                return "Part of a team";
            case "SoleLead":
                return "Sole lead";
            default:
                return "-";
        };
    }

    const getSaleExpertiseValues = () => {

        let saleExpertiseValues = [];
        let saleExpertiseCheckboxes = document.getElementsByClassName("sale_expertise__checkbox");
        for (let i = 0; i < saleExpertiseCheckboxes.length; i++) {
            let chakraCheckboxElement = saleExpertiseCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                saleExpertiseValues.push(checkboxInput.value);
            }
        }
        return saleExpertiseValues;
    }

    const getAssetSpecialtyValues = () => {
        let assetSpecialtyValues = [];
        let assetSpecialtyCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
        for (let i = 0; i < assetSpecialtyCheckboxes.length; i++) {
            let chakraCheckboxElement = assetSpecialtyCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetSpecialtyValues.push(checkboxInput.value);
            }
        }
        return assetSpecialtyValues;
    }

    return (
        <Modal 
            isOpen={showEditProfileModal}
            onClose={() => setShowEditProfileModal(false)}
            isCentered
            size='3xl'
            scrollBehavior='inside'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "0px"
                }}>Edit Profile</ModalHeader>
                <ModalCloseButton
                    onClose={() => setShowEditProfileModal(false)}
                    onMouseDown={() => setShowEditProfileModal(false)}
                />
                <ModalBody>
                    <Stack gap={6}>
                        <Stack gap={3}>
                            <Stack gap={1}>
                                <Heading fontSize='md'>
                                    Personal Information
                                </Heading>
                                <Flex>
                                    <Icon color='gray' style={{marginTop: "3px"}}>
                                        <FaCircleInfo/>
                                    </Icon>
                                    <Text color='gray' fontSize='xs'>
                                        To edit personal information, visit the Settings page
                                    </Text>
                                </Flex>
                            </Stack>
                            <Stack gap={2}>
                                <Stack gap={0}>
                                    <Text color='gray' fontSize='xs'>
                                        NAME
                                    </Text>
                                    <Text fontSize='md'>
                                        {user && user[FIRST_NAME] ? user[FIRST_NAME] : '-'} {user && user[LAST_NAME] ? user[LAST_NAME] : '-'}
                                    </Text>
                                </Stack>
                                <Stack gap={0}>
                                    <Text color='gray' fontSize='xs'>
                                        EMAIL
                                    </Text>
                                    <Text fontSize='md'>
                                        {user && user[EMAIL] ? user[EMAIL] : '-'}
                                    </Text>
                                </Stack>
                                <Stack gap={0}>
                                    <Text color='gray' fontSize='xs'>
                                        PHONE NUMBER
                                    </Text>
                                    <Text fontSize='md'>
                                        {user && user[PHONE_NUMBER] ? user[PHONE_NUMBER] : '-'}
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack gap={3}>
                            <Stack gap={1}>
                                <Heading fontSize='md'>
                                    Agent Information
                                </Heading>
                                <Flex>
                                    <Icon color='gray' style={{marginTop: "3px"}}>
                                        <FaCircleInfo/>
                                    </Icon>
                                    <Text color='gray' fontSize='xs'>
                                        Edit your personal profile information below
                                    </Text>
                                </Flex>
                            </Stack>
                            <Stack gap={2}>
                                <Stack gap={1}>
                                    <Text color='gray' fontSize='xs'>
                                        ABOUT
                                    </Text>
                                    <InputGroup>
                                        <Textarea
                                            name={ABOUT_DESCRIPTION}
                                            className="default_input"
                                            maxLength={MAX_ABOUT_DESCRIPTION_LENGTH}
                                            placeholder={renderProfileField(ABOUT_DESCRIPTION)}
                                            onChange={(e) => {
                                                setAboutDescriptionTextareaValue(e.target.value);
                                                agentForm[ABOUT_DESCRIPTION] = e.target.value;
                                                setAgentForm(agentForm);
                                            }}
                                            resize={'none'}
                                            size='sm'
                                            style={{
                                                height: "100px",
                                                width: "100%",
                                            }}
                                        />
                                    </InputGroup>
                                    <Flex justifyContent='flex-end'>
                                        <Text color='gray' fontSize='xs'>
                                            {MAX_ABOUT_DESCRIPTION_LENGTH - parseInt(aboutDescriptionTextareaValue.length)} / {MAX_ABOUT_DESCRIPTION_LENGTH} characters left
                                        </Text>
                                    </Flex>
                                </Stack>
                                <Stack gap={1}>
                                    <Text color='gray' fontSize='xs'>
                                        FIRM
                                    </Text>
                                    <InputGroup>
                                        <Input
                                            name={FIRM}
                                            className="default_input"
                                            placeholder={renderProfileField(FIRM)}
                                            onChange={(e) => {
                                                agentForm[FIRM] = e.target.value;
                                                setAgentForm(agentForm);
                                            }}
                                            size='sm'
                                        >
                                        </Input>
                                    </InputGroup>
                                </Stack>
                                <Stack gap={1}>
                                    <Text color='gray' fontSize='xs'>
                                        LICENSE
                                    </Text>
                                    <InputGroup>
                                        <Select
                                            name={LICENSE_TYPE}
                                            className="default_input"
                                            onChange={(e) => {
                                                agentForm[LICENSE_TYPE] = e.target.value;
                                                setAgentForm(agentForm);
                                            }}
                                            size='sm'
                                        >
                                            <option selected={renderProfileField(LICENSE_TYPE) === "-" ? "selected" : ""} hidden>-</option>
                                            <option selected={renderProfileField(LICENSE_TYPE) === "Managing Broker" ? "selected" : ""} value="ManagingBroker">Managing Broker</option>
                                            <option selected={renderProfileField(LICENSE_TYPE) === "Associate Broker" ? "selected" : ""} value="AssociateBroker">Associate Broker</option>
                                            <option selected={renderProfileField(LICENSE_TYPE) === "Salesperson" ? "selected" : ""} value="Salesperson">Salesperson</option>
                                        </Select>
                                    </InputGroup>
                                </Stack>
                                <Stack gap={1}>
                                    <Text color='gray' fontSize='xs'>
                                        REPRESENTATION STYLE
                                    </Text>
                                    <InputGroup>
                                        <Select
                                            name={REPRESENTATION_STYLE}
                                            className="default_input"
                                            onChange={(e) => {
                                                agentForm[REPRESENTATION_STYLE] = e.target.value;
                                                setAgentForm(agentForm);
                                            }}
                                            size='sm'
                                        >
                                            <option selected={renderProfileField(REPRESENTATION_STYLE) === "-" ? "selected" : ""} hidden>-</option>
                                            <option selected={renderProfileField(REPRESENTATION_STYLE) === "Customer" ? "selected" : ""} value="Customer">Customer</option>
                                            <option selected={renderProfileField(REPRESENTATION_STYLE) === "Client" ? "selected" : ""} value="Client">Client</option>
                                        </Select>
                                    </InputGroup>
                                </Stack>
                                <Stack gap={1}>
                                    <Text color='gray' fontSize='xs'>
                                        WORKING STYLE
                                    </Text>
                                    <InputGroup>
                                        <Select
                                            name={WORKING_STYLE}
                                            className="default_input"
                                            onChange={(e) => {
                                                agentForm[WORKING_STYLE] = e.target.value;
                                                setAgentForm(agentForm);
                                            }}
                                            size='sm'
                                        >
                                            <option selected={renderWorkingStyle() === "-" ? "selected" : ""} hidden>-</option>
                                            <option selected={renderWorkingStyle() === "Part of a team" ? "selected" : ""} value="PartOfTeam">Part of a team</option>
                                            <option selected={renderWorkingStyle() === "Sole lead" ? "selected" : ""} value="SoleLead">Sole lead</option>
                                        </Select>
                                    </InputGroup>
                                </Stack>
                                <Stack gap={1}>
                                    <Text color='gray' fontSize='xs'>
                                        SALE EXPERTISE
                                    </Text>
                                    <Stack>
                                        <Checkbox 
                                            className="sale_expertise__checkbox"
                                            name={SHORT_SALE}
                                            value="ShortSale"
                                            defaultChecked={agent[SALE_EXPERTISE] && agent[SALE_EXPERTISE].includes("ShortSale")}
                                            onChange={() => {
                                                agentForm[SALE_EXPERTISE] = getSaleExpertiseValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Short Sale
                                        </Checkbox>
                                        <Checkbox 
                                            className="sale_expertise__checkbox"
                                            name={FORECLOSURE}
                                            value="Foreclosure"
                                            defaultChecked={agent[SALE_EXPERTISE] && agent[SALE_EXPERTISE].includes("Foreclosure")}
                                            onChange={() => {
                                                agentForm[SALE_EXPERTISE] = getSaleExpertiseValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Foreclosure
                                        </Checkbox>
                                    </Stack>
                                </Stack>
                                <Stack gap={1}>
                                    <Text color='gray' fontSize='xs'>
                                        ASSET SPECIALTIES
                                    </Text>
                                    <Stack>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={SINGLE_FAMILY_HOME}
                                            value="SingleFamilyHome"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("SingleFamilyHome")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Single Family Home
                                        </Checkbox>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={APARTMENT}
                                            value="Apartment"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Apartment")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Apartment
                                        </Checkbox>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={TOWNHOME}
                                            value="Townhome"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Townhome")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Townhome
                                        </Checkbox>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={DUPLEX}
                                            value="Duplex"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Duplex")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Duplex
                                        </Checkbox>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={MULTIPLEX}
                                            value="Multiplex"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Multiplex")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Multiplex
                                        </Checkbox>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={CONDOMINIUM}
                                            value="Condominium"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Condominium")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Condominium
                                        </Checkbox>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={TRAILER}
                                            value="Trailer"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Trailer")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Trailer
                                        </Checkbox>
                                        <Checkbox 
                                            className="asset_specialty__checkbox"
                                            name={MANUFACTURED}
                                            value="Manufactured"
                                            defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Manufactured")}
                                            onChange={() => {
                                                agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                                setAgentForm(agentForm);
                                            }}
                                        >
                                            Manufactured
                                        </Checkbox>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </ModalBody>
                <ModalFooter style={{
                    margin: "-5px 0px 5px 0px",
                }}>
                    <Button className="negative_grey_button hover" onMouseDown={() => setShowEditProfileModal(false)} mr={3} size='sm'>Cancel</Button>
                    <Button 
                        className="positive_button hover"
                        onMouseDown={() => {
                            if (Object.keys(agentForm).length === 0) {
                                return;
                            }
                            handleUpdateUserProfile({...agentForm}, "Saved", "Your changes were saved", () => {
                                setAgentForm([]);
                                setShowEditProfileModal(false);
                            })
                        }}
                        size='sm'>
                        {
                            saveProfileLoading ?
                            <l-dot-pulse
                                size="25"
                                speed="1.25" 
                                color="white" 
                            ></l-dot-pulse> :
                            <>Save</>
                        }
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default EditProfileModal;