import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Tab,
    Tabs,
    TabList,
    TabPanel,
    TabPanels,
    Input,
    InputGroup,
    InputLeftElement,
} from '@chakra-ui/react';
import { FaFileLines, FaSliders, FaUsers } from 'react-icons/fa6';
import { MdUpload } from "react-icons/md";
import { IoTrashOutline, IoWarningOutline } from "react-icons/io5";
import { Search2Icon } from '@chakra-ui/icons';

import {
    AGENCY_AGREEMENT,
    CLIENTS,
    CLOSING_DISCLOSURE,
    DEFAULT_DELETE_TIMEOUT,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_POST_TIMEOUT,
    DEFAULT_PUT_TIMEOUT,
    DELETE,
    FIRST_NAME,
    LAST_NAME,
    GET,
    ID,
    POST,
    PUT,
    PURCHASE_AND_SALE_AGREEMENT,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO
} from '../../../utils/constants.js';

import AgentDashboardParentPage from '../utils/AgentDashboardParentPage.js';

import { axiosGetUser, axiosGetAgent } from '../../common/methods/methods.js';
import { checkRequireAgentSignUp, loadMime } from '../../../utils/utility.js';

import { dotPulse } from 'ldrs'

import NoObjectsDisplay from '../../common/NoObjectsDisplay.js';
import MainPanel from '../../common/MainPanel.js';
import UserCard from './UserCard.js';
import AgentSignUpInformationModal from '../utils/AgentSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import './Page.css';
import '../../Generic.css';

function AgentClientsDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [clientsLoading, setClientsLoading] = useState(true);

    const [agent, setAgent] = useState(null);
    const [agentLoading, setAgentLoading] = useState(true);

    const [clients, setClients] = useState(null);
    const [allClients, setAllClients] = useState(null);

    const [clientToDefaultPropertyMap, setClientToDefaultPropertyMap] = useState(null);
    const [clientToOfferMap, setClientToOfferMap] = useState(null);

    const [documentModalClient, setDocumentModalClient] = useState(null);

    const [finishSignUpFormAgent, setFinishSignUpFormAgent] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    dotPulse.register()

    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (null !== user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            setUserLoading(false);
        });
    }, [userId]);

    useEffect(() => {
        axiosGetAgent(userId).then(agent => {
            if (agent) {
                if (checkRequireAgentSignUp(agent) === true) {
                    setFinishSignUpFormAgent(agent);
                }
                setAgent(agent);
                setAgentLoading(false);
            } 
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId])

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/clients/' + userId,
            timeout: DEFAULT_GET_TIMEOUT
        }).then(response => {
            if (200 === response.status) {
                let clients = response.data;
                if (clients === null || clients.length === 0) {
                    setClients([]);
                    setAllClients([]);
                    setClientsLoading(false);
                    return;
                }
                setClients(clients);
                setAllClients(clients);
                let clientIds = [];
                for (let i = 0; i < clients.length; i++) {
                    let client = clients[i];
                    clientIds.push(client[ID])
                }
                // Make 2 calls to fetch our offers and our properties per user.
                axios.all([
                    axios({
                        method: POST,
                        url: '/api/v1/user/agent/clients/properties/' + userId,
                        timeout: DEFAULT_POST_TIMEOUT,
                        data: { client_ids: clientIds }
                    }),
                    axios({
                        method: POST,
                        url: '/api/v1/user/agent/clients/offers/' + userId,
                        timeout: DEFAULT_POST_TIMEOUT,
                        data: { client_ids: clientIds }
                    })
                ]).then(axios.spread((propertiesResponse, offersResponse) => {
                    if (200 === propertiesResponse.status) {
                        let defaultProperties = propertiesResponse.data;
                        let clientToDefaultPropertyMap = new Map();
                        for (let i = 0; i < defaultProperties.length; i++) {
                            let defaultProperty = defaultProperties[i];
                            clientToDefaultPropertyMap.set(defaultProperty["buyer_id"], defaultProperty);
                        }
                        setClientToDefaultPropertyMap(clientToDefaultPropertyMap);
                    }
                    if (200 === offersResponse.status) {
                        let offers = offersResponse.data;
                        let clientToOfferMap = new Map();
                        for (let i = 0; i < offers.length; i++) {
                            let offer = offers[i];
                            clientToOfferMap.set(offer["buyer_id"], offer);
                        }
                        setClientToOfferMap(clientToOfferMap);
                    }
                })).catch(error => {
                })
                setClientsLoading(false);
            }
        }).catch(error => {
        })
    }, [userId]);

    const handleSearchClientsChange = (event) => {
        let searchInput = event.target.value;
        // Strip out our empty spaces in search input.
        searchInput = searchInput.replace(/ /g,'').toLowerCase();
        // Search is empty, reset to all clients.
        if ("" === searchInput) {
            setClients(allClients);
            return;
        }

        // Input is not null, let's filter our client.
        setClients(allClients.filter((client) => {
            let name = client[FIRST_NAME] + client[LAST_NAME];

            let defaultOffer = clientToDefaultPropertyMap.get(client[ID]);
            let address = defaultOffer[ADDRESS_LINE_ONE] + 
                          defaultOffer[ADDRESS_LINE_TWO];

            name = name.replace(/ /g,'');
            name = name.toLowerCase();

            address = address.replace(/ /g,'');
            address = address.toLowerCase();

            return name.includes(searchInput) || address.includes(searchInput);
        }));
        return;
    }

    const NoDocumentObject = () => {
        return (
            <div style={{
                backgroundColor: "#efefef",
                borderRadius: "4px",
                float: "left",
                height: "calc(100vh - 100px - 75px",
                margin: "0px 25px 0px 25px",
                padding: "0px 25px 0px 25px",
                width: "calc(100% - 50px)",
            }}>
                <div style={{
                    margin: "250px 0px 50px 0px",
                }}>
                    <IoWarningOutline style={{
                        height: "40px",
                        margin: "0px auto 0px auto",
                        width: "40px",
                    }}/>
                    <p style={{
                        fontWeight: "bold",
                        margin: "15px 0px 0px 0px",
                        textAlign: "center",
                    }}>
                        NO FILE UPLOADED
                    </p>
                </div>
            </div>
        )
    }

    const DocumentModal = () => {

        const [tabIndex, setTabIndex] = useState(0);
        const tabToFileTypeMap = new Map([
            [0, AGENCY_AGREEMENT],
            [1, PURCHASE_AND_SALE_AGREEMENT],
            [2, CLOSING_DISCLOSURE],
        ]);

        const [documentsLoading, setDocumentsLoading] = useState(true);

        const [agencyAgreementURL, setAgencyAgreementURL] = useState("");
        const [purchaseAndSaleAgreementURL, setPurchaseAndSaleAgreementURL] = useState("");
        const [closingDisclosureURL, setClosingDisclosureURL] = useState("");

        const [triggerFetchDocument, setTriggerFetchDocuments] = useState(false);

        useEffect(() => {
            // Fetch all our documents if they exist.
            axios({
                method: GET,
                url: '/api/v1/user/agent/clients/files/' + userId + '/' + documentModalClient[ID],
                timeout: DEFAULT_GET_TIMEOUT,
            }).then(response => {
                if (200 === response.status) {
                    let documentURLs = response.data;
                    setAgencyAgreementURL(documentURLs["agency_agreement_public_url"]);
                    setPurchaseAndSaleAgreementURL(documentURLs["purchase_and_sale_agreement_public_url"]);
                    setClosingDisclosureURL(documentURLs["closing_disclosure_public_url"]);
                    setDocumentsLoading(false);
                }
            }).catch(error => {
                setDocumentsLoading(false);
            });
        }, [triggerFetchDocument]);

        const handleUploadFileChange = (e, fileType) => {

            let file = e.target.files[0];
    
            loadMime(file, function(fileMimeType) {
                // Handle saving the file to our server in the background.
                axios({
                    method: GET,
                    url: '/api/v1/user/agent/clients/file/upload_url/' + userId + '/' + documentModalClient[ID] + '?file_type=' + fileType + '&mime_type=' + fileMimeType,
                    timeout: DEFAULT_GET_TIMEOUT,
                }).then(response => {
                    let uploadFile = response.data;
                    let uploadSignedURL = uploadFile["upload_signed_url"];
                    axios({
                        method: PUT,
                        url: uploadSignedURL,
                        timeout: DEFAULT_PUT_TIMEOUT,
                        data: file,
                        headers: {
                            'Content-Type': fileMimeType
                        }
                    }).then(response=> {
                        if (200 === response.status) {
                            setTriggerFetchDocuments(!triggerFetchDocument);
                        }
                    }).catch(error=> {
                        // TODO
                    });
                }).catch(error => {
                    // TODO
                });
            });
        }

        const handleDeleteFile = (fileType) => {
            axios({
                method: DELETE,
                url: '/api/v1/user/agent/clients/file/' + userId + '/' + documentModalClient[ID] + '?file_type=' + fileType,
                timeout: DEFAULT_DELETE_TIMEOUT,
            }).then(response => {
                if (200 === response.status) {
                    setTriggerFetchDocuments(!triggerFetchDocument);
                }
            }).catch(error => {
                // TODO
            });
        }

        return (
            <Modal size='6xl' isOpen={setDocumentModalClient !== null} onClose={() => {
                setDocumentModalClient(null);
                setAgencyAgreementURL("");
                setPurchaseAndSaleAgreementURL("");
                setClosingDisclosureURL("");
            }} isCentered style={{
            }}>
                <ModalOverlay/>
                <ModalContent style={{
                    height: "calc(100vh - 100px)",
                }}>
                    <ModalHeader style={{
                        paddingBottom: "0px",
                    }}>
                        {documentModalClient && documentModalClient[FIRST_NAME] && documentModalClient[LAST_NAME] ? documentModalClient[FIRST_NAME] + " " + documentModalClient[LAST_NAME] + " " : ""} Documents 
                    </ModalHeader>
                    <ModalCloseButton onClose={() => {
                        setDocumentModalClient(null);
                        setAgencyAgreementURL("");
                        setPurchaseAndSaleAgreementURL("");
                        setClosingDisclosureURL("");
                    }} onMouseDown={() => {
                        setDocumentModalClient(null);
                        setAgencyAgreementURL("");
                        setPurchaseAndSaleAgreementURL("");
                        setClosingDisclosureURL("");
                    }}/>
                    <ModalBody style={{
                        paddingBottom: "20px",
                        paddingTop: "0px" 
                    }}>
                        <Tabs align="start" orientation="vertical" variant="unstyled" index={tabIndex}>
                            <TabList style={{
                                margin: "10px 0px 0px 0px",
                                width: "300px",
                            }}>
                                <div className="agents__clients_page__document_modal_icon_box">
                                    <IoTrashOutline className="agents__clients_page__document_modal_icon negative_grey_button hover" onMouseDown={() => {
                                        handleDeleteFile(tabToFileTypeMap.get(tabIndex));
                                    }}/>
                                    <label htmlFor="upload-client-document">
                                        <MdUpload className="agents__clients_page__document_modal_icon negative_button hover"/>
                                    </label>
                                    <input
                                        accept=".pdf"
                                        id="upload-client-document"
                                        onChange={(e) => handleUploadFileChange(e, tabToFileTypeMap.get(tabIndex))}
                                        style={{
                                            display: "none",
                                        }}
                                        type="file"
                                    ></input>
                                    {/* <MdDownload className="agents__clients_page__document_modal_icon positive_button hover"/> */}
                                </div>
                                <Tab className="agent__client_page__tab" onMouseDown={() => setTabIndex(0)} style={{
                                    backgroundColor: tabIndex === 0 ? "#efefef" : "",
                                }}>
                                    <FaFileLines className="agent__client_page__tab_icon"/>
                                    <p className="agents__clients_page__document_modal__document_text">
                                        Agency Agreement
                                    </p>
                                </Tab>
                                <Tab className="agent__client_page__tab" onMouseDown={() => setTabIndex(1)} style={{
                                    backgroundColor: tabIndex === 1 ? "#efefef" : "",
                                }}>
                                    <FaFileLines className="agent__client_page__tab_icon"/>
                                    <p className="agents__clients_page__document_modal__document_text">
                                        Purchase & Sale Agreement
                                    </p>
                                </Tab>
                                <Tab className="agent__client_page__tab" onMouseDown={() => setTabIndex(2)} style={{
                                    backgroundColor: tabIndex === 2 ? "#efefef" : "",
                                }}>
                                    <FaFileLines className="agent__client_page__tab_icon"/>
                                    <p className="agents__clients_page__document_modal__document_text">
                                        Closing Disclosure
                                    </p>
                                </Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel className="tabs__tab_panel" index={0}>
                                    {
                                        documentsLoading ?
                                        // Default values shown
                                        <div className="user__clients_page_document_modal__default_background">
                                            <div className="user__clients_page_document_modal__loading_spinner_box">
                                                <l-dot-pulse
                                                    size="40"
                                                    speed="1.5" 
                                                    color="grey" 
                                                ></l-dot-pulse>
                                            </div>
                                        </div> : 
                                        (agencyAgreementURL && agencyAgreementURL !== "" ?
                                        <embed src={agencyAgreementURL + "#navpanes=0"} className="user__clients_page__document_modal__embed">
                                        </embed> :
                                        <NoDocumentObject/>)
                                    }
                                </TabPanel>
                                <TabPanel className="tabs__tab_panel" index={1}>
                                    {
                                        documentsLoading ?
                                        // Default values shown
                                        <div className="user__clients_page_document_modal__default_background">
                                            <div className="user__clients_page_document_modal__loading_spinner_box">
                                                <l-dot-pulse
                                                    size="40"
                                                    speed="1.5" 
                                                    color="grey" 
                                                ></l-dot-pulse>
                                            </div>
                                        </div> : 
                                        (purchaseAndSaleAgreementURL && purchaseAndSaleAgreementURL !== "" ?
                                        <embed src={purchaseAndSaleAgreementURL + "#navpanes=0"} className="user__clients_page__document_modal__embed">
                                        </embed> :
                                        <NoDocumentObject/>)
                                    }
                                </TabPanel>
                                <TabPanel className="tabs__tab_panel" index={2}>
                                    {
                                        documentsLoading ?
                                        // Default values shown
                                        <div className="user__clients_page_document_modal__default_background">
                                            <div className="user__clients_page_document_modal__loading_spinner_box">
                                                <l-dot-pulse
                                                    size="40"
                                                    speed="1.5" 
                                                    color="grey" 
                                                ></l-dot-pulse>
                                            </div>
                                        </div> :
                                        (closingDisclosureURL && closingDisclosureURL !== "" ?
                                        <embed src={closingDisclosureURL + "#navpanes=0"} className="user__clients_page__document_modal__embed">
                                        </embed> :
                                        <NoDocumentObject/>)
                                    }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }

    const renderFilters = () => {
        return (
            <div style={{
                width: "100%",
            }}>
                <div style={{
                    display: "flex",
                }}>
                    <FaSliders style={{
                        height: "15px",
                        margin: "7px 12px 0px 0px",
                        width: "15px",
                    }} />
                    <div>
                        <InputGroup style={{
                            width: "350px"
                        }}>
                            <InputLeftElement size='sm' pointerEvents='none'>
                                <Search2Icon color='gray' style={{
                                    margin: "-7.5px 0px 0px 0px"
                                }}/>
                            </InputLeftElement>
                            <Input
                                className="default_input"
                                onChange={handleSearchClientsChange}
                                size='sm'
                                style={{
                                    margin: "0px 10px 0px 0px",
                                }}
                                placeholder='Search by name'
                            />
                        </InputGroup>
                    </div>
                </div>
            </div>
        );
    }

    const Clients = () => {

        let rows = [];

        for (let i = 0; i < clients.length; i++) {
            let client = clients[i];
            rows.push(
                <UserCard
                    client={client}
                    defaultProperty={clientToDefaultPropertyMap ? clientToDefaultPropertyMap.get(client[ID]) : null}
                    offer={clientToOfferMap ? clientToOfferMap.get(client[ID]) : null}
                    setDocumentModalClient={() => setDocumentModalClient(client)}
                />
            );          

        }

        return (
            <div style={{
                margin: "15px 0px 0px 0px",
            }}>
                <div style={{
                    display: "flex",
                }}>
                    <div style={{
                        width: "32px",
                    }}></div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "0px 0px 0px 15px",
                        width: "calc(100% - 32px - 15px)",
                    }}>
                        <p className="clients__user_card__header_text" style={{
                            width: "27.5%",
                        }}>
                            NAME
                        </p>
                        <p className="clients__user_card__header_text" style={{
                            width: "12.5%",
                        }}>
                            PRICE
                        </p>
                        <p className="clients__user_card__header_text" style={{
                            width: "35%",
                        }}>
                            ADDRESS
                        </p>
                        <p className="clients__user_card__header_text" style={{
                            width: "10%",
                        }}>
                            COMMISSION
                        </p>
                        <p className="clients__user_card__header_text" style={{
                            width: "10%",
                        }}>
                            TERM
                        </p>
                        <p className="clients__user_card__header_text" style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "5%",
                        }}>
                            DOCUMENTS
                        </p>
                    </div>
                </div>
                <div style={{
                    height: "calc(100vh - 50px - 125px)",
                    overflowY: "scroll",
                }}>
                    {rows}
                </div>
            </div>
        );
    }

    if (userLoading || agentLoading) return;

    return (
        <AgentDashboardParentPage user={user} tab={CLIENTS} agent={agent}>
            {
                clientsLoading || finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormAgent ?
                        <AgentSignUpInformationModal
                            user={finishSignUpFormAgent}
                            setFinishSignUpFormAgent={setFinishSignUpFormAgent}
                        />: null
                    }
                    <MainPanel>
                        {
                            clients && clients.length > 0 ? 
                            <>
                                {renderFilters()}
                                { documentModalClient ? <DocumentModal/> : null }
                                <Clients/>
                            </> : 
                            <NoObjectsDisplay
                                icon={FaUsers}
                                titleText={"No Clients Found"}
                                subtitleText={"You don't have any clients yet. Connect with potential leads and make them offers to bring them on as clients"}
                                buttonText={"Search Leads"}
                                buttonCallback={() => {window.location.href="/user/agent/dashboard/leads/" + user[ID]}}
                            />
                        }
                    </MainPanel>
                </>
            }
        </AgentDashboardParentPage>
    );
}

export default AgentClientsDashboardPage;