import React from 'react';

import BuyerNavigationBar from './NavigationBar.js';
import BuyerViewNavigationBar from './BuyerViewNavigationBar.js';

import {
    BUYER
} from '../../../utils/constants.js';

import './BuyerDashboardParentPage.css';

function BuyerDashboardParentPage(props) {

    const user = props.user;
    const buyer = props.buyer;
    const tab = props.tab;

    return (
        <div className="buyer_dashboard__parent_page" style={{
            backgroundColor: "#ffffff",
            overflow: "scroll",
        }}>
            <BuyerViewNavigationBar user={user} buyer={buyer} viewType={BUYER} displaySwitchButton={true}/>
            <div style={{
                display: "flex",
            }}>
                <BuyerNavigationBar user={user} currTab={tab}/>
                <div className="buyer_dashboard__parent">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default BuyerDashboardParentPage;