import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
    Button,
    Checkbox,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Select,
    Tooltip,
    useToast
} from '@chakra-ui/react'
import { EmailIcon, LockIcon, PhoneIcon } from '@chakra-ui/icons'

import { 
    isValidEmail,
    isValidPassword,
    isValidPhoneNumber,
 } from '../utils/utility.js';

import Footer from './Footer.js';
import NavigationBar from './NavigationBar.js';
import { StreamChat } from 'stream-chat';
import { dotPulse } from 'ldrs';

import './SignUpPage.css';
import './Generic.css';

import {
    AGENT,
    BOTH,
    BUYER,
    FIRST_NAME,
    FORM_FIRST_NAME,
    FORM_LAST_NAME,
    FORM_EMAIL,
    FORM_PHONE_NUMBER,
    FORM_PASSWORD,
    FORM_CONFIRM_PASSWORD, 
    FORM_USER_TYPE,
    ID,
    USER_TYPE,
} from '../utils/constants.js';
import { useAuth } from './auth/useAuth.js';

const chatClient = StreamChat.getInstance("ufb64fqzh9zr");

function SignUpPage(props) {

    const navigate = useNavigate();

    const { signup } = useAuth();

    // Our sign up form. We use this object to track the fields as the user
    // sets them.
    const [form] = useState([]);

    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneNumberError, setPhoneNumberError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState(null);
    const [termsError, setTermsError] = useState(null);

    const [signUpLoading, setSignUpLoading] = useState(false);

    const [searchParams] = useSearchParams();
    let userType = searchParams.get("user_type");
    if (!userType) {
        userType = BUYER;
    }

    dotPulse.register();

    const toast = useToast();

    useEffect(() => {
        // Set our user type as the default, since we only trigger our function on
        // form change. If the user doesn't change the form, we want to use the
        // default, which is currently buyer..
        form[FORM_USER_TYPE] = userType ? userType : BUYER;
    }, []);

    useEffect(() => {
        const handleKeyDown = (e) => {
             const key = e.key;
             if (key === "Enter") {
                handleSignUpFormSubmit();
             }
        };
        document.addEventListener('keydown', handleKeyDown, true);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const checkAndAlertSignUpFormErrors = () => {
        
        if (!form) return true;
        
        var hasErrors = false;

        // Check first name errors.
        if (!form[FORM_FIRST_NAME] || form[FORM_FIRST_NAME].length === 0) {
            hasErrors = true;
            setFirstNameError("First Name is required");
        } else if (form[FORM_FIRST_NAME].length > 90) {
            hasErrors = true;
            setFirstNameError("First Name cannot be more than 90 characters");
        }

        // Check last name errors.
        if (!form[FORM_LAST_NAME] || form[FORM_LAST_NAME].length === 0) {
            hasErrors = true;
            setLastNameError("Last Name is required");
        } else if (form[FORM_LAST_NAME].length > 90) {
            hasErrors = true;
            setLastNameError("Last Name cannot be more than 90 characters");
        }

        // Check email errors.
        if (!form[FORM_EMAIL] || form[FORM_EMAIL].length === 0) {
            hasErrors = true;
            setEmailError("Email is required");
        } else if (!isValidEmail(form[FORM_EMAIL])) {
            hasErrors = true;
            setEmailError("Email is invalid");
        } else if (form[FORM_EMAIL].length > 255) {
            hasErrors = true;
            setEmailError("Email cannot be more than 255 characters");
        }

        // Check Phone number errors.
        if (!form[FORM_PHONE_NUMBER] || form[FORM_PHONE_NUMBER].length === 0) {
            hasErrors = true;
            setPhoneNumberError("Phone Number is required");
        } else if (!isValidPhoneNumber(form[FORM_PHONE_NUMBER])) {
            hasErrors = true;
            setPhoneNumberError("Phone Number is invalid");
        }

        var hasPasswordErrors = false;
        // Check password errors.
        if (!form[FORM_PASSWORD] || form[FORM_PASSWORD].length === 0) {
            hasErrors = true;
            hasPasswordErrors = true;
            setPasswordError("Password is required");
        } else if (!isValidPassword(form[FORM_PASSWORD])) {
            hasErrors = true;
            hasPasswordErrors = true;
            setPasswordError("Passwords must contain at least 1 upper case, 1 special character, and be 8 to 255 characters")
        }

        // Check confirm password errors.
        if (!form[FORM_CONFIRM_PASSWORD] || form[FORM_CONFIRM_PASSWORD].length === 0) {
            hasErrors = true;
            hasPasswordErrors = true;
            setConfirmPasswordError("Password confirmation is required");
        }

        // Check if there are password errors already so we don't override them.
        if (!hasPasswordErrors && form[FORM_PASSWORD] && form[FORM_CONFIRM_PASSWORD] && form[FORM_PASSWORD] !== form[FORM_CONFIRM_PASSWORD]) {
            hasErrors = true;
            setConfirmPasswordError("Passwords do not match")
        }

        let termsAgreedCheckbox = document.getElementById("termsAndAgreementCheckbox");
        if (!termsAgreedCheckbox.checked) {
            hasErrors = true;
            setTermsError("You must agree to Terms")
        }

        return hasErrors;
    }

    /* Name: handleSignUpFormSubmit
     * Description: Handles the submission of our form. It uses the "form" 
     *              state object to track the values entered by our user.
     *              It makes a POST request to our signup REST API.
     * Returns: None
     */
    const handleSignUpFormSubmit = () => {

        setSignUpLoading(true);

        if (checkAndAlertSignUpFormErrors()) {
            setSignUpLoading(false);
            return;
        }

        signup(form[FORM_FIRST_NAME],
               form[FORM_LAST_NAME],
               form[FORM_EMAIL],
               form[FORM_PASSWORD],
               form[FORM_PHONE_NUMBER],
               form[FORM_USER_TYPE])
        .then((aUser) => {
            let userId = aUser[ID];
            let userType = aUser[USER_TYPE];

            let chatToken;
            let chatUserType;
            if (userType === AGENT) {
                chatToken = aUser.agent_chat_token;
                chatUserType = AGENT;
            } else {
                chatToken = aUser.buyer_chat_token;
                chatUserType = BUYER;
            }
            localStorage.setItem("chatToken", chatToken);

            const chatUser = {
                id: chatUserType.toLowerCase()+"_"+userId,
                name: aUser[FIRST_NAME],
            };
            const connectionPromise = chatClient.connectUser(chatUser, chatToken);
            navigate(userType === AGENT ? '/user/agent/dashboard/leads/' + userId : '/user/buyer/dashboard/agents/' + userId);
        }).catch(error => {
            // Conflicting resource. Account already exists.
            if (error.response.status === 409) {
                toast({
                    title: 'Sign Up Error',
                    description: "Email or Phone Number already associated with an account",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            } else if (error.response.status === 500) {
                toast({
                    title: 'Internal Server Error',
                    description: "An internal server error occurred. Please try again later",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
            setSignUpLoading(false);
        })
    }

    /* Name: handleSignUpFormChange
     * Description: Listener we attach to our Inputs. Allows us to track the
     *              changes made to inputs.
     * Returns: None
     */
    const handleSignUpFormChange = (event) => {
        // Reset our errors.
        if (event.target.name === FORM_FIRST_NAME){
            setFirstNameError(null);
        } else if (event.target.name === FORM_LAST_NAME) {
            setLastNameError(null);
        } else if (event.target.name === FORM_EMAIL) {
            setEmailError(null);
        } else if (event.target.name === FORM_PHONE_NUMBER) {
            setPhoneNumberError(null);
        } else if (event.target.name === FORM_PASSWORD) {
            setPasswordError(null);
        } else if (event.target.name === FORM_CONFIRM_PASSWORD) {
            setConfirmPasswordError(null);
        }
        form[event.target.name] = event.target.value;
        console.log(form);
    }

    const handlePhoneNumberFormChange = (event) => {
        event.target.value = event.target.value.replace(/\D/g,'');
        var size = event.target.value.length;
        if (size>0) {event.target.value="("+event.target.value}
        if (size===3) {event.target.value=event.target.value.slice(0,4)+") "+event.target.value.slice(4,11)}
        if (size>3) {event.target.value=event.target.value.slice(0,4)+") "+event.target.value.slice(4,11)}
        if (size>6) {event.target.value=event.target.value.slice(0,9)+" - " +event.target.value.slice(9)}
        handleSignUpFormChange(event);
        return;
    }

    return (
        <div>
            <div className="signup-page">
                <NavigationBar isUserPage={false}/>
                <div>
                    <p className="signup_page__title">
                        Create your Rezied account
                    </p>
                    <p style={{
                        color: "#5A5A5A",
                        fontSize: "1.0em",
                        textAlign: "center",
                    }}>
                        Tell us a little more about yourself
                    </p>
                    <br></br>
                    <form className="signup_page__form">
                        <div className="signup_page__form_input_block">
                            <div className="signup_page__form_input_parent_half signup_page__form_input_extra_margin_bottom">
                                <label className="signup-page-form-label">
                                    First Name
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={firstNameError}
                                    isOpen={firstNameError !== null}
                                    placement='top-end'
                                >
                                    <Input
                                        name={FORM_FIRST_NAME}
                                        className="default_input signup_page__form_input"
                                        onChange={handleSignUpFormChange}
                                    />
                                </Tooltip>
                            </div>
                            <div className="signup_page__form_input_parent_half">
                                <label className="signup-page-form-label">
                                    Last Name
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={lastNameError}
                                    isOpen={lastNameError !== null}
                                    placement='top-end'
                                >
                                    <Input
                                        className="default_input signup_page__form_input"
                                        name={FORM_LAST_NAME}
                                        onChange={handleSignUpFormChange}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className="signup_page__form_input_block">
                            <div style={{
                                width: "100%",
                            }}>
                                <label className="signup-page-form-label">
                                    Email
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={emailError}
                                    isOpen={emailError !== null}
                                    placement='top-end'
                                >
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                        <EmailIcon color='#cccccc'/>
                                        </InputLeftElement>
                                        
                                            <Input
                                                className="default_input signup_page__form_input"
                                                name={FORM_EMAIL}
                                                onChange={handleSignUpFormChange}
                                            />
                                    </InputGroup>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="signup_page__form_input_block">
                            <div className="signup_page__form_input_parent_large signup_page__form_input_extra_margin_bottom">
                                <label className="signup-page-form-label">
                                    Phone Number
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={phoneNumberError}
                                    isOpen={phoneNumberError !== null}
                                    placement='top-end'
                                >
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                        <PhoneIcon color='#cccccc'/>
                                        </InputLeftElement>
                                        
                                            <Input
                                                className="default_input signup_page__form_input"
                                                name={FORM_PHONE_NUMBER}
                                                onChange={handlePhoneNumberFormChange}
                                                type='tel'
                                            />
                                    </InputGroup>
                                </Tooltip>
                            </div>
                            <div className="signup_page__form_input_parent_small">
                                <label className="signup-page-form-label">
                                    I am an:
                                </label>
                                <Select 
                                    className="default_input signup_page__form_input"
                                    name={FORM_USER_TYPE}
                                    onChange={handleSignUpFormChange}
                                >
                                    <option value={BUYER} selected={userType === BUYER}>Buyer</option>
                                    <option value={AGENT} selected={userType === AGENT}>Agent</option>
                                    <option value={BOTH}>Both</option>
                                </Select>
                            </div>
                        </div>
                        <div className="signup_page__form_input_block">
                            <div style={{
                                width: "100%",
                            }}>
                                <label className="signup-page-form-label">
                                    Password
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={passwordError}
                                    isOpen={passwordError !== null}
                                    placement='top-end'
                                >
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                        <LockIcon color='#cccccc'/>
                                        </InputLeftElement>
                                            <Input
                                                className="default_input signup_page__form_input"
                                                name={FORM_PASSWORD}
                                                onChange={handleSignUpFormChange}
                                                type='password'
                                            />
                                           
                                    </InputGroup>
                                    
                                </Tooltip>
                                <label style={{
                                    color: "grey",
                                    fontSize: "0.8em",
                                }}>
                                    Must contain 1 Uppercase, 1 special character, and be 8 to 255 characters.
                                </label>
                            </div>
                        </div>
                        <div className="signup_page__form_input_block">
                            <div style={{
                                width: "100%"
                            }}>
                                <label className="signup-page-form-label">
                                    Confirm Password
                                </label>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={confirmPasswordError}
                                    isOpen={confirmPasswordError !== null}
                                    placement='top-end'
                                >
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                        <LockIcon color='#cccccc'/>
                                        </InputLeftElement>
                                        
                                            <Input
                                                className="default_input signup_page__form_input"
                                                name={FORM_CONFIRM_PASSWORD}
                                                onChange={handleSignUpFormChange}
                                                type='password'
                                            />
                                    </InputGroup>
                                </Tooltip>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                        }}>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={termsError}
                                isOpen={termsError !== null}
                                placement='top-start'
                            >
                                <Checkbox
                                    id="termsAndAgreementCheckbox"
                                    size='lg'
                                    colorScheme='blue'
                                    onChange={() => setTermsError(null)}
                                    style={{
                                        borderRadius: "8px",
                                        color: "#cccccc"
                                    }}
                                />
                            </Tooltip>
                            <p style={{
                                color: "grey",
                                fontSize: "0.7em",
                                margin: "0px 0px 0px 10px",
                            }}>
                                I agree to Rezied's <Link href="/terms-and-agreements">Terms of Service</Link> and <Link href="/privacy-policy">Privacy Policy</Link> which includes my consent to receive marketing information from ResiRoots. I can unsubscribe from marketing communications at any time.
                            </p>
                        </div>
                        <Button
                            className="hover"
                            onMouseDown={() => {
                                if (!signUpLoading) {
                                    handleSignUpFormSubmit();
                                }
                            }}
                            style={{
                                backgroundColor: "#05b96b",
                                borderRadius: "12px",
                                color: "white",
                                margin: "20px 0px 0px 0px",
                                opacity: signUpLoading ? "0.7" : "1.0",
                                width: "100%",
                        }}>
                            {
                                signUpLoading ?
                                <l-dot-pulse
                                    size="35"
                                    speed="1.25" 
                                    color="white" 
                                ></l-dot-pulse> :
                                <>Create your account</>
                            }
                        </Button>
                    </form>
                    <div style={{
                        color: "grey",
                        margin: "0px 0px 0px 0px",
                        textAlign: "center",
                    }}>
                        <Link href="/auth/login" style={{
                            fontSize: "0.9em",
                        }}>Already have an account? Log In</Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default SignUpPage;