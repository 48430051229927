import './App.css';
import Radar from 'radar-sdk-js';

import { ChakraProvider } from '@chakra-ui/react';

import RequireAuth from './components/auth/RequireAuth.js';

import HomePage from './components/HomePage.js';
import TermsAndAgreementsPage from './components/TermsAndAgreementsPage.js';
import PrivacyPolicyPage from './components/PrivacyPolicyPage.js';
import BuysideCommissionTermsAndConditionsPage from './components/BuysideCommissionTermsAndConditionsPage.js';

import LoginPage from './components/LoginPage.js';
import SignUpPage from './components/SignUpPage.js';

import ContactUsPage from './components/ContactUsPage.js';
import AccountVerificationPage from './components/AccountVerificationPage.js';
import PasswordResetPage from './components/PasswordResetPage.js';

import UserSettingsPage from './components/common/Settings/SettingsPage.js';

import AgentClientsDashboardPage from './components/agent/Clients/Page.js';
import AgentLeadsDashboardPage from './components/agent/Leads/Page.js';
import AgentMessagesDashboardPage from './components/agent/Messages/Page.js';
import AgentProfileDashboardPage from './components/agent/Profile/Page.js';

import BuyerAgentProfilePage from './components/common/AgentProfile.js';
import BuyerAgentsDashboardPage from './components/buyer/Agents/Page.js';
import BuyerHomesDashboardPage from './components/buyer/Homes/Page.js';
import BuyerLoansDashboardPage from './components/buyer/Loans/Page.js';
import BuyerMessagesDashboardPage from './components/buyer/Messages/Page.js';
import BuyerProfileDashboardPage from './components/buyer/Profile/Page.js';

import './components/Generic.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { AuthProvider } from './components/auth/useAuth.js';

Radar.initialize('prj_test_pk_08a3dd5c11884cb727dd34d8570f7e52dfeeb3e5');

function App() {
  return (
    <ChakraProvider>
        <div>
          <link rel="preconnect" href="https://fonts.googleapis.com"></link>
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
          <link href="https://fonts.googleapis.com/css2?family=Gabarito:wght@400;700&display=swap" rel="stylesheet"></link>
          <AuthProvider>
            <Router>
              <Routes>
                <Route exact path="/auth/signup" element={<SignUpPage/>}/>
                <Route path="/auth/login" element={<LoginPage/>}/>
                <Route path="/terms-and-agreements" element={<TermsAndAgreementsPage/>}/>
                <Route path="/buyside-commission-terms-and-conditions" element={<BuysideCommissionTermsAndConditionsPage/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                <Route path="/account-verification" element={<AccountVerificationPage/>}/>
                <Route path="/auth/password/reset" element={<PasswordResetPage/>}/>
                <Route path="/contact-us/:id" element={<ContactUsPage/>}/>
                <Route path="/contact-us" element={<ContactUsPage/>}/>

                {/* All routes below here require Auth*/}
                <Route path="/user/settings/:id" element={
                  <RequireAuth>
                    <UserSettingsPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/agent/dashboard/clients/:id" element={
                  <RequireAuth>
                    <AgentClientsDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/agent/dashboard/leads/:id" element={
                  <RequireAuth>
                    <AgentLeadsDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/agent/dashboard/messages/:id" element={
                  <RequireAuth>
                    <AgentMessagesDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/agent/dashboard/profile/:id" element={
                  <RequireAuth>
                    <AgentProfileDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/buyer/dashboard/homes/:id" element={
                  <RequireAuth>
                    <BuyerHomesDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/buyer/dashboard/loans/:id" element={
                  <RequireAuth>
                    <BuyerLoansDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/buyer/dashboard/agents/:id" element={
                  <RequireAuth>
                    <BuyerAgentsDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/buyer/dashboard/messages/:id" element={
                  <RequireAuth>
                    <BuyerMessagesDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/buyer/dashboard/profile/:id" element={
                  <RequireAuth>
                    <BuyerProfileDashboardPage/>
                  </RequireAuth>
                }/>
                <Route path="/user/buyer/:userId/agent-profile/:agentId" element={
                  <RequireAuth>
                    <BuyerAgentProfilePage/>
                  </RequireAuth>
                }/>
                <Route exact path="/" element={<HomePage/>}/>
              </Routes>
            </Router>
          </AuthProvider>
        </div>
    </ChakraProvider>
  );
}

export default App;