import React from 'react';

import {
    Alert,
    AlertIcon,
    Avatar,
    Button,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ButtonGroup
} from '@chakra-ui/react';

import './ContactedByAgentModal.css';

import { 
    CONNECTION_REQUEST_BY_AGENT_ACCEPTED, 
    FIRM, 
    FIRST_NAME, 
    ID, 
    LAST_NAME, 
    NOT_INTERESTED_BY_BUYER, 
    PROFILE_PICTURE_URL
} from '../../../utils/constants';
import { prettifyAgentRepresentation } from '../../../utils/utility';

function ContactedByAgentModal(props) {
    
    const agent = props.contactedByAgent;
    const setContactedByAgent = props.setContactedByAgent;
    const handleAddConnection = props.handleAddConnection;
    const setShowConnectionsModal = props.setShowConnectionsModal;

    return (
        <Modal size='2xl' isOpen={agent !== null} onClose={() => setContactedByAgent(null)} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader style={{
                    padding: "0px",
                }}>
                    <Image src={require('../../../Images/AgentCardWallpaperMural.png')} style={{
                        borderRadius: "6px 6px 0px 0px",
                        height: "150px",
                        width: "100%",
                    }}/>
                    {/* {agent[FIRST_NAME]} {agent[LAST_NAME]} */}
                </ModalHeader>
                <ModalCloseButton onClose={() => setContactedByAgent(null)} onMouseDown={() => setContactedByAgent(null)}/>
                <ModalBody style={{
                    paddingTop: "0",
                }}>
                    <Avatar src={agent && agent[PROFILE_PICTURE_URL] ? agent[PROFILE_PICTURE_URL] : ""} size='xl' style={{
                        border: "4px solid white",
                        margin: "-50px 0px 0px 0px",
                    }}></Avatar>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <p style={{
                            fontSize: "1.2em",
                            fontWeight: "bold",
                        }}>
                            {agent[FIRST_NAME]} {agent[LAST_NAME]}
                        </p>
                        <p style={{
                            color: "grey",
                            fontSize: "0.85em",
                        }}>
                            {agent[FIRM] ? agent[FIRM] : "No Brokerage"}
                        </p>
                    </div>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <Alert status='info' style={{
                            borderRadius: "4px",
                            fontSize: "0.775em",
                            padding: "7.5px 12.5px 7.5px 12.5px"
                        }}>
                            <AlertIcon />
                                Review this offer carefully. Accepting prevents you from working with other agents unless you unmatch.
                        </Alert>
                    </div>
                    <div style={{
                        display: "flex",
                        margin: "20px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(100% /3)"
                        }}>
                            <p className="contacted_by_agent_modal__offer_title">
                                COMMISSION %
                            </p>
                            <p>
                                {agent.offer_commission_percentage}%
                            </p>
                        </div>
                        <div style={{
                            width: "calc(100% /3)"
                        }}>
                            <p className="contacted_by_agent_modal__offer_title">
                                NUMBER OF MONTHS
                            </p>
                            <p>
                                {agent.offer_number_of_months} mo.
                            </p>
                        </div>
                        <div style={{
                            width: "calc(100% /3)"
                        }}>
                            <p className="contacted_by_agent_modal__offer_title">
                                REPRESENTATION
                            </p>
                            <p>
                                {prettifyAgentRepresentation(agent.offer_representation)}
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        margin: "25px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(100% /3)"
                        }}>
                            <p className="contacted_by_agent_modal__offer_title">
                               EXCLUSIVITY
                            </p>
                            <p>
                                {agent.offer_is_exclusive === true ? "Yes" : "No"}
                            </p>
                        </div>
                        <div style={{
                            width: "calc(100% /3)"
                        }}>
                            <p className="contacted_by_agent_modal__offer_title">
                                RETAINER FEE
                            </p>
                            <p>
                                ${agent.offer_retainer_fee_dollars}
                            </p>
                        </div>
                        <div style={{
                            width: "calc(100% /3)"
                        }}>
                            <p className="contacted_by_agent_modal__offer_title">
                                RETAINER PART OF COMMISSION
                            </p>
                            <p>
                                {agent.offer_retainer_part_of_commission === true ? "Yes" : "No"}
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "40px 0px 0px 0px",
                    }}>
                        <ButtonGroup>
                            <Button 
                                className="negative_grey_button hover" 
                                size="sm"
                                onMouseDown={() => {
                                    handleAddConnection(agent.agent_id, NOT_INTERESTED_BY_BUYER, () => setContactedByAgent(null));
                                }}
                            >
                                Not Interested
                            </Button>
                            <Button 
                                className="positive_button hover" 
                                size='sm'
                                onMouseDown={() => {
                                    handleAddConnection(agent.agent_id, CONNECTION_REQUEST_BY_AGENT_ACCEPTED, () => {
                                        setContactedByAgent(null);
                                        setShowConnectionsModal(false);
                                    });
                                }}
                            >
                                Accept
                            </Button>
                        </ButtonGroup>
                    </div>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ContactedByAgentModal;