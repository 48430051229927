import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { 
    Avatar,
    Badge,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useToast,
    Heading,
    HStack,
    Text,
    Stack,
    Center,
    Flex,
    Divider
 } from '@chakra-ui/react' 
import { Icon, LockIcon } from '@chakra-ui/icons'
import { FaUser, FaUserTie, FaArrowRightLong } from 'react-icons/fa6';

import useAuth from './auth/useAuth.js';

import { StreamChat } from 'stream-chat';

import { dotPulse } from 'ldrs';

import Footer from './Footer.js';
import NavigationBar from './NavigationBar.js';

import './LoginPage.css';
import './Generic.css';

import {
    AGENT,
    BOTH,
    BUYER,
    FIRST_NAME,
    ID,
    LAST_NAME,
    FORM_EMAIL,
    FORM_PASSWORD,
    USER_TYPE,
    PROFILE_PICTURE_URL,
    PHONE_NUMBER,
} from '../utils/constants.js';

const chatClient = StreamChat.getInstance("ufb64fqzh9zr");

function LoginPage(props) {

    const navigate = useNavigate();
    const { login } = useAuth();

    const toast = useToast();

    // Our sign up form. We use this object to track the fields as the user
    // sets them.
    const [form] = useState([]);
    const [user, setUser] = useState(null);
    const [loginLoading, setLoginLoading] = useState(false);

    const [showRenderChooseUserTypeModal, setShowRenderChooseUserTypeModal] = useState(false);

    dotPulse.register();

    useEffect(() => {
        const handleKeyDown = (e) => {
            const key = e.key;
            if (key === "Enter") {
                handleLoginFormSubmit();
            }
        };
        document.addEventListener('keydown', handleKeyDown, true);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    
    }, []);

    /* Name: handleLoginFormSubmit
     * Description: Handles the submission of our form. It uses the "form" 
     *              state object to track the values entered by our user.
     *              It makes a POST request to our signup REST API.
     * Returns: None
     */
    const handleLoginFormSubmit = () => {

        setLoginLoading(true);
        login(form[FORM_EMAIL], form[PHONE_NUMBER], form[FORM_PASSWORD]).then((aUser) => {
            setUser(aUser);
            let userId = aUser[ID];
            let userType = aUser[USER_TYPE];

            let chatToken;
            let chatUserType;
            if (userType === AGENT) {
                chatToken = aUser.agent_chat_token;
                chatUserType = AGENT;
            } else {
                chatToken = aUser.buyer_chat_token;
                chatUserType = BUYER;
            }

            localStorage.setItem("chatToken", chatToken);
    
            const chatUser = {
                id: chatUserType.toLowerCase()+"_"+userId,
                name: aUser[FIRST_NAME],
                image: aUser[PROFILE_PICTURE_URL],
            };
            
            const connectionPromise = chatClient.connectUser(chatUser, chatToken);

            switch(userType) {
                case AGENT:
                    navigate('/user/agent/dashboard/leads/' + userId);
                    return;
                case BUYER:
                    navigate('/user/buyer/dashboard/agents/' + userId);
                    return;
                // If they are both agent and buyer prompt to ask which
                // view they wish to visit.
                case BOTH:
                    setShowRenderChooseUserTypeModal(true);
                    return;
                // Can't be null. Maintain not null constraint on database.
                default:
            }
        }).catch(error => {
            if (error) {
                toast({
                    title: 'Login Error',
                    description: "Invalid username/password combination",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).finally(() => {
            setLoginLoading(false);
        })
    }

    const renderChooseUserTypeModal = () => {
        return (
            <Modal
                isCentered
                isOpen={showRenderChooseUserTypeModal}
                onClose={() => {
                    setShowRenderChooseUserTypeModal(false)
                    setLoginLoading(false);
                }}
                size='xs'
            >
                <ModalOverlay/>
                <ModalContent style={{
                    padding: "0px 0px 10px 0px"
                }}>
                    <ModalCloseButton onClose={() => {
                        setShowRenderChooseUserTypeModal(false);
                        setLoginLoading(false);
                    }} onMouseDown={() => {
                        setShowRenderChooseUserTypeModal(false);
                        setLoginLoading(false);
                    }}/>
                    <ModalBody style={{
                        paddingTop: "30px",
                    }}>
                        <Stack gap={3}>
                            <Flex gap={3}>
                                {
                                    <Avatar
                                        borderRadius='full'
                                        boxSize='55px'
                                        src={user ? user[PROFILE_PICTURE_URL] : ''}
                                        alt={user ? user[FIRST_NAME] + " " + user[LAST_NAME] : 'Name'}
                                    /> 
                                }
                                <Stack gap={1}>
                                    <Text fontWeight='bold' fontSize='lg'>
                                        Welcome {user ? user[FIRST_NAME] : ''},
                                    </Text>
                                    <Badge ml='0' fontSize='0.6em' colorScheme='green' style={{
                                        marginTop: "-2.5px",
                                        padding: "2.5px 7.5px 2.5px 7.5px",
                                    }}>
                                        Buyer/Agent
                                    </Badge>
                                </Stack>
                            </Flex>
                            <Stack gap={2}>
                                <Text fontWeight='bold'>
                                    Take me to...
                                </Text>
                                <Stack gap={2}>
                                    <Divider colorScheme='black'/>
                                    <Flex align='center' gap={2} justifyContent='space-between'
                                        style={{cursor: "pointer"}}
                                        onMouseDown={() => {
                                        if (user && user[ID]) {
                                            navigate('/user/buyer/dashboard/agents/' + user[ID]);
                                            // getChatTokenAndRedirectPage(chatClient, user[ID], user[FIRST_NAME], BUYER, state && state.path ? state.path : '/user/buyer/dashboard/homes/' + user[ID]);
                                        }
                                    }}>
                                        <HStack>
                                            <FaUser/>
                                            <Text>
                                                Buyer
                                            </Text>
                                        </HStack>
                                        <FaArrowRightLong/>
                                    </Flex>
                                    <Divider colorScheme='black'/>
                                    <Flex align='center' gap={2} justifyContent='space-between'
                                        style={{cursor: "pointer"}} 
                                        onMouseDown={() => {
                                            if (user && user[ID]) {
                                                navigate('/user/agent/dashboard/leads/' + user[ID]);
                                                // getChatTokenAndRedirectPage(chatClient, user[ID], user[FIRST_NAME], AGENT, state && state.path ? state.path : '/user/agent/dashboard/leads/' + user[ID]);
                                            }
                                    }}>
                                        <HStack>
                                            <FaUserTie/>
                                            <Text>
                                                Agent
                                            </Text>
                                        </HStack>
                                        <FaArrowRightLong />
                                    </Flex>
                                </Stack>
                            </Stack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }

    /* Name: handleLoginFormChange
     * Description: Listener we attach to our Inputs. Allows us to track the
     *              changes made to inputs.
     * Returns: None
     */
    const handleLoginFormChange = (event) => {
        form[event.target.name] = event.target.value;
    }

    return (
        <div>
            <div className="login-page">
                <NavigationBar isUserPage={false}/>
                {   
                    showRenderChooseUserTypeModal ?
                    renderChooseUserTypeModal() :
                    null
                }
                <Center style={{marginTop: "50px"}}>
                    <Stack spacing={3} width='350px'>
                        <Heading size='lg'>
                            Sign in
                        </Heading>
                        <form>
                            <Stack spacing={4}>
                                <Stack spacing={3}>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                        <Icon as={FaUser} color='#cccccc'/>
                                        </InputLeftElement>
                                        <Input
                                            name={FORM_EMAIL}
                                            onChange={handleLoginFormChange}
                                            style={{
                                                border: "1px solid #cccccc",
                                                borderRadius: "12px",
                                            }}
                                            placeholder='Email'
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents='none'>
                                            <LockIcon color='#cccccc'/>
                                        </InputLeftElement>
                                        <Input
                                            onChange={handleLoginFormChange}
                                            name={FORM_PASSWORD}
                                            style={{
                                                border: "1px solid #cccccc",
                                                borderRadius: "12px",
                                            }}
                                            type='password'
                                            placeholder='Password'
                                        />
                                    </InputGroup>
                                </Stack>
                                <Button 
                                    className="positive_button hover"
                                    onMouseDown={() => {
                                        if (loginLoading) return;
                                        handleLoginFormSubmit();
                                    }}
                                    style={{
                                        opacity: loginLoading ? "0.7" : "1.0",
                                        width: "100%",
                                    }}>
                                        {
                                            loginLoading ? 
                                            <l-dot-pulse
                                                size="35"
                                                speed="1.25" 
                                                color="white" 
                                            ></l-dot-pulse> :
                                            <>Login</>
                                        }
                                    </Button>
                                </Stack>
                        </form>
                        <Flex gap={2} justify='flex-end'>
                            <Link href="/auth/signup" style={{
                                fontSize: "0.9em",
                            }}>
                                <Text color='gray' fontSize='xs'>
                                    Don't have an account? Sign Up
                                </Text>
                            </Link>
                            <Text color='gray' fontSize='xs'>
                                &#x2022;
                            </Text>
                            <Link href="/auth/password/reset" style={{
                                fontSize: "0.9em",
                            }}>
                                <Text color='gray' fontSize='xs'>
                                    Forgot your password?
                                </Text>
                            </Link>
                        </Flex>
                    </Stack>
                </Center>
            </div>
            <Footer/>
        </div>
    );
}

export default LoginPage;