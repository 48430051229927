import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { 
    Button,
    ButtonGroup,
    Checkbox,
    Flex,
    Heading,
    HStack,
    IconButton,
    Input,
    InputGroup,
    Select,
    Stack,
    Text
} from '@chakra-ui/react'

import { FaCircleCheck, FaMoneyBill, FaMoneyBillTrendUp,FaRegCalendarCheck, FaBuildingColumns } from "react-icons/fa6";
import { FaElementor } from 'react-icons/fa';
import { MdClose, MdEdit } from 'react-icons/md';

import {
    APARTMENT,
    ASSET_INTERESTS,
    CONDOMINIUM,
    DUPLEX,
    FIRST_TIME_BUYER,
    ID,
    MANUFACTURED,
    MAX_PRICE,
    MIN_PRICE,
    MOVE_BEFORE_DATE,
    MULTIPLEX,
    PRE_APPROVED,
    SINGLE_FAMILY_HOME,
    TOWNHOME,
    TRAILER,
} from '../../../utils/constants.js';

import { 
    dollarfyPrice,
    prettifyDate,
    prettifyPropertyType
} from '../../../utils/utility.js';

import './IntroPanel.css';

function IntroPanel(props) {

    const navigate = useNavigate();

    const user = props.user;
    const buyer = props.buyer;
    const handleUpdateUserProfile = props.handleUpdateUserProfile;

    const [buyerForm, setBuyerForm] = useState([]);
    const [editProfile, setEditProfile] = useState(false);

    const renderIsFirstTimeBuyer = () => {
        // Display placeholder text if we cannot load the buyer.
        if (!buyer || null == buyer[FIRST_TIME_BUYER]) return "-";
        return (buyer[FIRST_TIME_BUYER] === true ? "Yes" : "No")
    }

    const renderPreapproved = () => {
        // Display placeholder text if we cannot load the buyer.
        if (!buyer || null === buyer[PRE_APPROVED]) return "-";
        return (buyer[PRE_APPROVED] === true ? "Yes" : "No")
    }

    const renderMoveBeforeDate = () => {
        // Display placeholder text if we cannot load the buyer.
        return buyer && buyer[MOVE_BEFORE_DATE] ? prettifyDate(buyer[MOVE_BEFORE_DATE]) : "-";
    }

    const renderMinimumPrice = () => {
        // Display placeholder text if we cannot load the buyer.
        return buyer && buyer[MIN_PRICE] ? "$" + dollarfyPrice(buyer[MIN_PRICE]) : "-";
    }

    const renderMaximumPrice = () => {
       // Display placeholder text if we cannot load the buyer.
       return buyer && buyer[MAX_PRICE] ? "$" + dollarfyPrice(buyer[MAX_PRICE]) : "-";
    }

    const renderAssetInterests = () => {
       if (!buyer || !buyer[ASSET_INTERESTS] || null === buyer[ASSET_INTERESTS]) return "-";

       let assetInterests = buyer[ASSET_INTERESTS];

       let result = [];
       for (var i = 0; i < assetInterests.length; i++) {
           result.push(prettifyPropertyType(assetInterests[i]));
           result.push(<br></br>);
       }
       return result;
    }

    const getAssetInterestsValues = () => {
        let assetInterestsValues = [];
        let assetInterestsCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
        for (let i = 0; i < assetInterestsCheckboxes.length; i++) {
            let chakraCheckboxElement = assetInterestsCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetInterestsValues.push(checkboxInput.value);
            }
        }
        return assetInterestsValues;
    }

    return (
        <Stack width='325px'>
            <Stack gap={3}>
                <HStack justify='space-between'>
                    <Heading fontSize='md' fontWeight='bold'>
                        Intro
                    </Heading>
                    <IconButton
                        className="edit_profile_button"
                        icon={ editProfile ? <MdClose/> : <MdEdit/>}
                        isRound
                        onMouseDown={() => {
                            editProfile ?
                            setEditProfile(false) : 
                            setEditProfile(true)
                        }}
                        size='sm'
                        style={{
                            backgroundColor: "#05b96b",
                            color: "white",
                            cursor: "pointer",
                            transition: "0.3s",
                        }} 
                    />
                </HStack>
                <Stack gap={2}>
                    <Stack gap={1}>
                        <Flex gap={2}>
                            <FaElementor className="intro_panel__icon"/>
                            <Text color='gray' fontSize='xs'>
                                FIRST TIME BUYER
                            </Text>
                        </Flex>
                        {
                            editProfile ?
                            <InputGroup>
                                <Select
                                    name={FIRST_TIME_BUYER}
                                    className="default_input"
                                    onChange={(e) => {
                                        buyerForm[FIRST_TIME_BUYER] = e.target.value === "true";
                                        setBuyerForm(buyerForm);
                                    }}
                                    size='sm'
                                >
                                    <option selected={renderIsFirstTimeBuyer() === "Yes" ? "selected" : ""} value="true">Yes</option>
                                    <option selected={renderIsFirstTimeBuyer() === "No" ? "selected" : ""} value="false">No</option>
                                </Select>
                            </InputGroup> :
                            <Text fontSize='sm'>
                                {renderIsFirstTimeBuyer()}
                            </Text>
                        }
                    </Stack>
                    <Stack gap={1}>
                        <Flex gap={2}>
                            <FaCircleCheck className="intro_panel__icon"/>
                            <Text color='gray' fontSize='xs'>
                                PRE-APPROVED
                            </Text>
                        </Flex>
                        {
                            editProfile ?
                            <InputGroup>
                                <Select
                                    className="default_input"
                                    onChange={(e) => {
                                        buyerForm[PRE_APPROVED] = e.target.value === "true";
                                        setBuyerForm(buyerForm);
                                    }}
                                    name={PRE_APPROVED}
                                    size='sm'
                                >
                                    <option selected={renderPreapproved() === "Yes" ? "selected" : ""} value="true">Yes</option>
                                    <option selected={renderPreapproved() === "No" ? "selected" : ""} value="false">No</option>
                                </Select>
                            </InputGroup> :
                            <Text fontSize='sm'>
                                {renderPreapproved()}
                            </Text>
                        }
                    </Stack>
                    <Stack gap={1}>
                        <Flex gap={2}>
                            <FaRegCalendarCheck className="intro_panel__icon"/>
                            <Text color='gray' fontSize='xs'>
                                MOVE BEFORE DATE
                            </Text>
                        </Flex>
                        {
                            editProfile ?
                            <InputGroup>
                                <Input 
                                    type="date"
                                    className="default_input"
                                    onChange={(e) => {
                                        buyerForm[MOVE_BEFORE_DATE] = new Date(e.target.value);
                                        setBuyerForm(buyerForm);
                                    }}
                                    max="9999-12-31"
                                    size='sm'/>
                            </InputGroup> :
                            <Text fontSize='sm'>
                                {renderMoveBeforeDate()}
                            </Text>
                        }
                    </Stack>
                    <Stack gap={1}>
                        <Flex gap={2}>
                            <FaMoneyBill className="intro_panel__icon"/>
                            <Text color='gray' fontSize='xs'>
                                MINIMUM PRICE
                            </Text>
                        </Flex>
                        {
                            editProfile ?
                            <InputGroup>
                                <Input 
                                    className="default_input"
                                    onChange={(e) => {
                                        buyerForm[MIN_PRICE] = parseInt(e.target.value);
                                        setBuyerForm(buyerForm);
                                    }}
                                    placeholder={renderMinimumPrice()}
                                    size='sm'/>
                            </InputGroup> : 
                            <Text fontSize='sm'>
                                {renderMinimumPrice()}
                            </Text>
                        }
                    </Stack>
                    <Stack gap={1}>
                        <Flex gap={2}>
                            <FaMoneyBillTrendUp className="intro_panel__icon"/>
                            <Text color='gray' fontSize='xs'>
                                MAXIMUM PRICE
                            </Text>
                        </Flex>
                        {
                            editProfile ?
                            <InputGroup>
                                <Input 
                                    className="default_input"
                                    onChange={(e) => {
                                        buyerForm[MAX_PRICE] = parseInt(e.target.value);
                                        setBuyerForm(buyerForm);
                                    }}
                                    placeholder={renderMaximumPrice()}
                                    size='sm'/>
                            </InputGroup> :
                            <Text fontSize='sm'>
                                {renderMaximumPrice()}
                            </Text>
                        }
                    </Stack>
                    <Stack gap={1}>
                        <Flex gap={2}>
                            <FaBuildingColumns className="intro_panel__icon"/>
                            <Text color='gray' fontSize='xs'>
                                LOOKING FOR
                            </Text>
                        </Flex>
                        {
                            editProfile ?
                            <Stack>
                                <Checkbox 
                                    name={SINGLE_FAMILY_HOME}
                                    value="SingleFamilyHome"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("SingleFamilyHome")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Single Family Home
                                </Checkbox>
                                <Checkbox 
                                    name={APARTMENT}
                                    value="Apartment"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Apartment")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Apartment
                                </Checkbox>
                                <Checkbox 
                                    name={TOWNHOME}
                                    value="Townhome"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Townhome")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Townhome
                                </Checkbox>
                                <Checkbox 
                                    name={DUPLEX}
                                    value="Duplex"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Duplex")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Duplex
                                </Checkbox>
                                <Checkbox 
                                    name={MULTIPLEX}
                                    value="Multiplex"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Multiplex")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Multiplex
                                </Checkbox>
                                <Checkbox 
                                    name={CONDOMINIUM}
                                    value="Condominium"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Condominium")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Condominium
                                </Checkbox>
                                <Checkbox 
                                    name={TRAILER}
                                    value="Trailer"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Trailer")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Trailer
                                </Checkbox>
                                <Checkbox 
                                    name={MANUFACTURED}
                                    value="Manufactured"
                                    size='md'
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Manufactured")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Manufactured
                                </Checkbox>
                            </Stack> :
                            <Text fontSize='sm'>
                                {renderAssetInterests()}
                            </Text>
                        }
                    </Stack>
                </Stack>
            </Stack>
            {
                editProfile ?
                <HStack justify='flex-end'>
                    <ButtonGroup>
                        <Button 
                            className="negative_button hover" 
                            onMouseDown={() => setEditProfile(false)}
                            size='sm'
                        >
                            Cancel
                        </Button>
                        <Button 
                            className="positive_button hover" 
                            onMouseDown={() => {
                                if (Object.keys(buyerForm).length === 0) return;
                                if (!buyerForm) return;
                                handleUpdateUserProfile({...buyerForm}, "Saved", "Your changes were saved", () => {
                                setEditProfile(false);
                                setBuyerForm([]);
                            })}}
                            size='sm'
                        >Save</Button>
                    </ButtonGroup>
                </HStack>
                : null
            }
            <HStack>
                <Link href={'/contact-us/' + user[ID]}>
                    <Text color='gray' fontSize='xs'>
                        Contact Us
                    </Text>
                </Link>
                <Text color='gray' fontSize='xs'>
                    •
                </Text>
                <Link href={'/terms-and-agreements'}>
                    <Text color='gray' fontSize='xs'>
                        Terms & Agreements
                    </Text>
                </Link>
                <Text color='gray' fontSize='xs'>
                    •
                </Text>
                <Link href={'/'}>
                    <Text color='gray' fontSize='xs'>
                        Rezied Inc.
                    </Text>
                </Link>
            </HStack>
        </Stack>
    );
}

export default IntroPanel;