import React, { useState } from 'react';
import axios from 'axios';

import {
    ADDRESS_CITY,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    POST,
    PURCHASE_PRICE,
    REPRESENTATION,
    TRANSACTION_DATE,
    DEFAULT_POST_TIMEOUT
} from '../../../../utils/constants.js';

import {
    InputGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    Select,
    ModalFooter,
    Button,
    useToast
} from '@chakra-ui/react';

import { dollarfyPrice } from '../../../../utils/utility.js';
import CurrencyInput from 'react-currency-input-field';

function AddTransactionModal(props) {

    const userId = props.userId;
    const showManualTransactionInputModal = props.showManualTransactionInputModal;
    const setShowManualTransactionInputModal = props.setShowManualTransactionInputModal;
    const triggerFetchTransactions = props.triggerFetchTransactions;

    const [transactionForm, setTransasctionForm] = useState([]);
    const [addTransactionLoading, setAddTransactionLoading] = useState(false);

    const toast = useToast();


    const handleTransactionFormSubmit = () => {

        setAddTransactionLoading(true);

        if (null === transactionForm[REPRESENTATION] || undefined === transactionForm[REPRESENTATION] || 0 === transactionForm[REPRESENTATION].length) {
            transactionForm[REPRESENTATION] = "Buyer";
        }
        // Make our POST request.
        axios({
            method: POST,
            url: '/api/v1/user/agent/transaction/' + userId,
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                agent_id: userId,
                representation: transactionForm[REPRESENTATION],
                address_line_one: transactionForm[ADDRESS_LINE_ONE],
                address_line_two: transactionForm[ADDRESS_LINE_TWO],
                address_city: transactionForm[ADDRESS_CITY],
                address_state: transactionForm[ADDRESS_STATE],
                address_zip_code: transactionForm[ADDRESS_ZIP_CODE],
                purchase_price: parseInt(transactionForm[PURCHASE_PRICE]),
                transaction_date: new Date(transactionForm[TRANSACTION_DATE]),
            }
        }).then(response => {
            // Successful response. Close the modal and load the property. Also
            // reset our form.
            if (response.status === 200) {
                toast({
                    title: 'Added Transaction',
                    description: "Transaction successfully added",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                setShowManualTransactionInputModal(false);
                triggerFetchTransactions();
                setAddTransactionLoading(false);
            }
        }).catch(error => {
            setAddTransactionLoading(false);
        })
    }

    const handleAddTransactionDollarFormChange = (value, name, values) => {
        transactionForm[name] = parseInt(value);
        return;
    }

    const handleAddTransactionFormChange = (event) => {
        transactionForm[event.target.name] = event.target.value;
    }

    return (
        <Modal 
            isOpen={showManualTransactionInputModal}
            onClose={() => setShowManualTransactionInputModal(false)}
            isCentered
            size='sm'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "0px"
                }}>Add a Transaction</ModalHeader>
                <ModalCloseButton
                    onClose={() => setShowManualTransactionInputModal(false)}
                    onMouseDown={() => setShowManualTransactionInputModal(false)}
                />
                <ModalBody style={{
                    paddingTop: "0px"
                }}>
                    <div>
                        <p style={{
                            color: "grey",
                            fontSize: "0.6em",
                            margin: "5px 0px 5px 0px",
                        }}>
                            ADDRESS
                        </p>
                        <Input 
                            size='sm'
                            className="default_input"
                            onChange={handleAddTransactionFormChange} name={ADDRESS_LINE_ONE} placeholder="123 Main St.">
                        </Input>
                    </div>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <div style={{
                                width: "calc(100%)"
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    ADDRESS LINE TWO (Optional)
                                </p>
                                <InputGroup style={{
                                }}>
                                    <Input 
                                        size='sm'
                                        className="default_input"
                                        onChange={handleAddTransactionFormChange} name={ADDRESS_LINE_TWO} placeholder="">
                                    </Input>
                                </InputGroup>
                            </div>
                            
                        </div>
                    </div>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}>
                            <div style={{
                                width: "calc(44% - 5px)"
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    CITY
                                </p>
                                <InputGroup>
                                    <Input 
                                        size='sm'
                                        className="default_input"
                                        onChange={handleAddTransactionFormChange}
                                        name={ADDRESS_CITY}
                                    />
                                </InputGroup>
                            </div>
                            <div style={{
                                width: "calc((23% - 5px)",
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    STATE
                                </p>
                                <InputGroup>
                                    <Select 
                                        size='sm'
                                        className="default_input"
                                        onChange={handleAddTransactionFormChange} name={ADDRESS_STATE} style={{
                                    }}>
                                        <option hidden>-</option>
                                        <option value="AL">AL</option>
                                        <option value="AK">AK</option>
                                        <option value="AR">AR</option>
                                        <option value="AZ">AZ</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="HI">HI</option>
                                        <option value="IA">IA</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="MA">MA</option>
                                        <option value="MD">MD</option>
                                        <option value="ME">ME</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MO">MO</option>
                                        <option value="MS">MS</option>
                                        <option value="MT">MT</option>
                                        <option value="NC">NC</option>
                                        <option value="NE">NE</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NV">NV</option>
                                        <option value="NY">NY</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WI">WI</option>
                                        <option value="WV">WV</option>
                                        <option value="WY">WY</option>
                                    </Select>
                                </InputGroup>
                            </div>
                            <div style={{
                                width: "calc(33% - 5px)",
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    ZIP CODE
                                </p>
                                <InputGroup>
                                    <Input
                                        size='sm'
                                        className="default_input"
                                        onChange={handleAddTransactionFormChange} name={ADDRESS_ZIP_CODE}
                                        placeholder="Zip Code" style={{
                                        }
                                    }/>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <p style={{
                            color: "grey",
                            fontSize: "0.6em",
                            margin: "0px 0px 5px 0px",
                        }}>
                            DATE OF CLOSING
                        </p>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <Input
                                max='9999-12-31'
                                size='sm'
                                type='date'
                                className="default_input"
                                onChange={handleAddTransactionFormChange}
                                name={TRANSACTION_DATE}/>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(60% - 5px)"
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                            }}>
                                PURCHASE PRICE
                            </p>
                            <CurrencyInput
                                name={PURCHASE_PRICE}
                                className="default_input"
                                placeholder={"$"}
                                decimalsLimit={2}
                                onValueChange={(value, name, values) => handleAddTransactionDollarFormChange(value, name, values)}
                                prefix={"$"}
                                style={{
                                    fontSize: "0.9em",
                                    padding: "4px 10px 4px 10px",
                                    width: "100%",
                                }}
                            />
                        </div>
                        <div style={{
                            width: "calc(40% - 5px)"
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                            }}>
                                REPRESENTED
                            </p>
                            <Select
                                size='sm'
                                className="default_input"
                                onChange={handleAddTransactionFormChange} name={REPRESENTATION}
                            >
                                <option value='Buyer'>Buyer</option>
                                <option value='Seller'>Seller</option>
                                <option value='Both'>Both</option>
                            </Select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{
                    margin: "-5px 0px 5px 0px",
                }}>
                    <Button className="negative_grey_button hover" onMouseDown={() => setShowManualTransactionInputModal(false)} mr={3} size='sm'>Cancel</Button>
                    <Button 
                        className="positive_button hover"
                        onMouseDown={() => handleTransactionFormSubmit()}
                        size='sm'>
                        {
                            addTransactionLoading ?
                            <l-dot-pulse
                                size="25"
                                speed="1.25" 
                                color="white" 
                            ></l-dot-pulse> :
                            <>Add Transaction</>
                        }
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default AddTransactionModal;