import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    Input,
    InputLeftElement,
    InputGroup,
    Select,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast
} from '@chakra-ui/react';

import { FaLocationDot } from 'react-icons/fa6';
import {  dollarfyPrice } from '../../../utils/utility.js';

import {
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_CITY,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    SINGLE_FAMILY_HOME,
    APARTMENT,
    CONDOMINIUM,
    TOWNHOME,
    DUPLEX,
    MULTIPLEX,
    TRAILER,
    MANUFACTURED,
    PURCHASE_PRICE,
    PROPERTY_TYPE,
    MONTHLY_HOA_FEE,
    ADDITIONAL_ANNUAL_FEES,
    RENOVATION_COSTS,
    MAINTENANCE_PERCENT,
    PATCH,
    ID,
    DEFAULT_PATCH_TIMEOUT
} from '../../../utils/constants';


function EditHomeModal(props) {

    const userId = props.userId;
    const property = props.property;
    const setSelectedHome = props.setSelectedHome;
    const showEditHomeModal = props.showEditHomeModal;
    const setShowEditHomeModal = props.setShowEditHomeModal;
    const setTriggerFetchProperties = props.setTriggerFetchProperties;

    const [editPropertyForm, setEditPropertyForm] = useState([]);
    const [editPropertyLoading, setEditPropertyLoading] = useState(false);

    // EditPropertyForm error handling.
    const [editPropertyAddressLineOneError, setEditPropertyAddressLineOneError] = useState(null);
    const [editPropertyCityError, setEditPropertyCityError] = useState(null);
    const [editPropertyStateError, setEditPropertyStateError] = useState(null);
    const [editPropertyZipCodeError, setEditPropertyZipCodeError] = useState(null);
    const [editPropertyPurchasePriceError, setEditPropertyPurchasePriceError] = useState(null);
    const [editPropertyMonthlyHOAFeeError, setEditPropertyMonthlyHOAFeeError] = useState(null);
    const [editPropertyAdditionalAnnualFeeError, setEditPropertyAdditionalAnnualFeeError] = useState(null);
    const [editPropertyRenovationCostsError, setEditPropertyRenovationCostsError] = useState(null);
    const [editPropertyMaintenancePercentError, setEditPropertyMaintenancePercentError] = useState(null);

    const toast = useToast();
    
    /*
     * Name: handleEditPropertyFormChange
     * Parameters: event - The calling event from the form.
     * Description: Called every time the Add Property Form input has changed.
     *              It updates the editPropertyForm state.
     */
    const handleEditPropertyFormChange = (event) => {
        if (event.target.name === ADDRESS_LINE_ONE) {
            setEditPropertyAddressLineOneError(null);
        } else if (event.target.name === ADDRESS_CITY) {
            setEditPropertyCityError(null);
        } else if (event.target.name === ADDRESS_STATE) {
            setEditPropertyStateError(null);
        } else if (event.target.name === ADDRESS_ZIP_CODE) {
            setEditPropertyZipCodeError(null);
        }
        editPropertyForm[event.target.name] = event.target.value;
    }

    const handleEditPropertyDollarFormChange = (event) => {
        if (event.target.name === PURCHASE_PRICE) {
            setEditPropertyPurchasePriceError(null);
        } else if (event.target.name === MONTHLY_HOA_FEE) {
            setEditPropertyMonthlyHOAFeeError(null);
        } else if (event.target.name === ADDITIONAL_ANNUAL_FEES) {
            setEditPropertyAdditionalAnnualFeeError(null);
        } else if (event.target.name === RENOVATION_COSTS) {
            setEditPropertyRenovationCostsError(null);
        }
        event.target.value = event.target.value.replace(/[^0-9 \,]/, '');
        event.target.value = event.target.value.replaceAll(/,/g, '');
        // TODO: Does not support currencies that uses commas as decimal point.
        editPropertyForm[event.target.name] = parseFloat(event.target.value.replace(',', ''));
        event.target.value = dollarfyPrice(event.target.value);
        return;
    }

    const handleEditPropertyPercentFloatFormChange = (event) => {
        if (event.target.name === MAINTENANCE_PERCENT) {
            setEditPropertyMaintenancePercentError(null);
        }
        // event.target.value = event.target.value.replace(/[^0-9 \,]/, '');
        let val = event.target.value;
        if (event.target.value.length > 5) {
            event.target.value = val.substring(0, 5);
        }
        editPropertyForm[event.target.name] = event.target.value;
        return;
    }

    /*
     * Name: handleEditPropertyFormSubmit
     * Paramters: None
     * Description: Called on button press. Handles the submit functionality of
     *              adding a property. Makes a POST request to our server.
     */
    const handleEditPropertyFormSubmit = (data) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/buyer/properties/' + userId + '/' + property[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            if (200 === response.status) {
                let updatedProperty = response.data;
                toast({
                    title: 'Success',
                    description: "Successfully edited your home",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                setShowEditHomeModal(false);
                setTriggerFetchProperties();
                setSelectedHome(updatedProperty);
            }
        }).catch(error => {
            toast({
                title: 'Internal server error',
                description: "Unable to edit property. Please try again later. " + error.response.data,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        })
    }
    

    const checkAndAlertEditPropertyFormErrors = () => {

        if (editPropertyForm === null || editPropertyForm === undefined) {
            return true;
        }

        var hasErrors = false;

        if (!editPropertyForm[ADDRESS_LINE_ONE] || editPropertyForm[ADDRESS_LINE_ONE].length === 0) {
            hasErrors = true;
            setEditPropertyAddressLineOneError("Required");
        }

        if (!editPropertyForm[ADDRESS_CITY] || editPropertyForm[ADDRESS_CITY].length === 0) {
            hasErrors = true;
            setEditPropertyCityError("Required");
        }

        if (!editPropertyForm[ADDRESS_STATE] || editPropertyForm[ADDRESS_STATE].length === 0) {
            hasErrors = true;
            setEditPropertyStateError("Required");
        }

        if (!editPropertyForm[ADDRESS_ZIP_CODE] || editPropertyForm[ADDRESS_ZIP_CODE].length === 0) {
            hasErrors = true;
            setEditPropertyZipCodeError("Required");
        }

        if (!editPropertyForm[PURCHASE_PRICE] || 
                editPropertyForm[PURCHASE_PRICE].length === 0 || 
                parseFloat(editPropertyForm[PURCHASE_PRICE] === 0.0)
        ) {
            hasErrors = true;
            setEditPropertyPurchasePriceError("Required");
        } else if (parseFloat(editPropertyForm[PURCHASE_PRICE]) > 1000000000.0) {
            hasErrors = true;
            setEditPropertyPurchasePriceError("Purchase price greater than $1 billion");
        } else if (parseFloat(editPropertyForm[PURCHASE_PRICE]) < 0.0) {
            hasErrors = true;
            setEditPropertyPurchasePriceError("Purchase price negative")
        }

        if (editPropertyForm[MONTHLY_HOA_FEE] === null || editPropertyForm[MONTHLY_HOA_FEE] === undefined) {
            // Do nothing.
        } else if (parseFloat(editPropertyForm[MONTHLY_HOA_FEE]) > 1000000.0) {
            hasErrors = true;
            setEditPropertyMonthlyHOAFeeError("Monthly HOA fee more than $1 million");
        } else if (parseFloat(editPropertyForm[MONTHLY_HOA_FEE]) < 0.0) {
            hasErrors = true;
            setEditPropertyMonthlyHOAFeeError("Monthly HOA fee negative");
        }

        if (editPropertyForm[ADDITIONAL_ANNUAL_FEES] === null || editPropertyForm[ADDITIONAL_ANNUAL_FEES] === undefined) {
            // Do nothing.
        } else if (parseFloat(editPropertyForm[ADDITIONAL_ANNUAL_FEES]) > 100000000.0) {
            hasErrors = true;
            setEditPropertyAdditionalAnnualFeeError("Additional annual fee more than $100 million");
        } else if (parseFloat(editPropertyForm[ADDITIONAL_ANNUAL_FEES]) < 0.0) {
            hasErrors = true;
            setEditPropertyAdditionalAnnualFeeError("Additional annual fee negative");
        }

        if (editPropertyForm[RENOVATION_COSTS] === null || editPropertyForm[RENOVATION_COSTS] === undefined) {
            // Do nothing.
        } else if (parseFloat(editPropertyForm[RENOVATION_COSTS]) > 500000000.0) {
            hasErrors = true;
            setEditPropertyRenovationCostsError("Renovation costs more than $500 million");
        } else if (parseFloat(editPropertyForm[ADDITIONAL_ANNUAL_FEES]) < 0.0) {
            hasErrors = true;
            setEditPropertyRenovationCostsError("Renovation costs negative");
        }

        if (editPropertyForm[MAINTENANCE_PERCENT] === null || editPropertyForm[MAINTENANCE_PERCENT] === undefined) {
            // Do nothing.
        } else if (parseFloat(editPropertyForm[MAINTENANCE_PERCENT]) > 100.0) {
            hasErrors = true;
            setEditPropertyMaintenancePercentError("Maintenance % more than 100%");
        } else if (parseFloat(editPropertyForm[MAINTENANCE_PERCENT]) < 0.0) {
            hasErrors = true;
            setEditPropertyMaintenancePercentError("Maintenance % negative");
        }
        
        return hasErrors;
    }

    return (
        <Modal size='sm' isOpen={showEditHomeModal} onClose={() => setShowEditHomeModal(false)} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Edit Home</ModalHeader>
                <ModalCloseButton onClose={() => setShowEditHomeModal(false)} onMouseDown={() => setShowEditHomeModal(false)}/>
                <ModalBody style={{
                    paddingTop: "0",
                }}>
                    <p style={{
                        color: "grey",
                        fontSize: "0.6em",
                        margin: "0px 0px 5px 0px",
                        padding: "0"
                    }}>
                        ADDRESS
                    </p>
                    <Tooltip
                        bg='red'
                        hasArrow
                        label={editPropertyAddressLineOneError}
                        isOpen={editPropertyAddressLineOneError !== null}
                        placement='top-end'
                    >
                        <InputGroup>
                            <InputLeftElement pointerEvents='none' style={{
                                margin: "-4px 0px 0px 0px"
                            }}>
                                <FaLocationDot color='#cccccc' />
                            </InputLeftElement>
                            <Input 
                                size='sm'
                                id="add_home_modal_address"
                                type='text'
                                name={ADDRESS_LINE_ONE}
                                onChange={handleEditPropertyFormChange}
                                placeholder={property ? property[ADDRESS_LINE_ONE] : 'e.g. 123 Main Street'}
                                className="default_input form_input"
                            />
                        </InputGroup>
                    </Tooltip>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <InputGroup>
                            <Input 
                                size='sm'
                                type='text'
                                name={ADDRESS_LINE_TWO}
                                onChange={handleEditPropertyFormChange}
                                placeholder={property && property[ADDRESS_LINE_TWO] && property[ADDRESS_LINE_TWO].length > 0 ? property[ADDRESS_LINE_TWO] : "Address Line Two (Optional)"}
                                className="default_input form_input"
                            />
                        </InputGroup>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(44% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                CITY
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyCityError}
                                isOpen={editPropertyCityError !== null}
                                placement='top'
                            >
                                <InputGroup>
                                    <Input
                                        size='sm'
                                        type='text'
                                        name={ADDRESS_CITY}
                                        onChange={handleEditPropertyFormChange}
                                        placeholder={property ? property[ADDRESS_CITY] : ""}
                                        className="default_input form_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(24% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                STATE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyStateError}
                                isOpen={editPropertyStateError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <Select className="default_input" name={ADDRESS_STATE} onChange={handleEditPropertyFormChange} size='sm'>
                                        <option hidden>-</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'AL'} value="AL">AL</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'AK'} value="AK">AK</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'AR'} value="AR">AR</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'AZ'} value="AZ">AZ</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'CA'} value="CA">CA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'CO'} value="CO">CO</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'CT'} value="CT">CT</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'DC'} value="DC">DC</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'DE'} value="DE">DE</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'FL'} value="FL">FL</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'GA'} value="GA">GA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'HI'} value="HI">HI</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'IA'} value="IA">IA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'ID'} value="ID">ID</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'IL'} value="IL">IL</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'IN'} value="IN">IN</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'KS'} value="KS">KS</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'KY'} value="KY">KY</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'LA'} value="LA">LA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'MA'} value="MA">MA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'MD'} value="MD">MD</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'ME'} value="ME">ME</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'MI'} value="MI">MI</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'MN'} value="MN">MN</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'MO'} value="MO">MO</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'MS'} value="MS">MS</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'MT'} value="MT">MT</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'NC'} value="NC">NC</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'NE'} value="NE">NE</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'NH'} value="NH">NH</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'NJ'} value="NJ">NJ</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'NM'} value="NM">NM</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'NV'} value="NV">NV</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'NY'} value="NY">NY</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'ND'} value="ND">ND</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'OH'} value="OH">OH</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'OK'} value="OK">OK</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'OR'} value="OR">OR</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'PA'} value="PA">PA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'RI'} value="RI">RI</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'SC'} value="SC">SC</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'SD'} value="SD">SD</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'TN'} value="TN">TN</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'TX'} value="TX">TX</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'UT'} value="UT">UT</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'VT'} value="VT">VT</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'VA'} value="VA">VA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'WA'} value="WA">WA</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'WI'} value="WI">WI</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'WV'} value="WV">WV</option>
                                        <option selected={property && property[ADDRESS_STATE] === 'WY'} value="WY">WY</option>
                                    </Select>
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(32% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                ZIP CODE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyZipCodeError}
                                isOpen={editPropertyZipCodeError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <Input
                                        size='sm'
                                        type='text'
                                        name={ADDRESS_ZIP_CODE}
                                        onChange={handleEditPropertyFormChange}
                                        placeholder={property ? property[ADDRESS_ZIP_CODE] : ""}
                                        className="default_input form_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                HOME PURCHASE PRICE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyPurchasePriceError}
                                isOpen={editPropertyPurchasePriceError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={PURCHASE_PRICE}
                                            onChange={handleEditPropertyDollarFormChange}
                                            placeholder={property ? dollarfyPrice(property[PURCHASE_PRICE]) : ""}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                PROPERTY TYPE
                            </p>
                            <Select 
                                size='sm'
                                name={PROPERTY_TYPE}
                                onChange={handleEditPropertyFormChange}
                                variant='outline'
                                className="default_input"
                                style={{
                                    fontSize: "0.8em",
                                }}
                            >
                                <option selected={property && property[PROPERTY_TYPE] === "SingleFamilyHome"} value={SINGLE_FAMILY_HOME}>Single Family Home</option>
                                <option selected={property && property[PROPERTY_TYPE] === "Apartment"} value={APARTMENT}>Apartment</option>
                                <option selected={property && property[PROPERTY_TYPE] === "Condominium"} value={CONDOMINIUM}>Condominium</option>
                                <option selected={property && property[PROPERTY_TYPE] === "Townhome"} value={TOWNHOME}>Townhome</option>
                                <option selected={property && property[PROPERTY_TYPE] === "Duplex"} value={DUPLEX}>Duplex</option>
                                <option selected={property && property[PROPERTY_TYPE] === "Multiplex"} value={MULTIPLEX}>Multiplex</option>
                                <option selected={property && property[PROPERTY_TYPE] === "Trailer"} value={TRAILER}>Trailer</option>
                                <option selected={property && property[PROPERTY_TYPE] === "Manufactured"} value={MANUFACTURED}>Manufactured</option>
                            </Select>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                MONTHLY HOA FEE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyMonthlyHOAFeeError}
                                isOpen={editPropertyMonthlyHOAFeeError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={MONTHLY_HOA_FEE}
                                            onChange={handleEditPropertyDollarFormChange}
                                            placeholder={property ? dollarfyPrice(property[MONTHLY_HOA_FEE]) : ""}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                OTHER ANNUAL FEES
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyAdditionalAnnualFeeError}
                                isOpen={editPropertyAdditionalAnnualFeeError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={ADDITIONAL_ANNUAL_FEES}
                                            onChange={handleEditPropertyDollarFormChange}
                                            placeholder={property ? dollarfyPrice(property[ADDITIONAL_ANNUAL_FEES]) : ""}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                RENOVATION COSTS
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyRenovationCostsError}
                                isOpen={editPropertyRenovationCostsError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        children='$'
                                        style={{
                                            margin: "-4px 0px 0px 0px",
                                            padding: "0px"
                                        }}
                                    />
                                        <Input
                                            size='sm'
                                            type='text'
                                            name={RENOVATION_COSTS}
                                            onChange={handleEditPropertyDollarFormChange}
                                            placeholder={property ? dollarfyPrice(property[RENOVATION_COSTS]) : ""}
                                            className="default_input form_input"
                                        />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                                padding: "0"
                            }}>
                                MAINTENANCE %
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={editPropertyMaintenancePercentError}
                                isOpen={editPropertyMaintenancePercentError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        color='#cccccc'
                                        fontSize='1.0em'
                                        style={{
                                            margin: "-4px 0px 0px 0px",
                                        }}
                                        children='%'
                                    />
                                    <Input
                                        size='sm'
                                        type='number'
                                        name={MAINTENANCE_PERCENT}
                                        onChange={handleEditPropertyPercentFloatFormChange}
                                        placeholder={property ? property[MAINTENANCE_PERCENT] : ""}
                                        className="default_input form_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onMouseDown={() => setShowEditHomeModal(false)} mr={3} size='sm' className="negative_button hover">Cancel</Button>
                    <Button 
                        className="hover"
                        onMouseDown={() => handleEditPropertyFormSubmit({...editPropertyForm})}
                        style={{
                            backgroundColor: '#05b96b',
                            // boxShadow: "1px 1px 1px 1px #dddddd",
                            borderRadius: "4px",
                            color: 'white'
                        }} size='sm'>
                        {
                            editPropertyLoading ?
                            <l-dot-pulse
                                size="25"
                                speed="1.25" 
                                color="white" 
                            ></l-dot-pulse> :
                            <>Save</>
                        }
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default EditHomeModal;