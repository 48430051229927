import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ID, MESSAGES, AGENT } from '../../../utils/constants.js';

import AgentDashboardParentPage from '../utils/AgentDashboardParentPage.js';
import Messages from '../../common/Messages.js';

import { axiosGetUser, axiosGetAgent } from '../../common/methods/methods.js';
import { checkRequireAgentSignUp } from '../../../utils/utility.js';

import AgentSignUpInformationModal from '../utils/AgentSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import './Page.css';
import '../../Generic.css';

function AgentMessagesDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    const [agent, setAgent] = useState(null);
    const [agentLoading, setAgentLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    const [finishSignUpFormAgent, setFinishSignUpFormAgent] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);


    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (null !== user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            // TODO
        });
    }, [userId]);

    useEffect(() => {
        axiosGetAgent(userId).then(agent => {
            if (agent) {
                if (checkRequireAgentSignUp(agent) === true) {
                    setFinishSignUpFormAgent(agent);
                };
                setAgent(agent);
                setAgentLoading(false);
            }
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            // TODO
            setFinishSignUpFormLoading(false);
        });
    }, [])

    let chatToken = localStorage.getItem("chatToken");

    if (userLoading || agentLoading) return;

    return (
        <AgentDashboardParentPage user={user} tab={MESSAGES} agent={agent}>
            {
                finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormAgent ?
                        <AgentSignUpInformationModal
                            user={finishSignUpFormAgent}
                            setFinishSignUpFormAgent={setFinishSignUpFormAgent}
                        />: null
                    }
                    <Messages user={user} userType={AGENT} pageUserType={AGENT} chatToken={chatToken}/>
                </>
            }
        </AgentDashboardParentPage>
    );
}

export default AgentMessagesDashboardPage;