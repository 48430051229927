import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
    Avatar,
    Button,
    ButtonGroup,
    Center,
    Flex,
    Heading,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    Popover,
    PopoverTrigger,
    Portal,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter
} from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { FaCircleInfo,FaFileLines, FaArrowRightLong, FaMapLocationDot, FaPhone } from "react-icons/fa6";

import {
    COORDINATES,
    DEFAULT_GET_TIMEOUT,
    CONNECTION_REMOVED_BY_BUYER,
    FIRST_NAME,
    FIRM,
    LAST_NAME,
    EMAIL,
    PHONE_NUMBER,
    GET,
    ID,
    PROFILE_PICTURE_URL,
    LICENSE_TYPE,
    ABOUT_DESCRIPTION,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    SALE_EXPERTISE,
    ASSET_SPECIALTY,
    COMMISSION_PERCENTAGE,
    NUMBER_OF_MONTHS,
    RETAINER_FEE_DOLLARS,
    RETAINER_PART_OF_COMMISSION,
    REPRESENTATION,
    IS_EXCLUSIVE,
    SINGLE_FAMILY_HOME,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_CITY,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    PURCHASE_PRICE,
    TRANSACTION_DATE
} from '../../../utils/constants';
import { isValidCoordinates } from '../../../utils/utility.js';

import { dollarfyPrice, prettifyDate } from '../../../utils/utility';

import './MyAgentPage.css';
import MyAgentAreasOfExpertise from './MyAgentAreasOfExpertise.js';
import TransactionsTable from '../../agent/utils/transactions/TransactionsTable.js';

function MyAgentPage(props) {
    const userId = props.userId;
    const workingAgent = props.workingAgent;
    const workingAgentId = workingAgent[ID];

    const handleAddConnection = props.handleAddConnection;
    const setTriggerFetchActiveAgent = props.setTriggerFetchActiveAgent;

    const [workingAgentOffer, setWorkingAgentOffer] = useState(null);
    const [workingAgentOfferLoading, setWorkingAgentOfferLoading] = useState(true);

    const [workingAgentTransactions, setWorkingAgentTransactions] = useState(null);
    const [workingAgentTransactionsLoading, setWorkingAgentTransactionsLoading] = useState(true);

    const [totalTransactionsAmount, setTotalTransactionsAmount] = useState(0.0);

    const [documentsLoading, setDocumentsLoading] = useState(true);

    const [selectedAreaOfExpertise, setSelectedAreaOfExpertise] = useState(null);

    const [showFindMoreAgentsModal, setShowFindMoreAgentsModal] = useState(false);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/offer/' + userId + '?agent_id=' + workingAgentId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            setWorkingAgentOffer(response.data);
            setWorkingAgentOfferLoading(false);
        })
    }, [userId, workingAgentId])

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/active_agent/files/' + userId + '/' + workingAgentId,
        }).then(response => {
            if (200 === response.status) {
                setDocumentsLoading(false);
            }
        }).catch(error => {
            setDocumentsLoading(false);
        })
    }, [userId, workingAgentId]);

    useEffect(() => {

        axios.all([
            axios({
                method: GET,
                url: '/api/v1/user/agent/transactions/' + workingAgentId,
                timeout: DEFAULT_GET_TIMEOUT,
            })
        ]).then(axios.spread((transactionsResponse) => {
            if (200 === transactionsResponse.status) {
                let transactions = transactionsResponse.data;
                let totalTransactionsAmount = 0.0;
                for (let i = 0; i < transactions.length; i++) {
                    totalTransactionsAmount += parseFloat(transactions[i][PURCHASE_PRICE]);
                }
                setTotalTransactionsAmount(totalTransactionsAmount);
                setWorkingAgentTransactions(transactions);
            }
        })).catch(error => {
            // TODO
        });
        setWorkingAgentTransactionsLoading(false);
    }, [workingAgentId]);

    const getPrettifySaleExpertise = (val) => {
        switch (val) {
            case "ShortSale":
                return "Short Sale";
            default:
                return val;
        }
    }

    const renderSaleExpertise = (agent) => {
        if (!agent || !agent[SALE_EXPERTISE] || null === agent[SALE_EXPERTISE]) {
            return "-";
        }
        let saleExpertise = agent[SALE_EXPERTISE];

        let result = [];
        for (var i = 0; i < saleExpertise.length; i++) {
            result.push(getPrettifySaleExpertise(saleExpertise[i]));
            result.push(<br></br>);
        }
        return result;
    }

    const renderAssetSpecialty = (agent) => {
        if (!agent || !agent[ASSET_SPECIALTY] || null === agent[ASSET_SPECIALTY]) {
            return "-";
        }
        let assetSpecialty = agent[ASSET_SPECIALTY];

        let result = [];
        for (var i = 0; i < assetSpecialty.length; i++) {
            result.push(getPrettifyAssetSpecialty(assetSpecialty[i]));
            // result.push(<br></br>);
        }
        return result.join(", ");
    }

    const getPrettifyAssetSpecialty = (val) => {
        switch (val) {
            case SINGLE_FAMILY_HOME:
                return "Single Family Home";
            default:
                return val;
        }
    }

    const renderWorkingStyle = (workingStyle) => {
        if (!workingAgent || !workingAgent[WORKING_STYLE] || null === workingAgent[WORKING_STYLE]) {
            return "-";
        }
        switch(workingAgent[WORKING_STYLE]) {
            case "PartOfTeam":
                return "Part of a team";
            case "SoleLead":
                return "Sole lead";
            default:
                return "-";
        };
    }

    const getAssetSpecialtyValues = () => {
        let assetSpecialtyValues = [];
        let assetSpecialtyCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
        for (let i = 0; i < assetSpecialtyCheckboxes.length; i++) {
            let chakraCheckboxElement = assetSpecialtyCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetSpecialtyValues.push(checkboxInput.value);
            }
        }
        return assetSpecialtyValues;
    }

    const WorkingAgentTransactions = () => {
        
        if (null === workingAgentTransactions) {
            return;
        }

        let transactions = [];

        for (let i = 0; i < workingAgentTransactions.length; i++) {
            let transaction = workingAgentTransactions[i];
            transactions.push(
                <div key={"my_agent_page__" + transaction[ID]} style={{
                    backgroundColor: "#efefef",
                    borderRadius: "4px",
                    display: "flex",
                    padding: "10px 10px 10px 10px",
                }}>
                    <p style={{
                        fontSize: "0.9em",
                        width: "50%",
                    }}>
                        {transaction[ADDRESS_LINE_ONE]} {transaction[ADDRESS_LINE_TWO]} {transaction[ADDRESS_CITY]}, {transaction[ADDRESS_STATE]} {transaction[ADDRESS_ZIP_CODE]}
                    </p>
                    <p style={{
                        fontSize: "0.9em",
                        width: "calc(40%/2)"
                    }}>
                        {prettifyDate(transaction[TRANSACTION_DATE])}
                    </p>
                    <p style={{
                        fontSize: "0.9em",
                        width: "calc(40%/2)"
                    }}>
                        ${dollarfyPrice(transaction[PURCHASE_PRICE])}
                    </p>
                    <p style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "0.9em",
                        width: "calc(10%)"
                    }}>
                        {transaction[REPRESENTATION]}
                    </p>
                </div>
            )
        }

        return (
            <Stack>
                <div style={{
                    display: "flex",
                }}>
                    <Text color='gray' fontSize='2xs'>
                        ADDRESS
                    </Text>
                    <Text color='gray' fontSize='2xs'>

                        CLOSING DATE
                    </Text>
                    <Text color='gray' fontSize='2xs'>
                        SALES PRICE
                    </Text>
                    <Text color='gray' fontSize='2xs'>
                        REPRESENTED
                    </Text>
                </div>
                <div style={{
                    borderRadius: "12px",
                    margin: "10px 0px 0px 0px",
                    width: "100%",
                }}>
                    {transactions}
                </div>
            </Stack>
        );
    }

    const FindMoreAgentsModal = () => {
        return (
            <Modal size='md' isOpen={showFindMoreAgentsModal} onClose={() => {
                setShowFindMoreAgentsModal(false);
            }} isCentered style={{
            }}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader style={{
                        paddingBottom: "0px",
                    }}>
                        Find more Agents
                    </ModalHeader>
                    <ModalCloseButton onClose={() => {
                        setShowFindMoreAgentsModal(false);
                    }} onMouseDown={() => {
                        setShowFindMoreAgentsModal(false);
                    }}/>
                    <ModalBody style={{
                        paddingBottom: "20px",
                        paddingTop: "0px" 
                    }}>
                        <div style={{
                            margin: "10px 0px 20px 0px",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.9em",
                                lineHeight: "17.5px",
                            }}>
                                In order to view other agents, you must first unmatch with your agent. This may result in legal consequences depending on agreements you've signed and they will also be notified. Are you sure you would like to proceed? 
                            </p>
                        </div>
                        <ButtonGroup style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            <Button className="positive_button hover" onMouseDown={() => setShowFindMoreAgentsModal(false)} size='sm'>
                                Take me back to my Agent
                            </Button>
                            <Button className="negative_button hover" size='sm' onMouseDown={() => {
                                handleAddConnection(workingAgent[ID], CONNECTION_REMOVED_BY_BUYER, setTriggerFetchActiveAgent);
                            }}>
                                I'm sure, unmatch me
                            </Button>
                        </ButtonGroup>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }

    if (workingAgentOfferLoading || documentsLoading || workingAgentTransactionsLoading) {
        return;
    }
    
    return (
        <Stack style={{marginTop: "5px"}} gap={6}>
            <Heading fontSize='2xl' fontWeight='bold'>
                My Agent
            </Heading>
            <Stack gap={4}>
                <Flex justifyContent='space-between'>
                    <Flex gap={3}>
                        <Avatar src={workingAgent[PROFILE_PICTURE_URL]} size='md'/>
                        <Stack gap={1}>
                            <Heading fontSize='lg' fontWeight='bold'>
                                {workingAgent[FIRST_NAME]} {workingAgent[LAST_NAME]}
                            </Heading>
                            <Text color='gray' fontSize='sm'>
                                {workingAgent[FIRM] ? workingAgent[FIRM] : "No firm found"}
                            </Text>
                        </Stack>
                    </Flex>
                    <HStack spacing='12px'>
                        <Popover placement='top'>
                            <PopoverTrigger>
                                <Button size='sm' className='positive_button hover' leftIcon={<EmailIcon/>}>
                                    Email
                                </Button>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent bg='#f4f4f4' style={{border: "none"}}>
                                <PopoverArrow bg='#f4f4f4'/>
                                <PopoverCloseButton />
                                <PopoverBody >
                                    <Center>
                                        <Text fontSize='sm' fontWeight='bold'>
                                            {workingAgent ? workingAgent[EMAIL] : "-"}  
                                        </Text>
                                    </Center>
                                </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                        <Popover placement='top'>
                            <PopoverTrigger>
                                <Button size='sm' className='positive_button hover' leftIcon={<PhoneIcon/>}>
                                    Phone Number
                                </Button>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent bg='#f4f4f4' style={{border: "none"}}>
                                <PopoverArrow bg='#f4f4f4'/>
                                <PopoverCloseButton />
                                <PopoverBody >
                                    <Center>
                                        <Text fontSize='sm' fontWeight='bold'>
                                            {workingAgent ? workingAgent[PHONE_NUMBER] : "-"}  
                                        </Text>
                                    </Center>
                                </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    </HStack>
                </Flex>
            </Stack>
            <Stack gap={3}>
                <Stack gap={1}>
                    <Heading fontSize='md' fontWeight='bold'>
                        My Offer
                    </Heading>
                    <Flex align='center'>
                        <Icon color='gray' size='xs' style={{marginTop:'4px'}}>
                            <FaCircleInfo/>
                        </Icon>
                        <Text color='gray' fontSize='xs'>
                            This is the offer you have accepted with your agent
                        </Text>
                    </Flex>
                </Stack>
                <HStack gap={4} justifyContent='space-between' style={{
                    backgroundColor: "#e0f9e8",
                    borderRadius: "6px",
                    padding: "20px 20px 20px 20px"
                }}>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='2xs'>
                            COMMISSION %
                        </Text>
                        <Text fontWeight='bold' fontSize='lg'>
                            {workingAgentOffer && workingAgentOffer[COMMISSION_PERCENTAGE] ? workingAgentOffer[COMMISSION_PERCENTAGE] : "-"}%
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='2xs'>
                            TERM (NO. OF MONTHS)
                        </Text>
                        <Text fontWeight='bold' fontSize='lg'>
                            {workingAgentOffer && workingAgentOffer[NUMBER_OF_MONTHS] ? workingAgentOffer[NUMBER_OF_MONTHS] : "-"} Months
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='2xs'>
                            RETAINER FEE ($)
                        </Text>
                        <Text fontWeight='bold' fontSize='lg'>
                            ${workingAgentOffer && workingAgentOffer[RETAINER_FEE_DOLLARS] ? workingAgentOffer[RETAINER_FEE_DOLLARS] : "-"}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='2xs'>
                            REPRESENTATION
                        </Text>
                        <Text fontWeight='bold' fontSize='lg'>
                            {workingAgentOffer && workingAgentOffer[REPRESENTATION] ? workingAgentOffer[REPRESENTATION] : "-"}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='2xs'>
                            EXCLUSIVITY
                        </Text>
                        <Text fontWeight='bold' fontSize='lg'>
                            {workingAgentOffer && workingAgentOffer[IS_EXCLUSIVE] !== null ? (workingAgentOffer[IS_EXCLUSIVE] === true ? "Exclusive" : "Non-Exclusive") : "-"}
                        </Text>
                    </Stack>
                </HStack>
            </Stack>
            <Stack gap={1}>
                <Heading fontSize='md' fontWeight='bold'>
                    My Documents
                </Heading>
                <Flex align='center'>
                    <Icon color='gray' size='xs' style={{marginTop:'4px'}}>
                        <FaCircleInfo/>
                    </Icon>
                    <Text color='gray' fontSize='xs'>
                        These are official documents related to your agent interaction
                    </Text>
                </Flex>
                <HStack spacing='12px'>
                    <Button variant='outline' className='negative_button hover' size='sm'>
                        Agency Agreement
                    </Button>
                    <Button variant='outline' className='negative_button hover' size='sm'>
                        Purchase & Sale Agreement
                    </Button>
                    <Button variant='outline' className='negative_button hover' size='sm'>
                        Closing Disclosure
                    </Button>
                </HStack>
            </Stack>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Heading fontSize='md' fontWeight='bold'>
                        Agent Personal Information
                    </Heading>
                    <Flex align='center'>
                        <Icon color='gray' size='xs' style={{marginTop:'4px'}}>
                            <FaCircleInfo/>
                        </Icon>
                        <Text color='gray' fontSize='xs'>
                            Information about your agent
                        </Text>
                    </Flex>
                </Stack>
                <HStack spacing='48px'>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='xs'>
                            ABOUT
                        </Text>
                        <Text>
                            {workingAgent && workingAgent[ABOUT_DESCRIPTION] ? workingAgent[ABOUT_DESCRIPTION] : "-"}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='xs'>
                            LICENSE
                        </Text>
                        <Text>
                            {workingAgent && workingAgent[LICENSE_TYPE] ? workingAgent[LICENSE_TYPE] : "-"}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='xs'>
                            REPRESENTATION STYLE
                        </Text>
                        <Text>
                            {workingAgent && workingAgent[REPRESENTATION_STYLE] ? workingAgent[REPRESENTATION_STYLE] : "-"}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='xs'>
                            WORKING STYLE
                        </Text>
                        <Text>
                            {workingAgent && workingAgent[WORKING_STYLE] ? renderWorkingStyle(workingAgent[WORKING_STYLE]) : "-"}
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='xs'>
                            SALE EXPERTISE
                        </Text>
                        <Text>
                            {workingAgent && workingAgent[SALE_EXPERTISE] ? renderSaleExpertise(workingAgent) : "-" }
                        </Text>
                    </Stack>
                    <Stack gap={0}>
                        <Text color='gray' fontSize='xs'>
                            ASSET SPECIALTIES
                        </Text>
                        <Text>
                            {workingAgent && workingAgent[ASSET_SPECIALTY] ? renderAssetSpecialty(workingAgent) : "-"}
                        </Text>
                    </Stack>
                </HStack>
            </Stack>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Heading fontSize='md' fontWeight='bold'>
                        Agent Transactions
                    </Heading>
                    <Flex align='center'>
                        <Icon color='gray' size='xs' style={{marginTop:'4px'}}>
                            <FaCircleInfo/>
                        </Icon>
                        <Text color='gray' fontSize='xs'>
                            Historical transactions your agent has worked on before
                        </Text>
                    </Flex>
                    <TransactionsTable transactions={workingAgentTransactions} readOnlyView={true}/>
                </Stack>
            </Stack>
            <Stack gap={2}>
                <Stack gap={1}>
                    <Heading fontSize='md' fontWeight='bold'>
                        Agent Areas of Expertise
                    </Heading>
                    <Flex align='center'>
                        <Icon color='gray' size='xs' style={{marginTop:'4px'}}>
                            <FaCircleInfo/>
                        </Icon>
                        <Text color='gray' fontSize='xs'>
                            Areas your agent has marked themselves as being an expert/familiar with
                        </Text>
                    </Flex>
                    <Stack>
                        <MyAgentAreasOfExpertise workingAgent={workingAgent} setSelectedAreaOfExpertiseCallback={(area) => setSelectedAreaOfExpertise(area)}/>
                        <div id="map-container" style={{ 
                            display: selectedAreaOfExpertise !== null && isValidCoordinates(selectedAreaOfExpertise[COORDINATES]) ? "block" : "none",
                            borderRadius: "12px",
                            height: 'calc(100vh - 250px - 170px)',
                            maxHeight: "400px",
                            width: "100%",
                        }}>
                            <div id="map" style={{ 
                                borderRadius: "8px",
                                maxHeight: "400px",
                                height: 'inherit',
                                maxHeight: 'inherit',
                                width: '100%'
                            }}/>
                        </div> 
                        <div style={{
                            display: selectedAreaOfExpertise !== null && isValidCoordinates(selectedAreaOfExpertise[COORDINATES]) ? "none" : "block",
                            backgroundColor: "#f6f6f6",
                            borderRadius: "12px",
                            height: '250px',
                            maxHeight: "400px",
                            position: "relative",
                            width: "100%",
                        }}>
                            <Center id="map">
                                <FaMapLocationDot style={{
                                    color: "grey",
                                    height: "25px",
                                    top: "calc(50% - 25px)",
                                    position: "absolute",
                                    width: "25px",
                                }}/>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.8em",
                                    margin: "5px 0px 0px 0px",
                                    position: "absolute",
                                    top: "50%",
                                }}>
                                    Invalid address
                                </p>
                            </Center>
                        </div>
                    </Stack>
                </Stack>
            </Stack>
            <Stack style={{marginTop: "-20px"}}>
                <Flex justifyContent='flex-end' align='center' className="hover" style={{cursor: "pointer"}} gap={1}>
                    <FindMoreAgentsModal/>
                    <Text onMouseDown={() => setShowFindMoreAgentsModal(true)} fontSize='xs'>
                        Find more Agents
                    </Text>
                    <Icon size='xs' style={{marginTop: "7px"}}>
                        <FaArrowRightLong/>
                    </Icon>
                </Flex>
            </Stack>
        </Stack>
    );
}

export default MyAgentPage;