import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { 
    Avatar,
    Button,
    Image,
    Heading,
    useToast,
    Stack,
    HStack,
    Flex,
    Icon,
    Text,
    Divider
} from '@chakra-ui/react';

import { MdEdit } from 'react-icons/md';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { FaHandshake, FaLocationDot, FaReceipt, FaUser } from 'react-icons/fa6';

import { checkRequireAgentSignUp, loadMime } from '../../../utils/utility.js';

import {
    ASSET_SPECIALTY,
    GET,
    EMAIL,
    FIRST_NAME,    
    FIRM,
    LAST_NAME,
    LICENSE_TYPE,
    ABOUT_DESCRIPTION,
    ID,
    PHONE_NUMBER,
    PROFILE,
    PROFILE_PICTURE_URL,
    PATCH,
    REPRESENTATION_STYLE,
    PUT,
    SINGLE_FAMILY_HOME,
    DEFAULT_PUT_TIMEOUT,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT,
    WORKING_STYLE,
    SALE_EXPERTISE
} from '../../../utils/constants.js';

import AgentDashboardParentPage from '../utils/AgentDashboardParentPage.js';
import AboutPanel from './AboutPanel.js';

import MainPanel from '../../common/MainPanel.js';

import { axiosGetAgent } from '../../common/methods/methods.js';
import { chaoticOrbit } from 'ldrs'
import AgentSignUpInformationModal from '../utils/AgentSignUpInformationModal.js';
import TransactionsTable from '../utils/transactions/TransactionsTable.js';
import AddTransactionModal from '../utils/transactions/AddTransactionModal.js';
import EditTransactionModal from '../utils/transactions/EditTransactionModal.js';
import Loader from '../../common/Settings/Loader.js';
import NoObjectsDisplay from '../../common/NoObjectsDisplay.js';
import EditProfileModal from './EditProfileModal.js';

import './Page.css';
import '../../Generic.css';

function AgentProfileDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [showEditProfilePictureButton, setShowEditProfilePictureButton] = useState(false);

    const toast = useToast();
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [agent, setAgent] = useState(null);
    const [agentLoading, setAgentLoading] = useState(true);

    const [profilePictureUploadLoading, setProfilePictureUploadLoading] = useState(false);
    const [triggerFetchAgentProfile, setTriggerFetchAgentProfile] = useState(false);

    const [triggerFetchUser, setTriggerFetchUser] = useState(false);
    const [userLoading, setUserLoading] = useState(true);

    const [finishSignUpFormAgent, setFinishSignUpFormAgent] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const [transactions, setTransactions] = useState(null);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const [allTransactions, setAllTransactions] = useState(null);
    const [triggerFetchTransactions, setTriggerFetchTransactions] = useState(false);
    const [transactionToEdit, setTransactionToEdit] = useState(null);
    const [showManualTransactionInputModal, setShowManualTransactionInputModal] = useState(false);

    const urlParams = useParams();
    const userId = urlParams[ID];

    chaoticOrbit.register()

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let user = response.data;
            setUser(user);
            setUserLoading(false);
            setTimeout(function() {
                setProfilePictureUploadLoading(false);
            }, 500);
        }).catch(error => {
            // TODO
        });
    }, [userId, triggerFetchUser]);

    useEffect(() => {
        axiosGetAgent(userId).then(agent => {
            if (agent) {
                if (checkRequireAgentSignUp(agent) === true) {
                    setFinishSignUpFormAgent(agent);
                };
                setAgent(agent);
                setAgentLoading(false);
            }
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId, triggerFetchAgentProfile]);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/transactions/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setTransactions(response.data);
                setAllTransactions(response.data);
                setTransactionsLoading(false);
            }
        }).catch(error => {
            setTransactionsLoading(false);
            // TODO: Handle our error codes.
        })
    }, [userId, triggerFetchTransactions]);

    const handleUpdateUserProfilePicture = (key) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: {
                "profile_picture_url_key": key
            },
        }).then(response => {
            setTriggerFetchUser(!triggerFetchUser);
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleUpdateUserProfile = (data, toastTitle, toastDescription, callback) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/agent/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            setTriggerFetchAgentProfile(!triggerFetchAgentProfile);
            toast({
                title: toastTitle,
                description: toastDescription,
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
            callback();
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleProfilePictureInputChange = (e) => {
        
        setProfilePictureUploadLoading(true);
        let file = e.target.files[0];
        // Set our display as the new picture.
        loadMime(file, function(fileMimeType) {
            // Handle saving the file to our server in the background.
            axios({
                method: GET,
                url: '/api/v1/user/profile/picture/uploadURL?id=' + userId + "&mime_type=" + fileMimeType,
                timeout: DEFAULT_GET_TIMEOUT,
            }).then(response => {
                let uploadProfilePicture = response.data;
                let uploadSignedURL = uploadProfilePicture["upload_signed_url"];
                let storageKey = uploadProfilePicture["storage_key"];
                axios({
                    method: PUT,
                    url: uploadSignedURL,
                    timeout: DEFAULT_PUT_TIMEOUT,
                    data: file,
                    headers: { 'Content-Type': 'application/octet-stream' }
                }).then(response=> {
                    if (200 === response.status) {
                        handleUpdateUserProfilePicture(storageKey);
                    }
                }).catch(error=> {
                    // TODO
                });
                e.target.value = '';
            }).catch(error => {
                // TODO
            });
        }); 
    }

    const prettifyLicenseType = (val) => {
        switch (val) {
            case "ManagingBroker":
                return "Managing Broker";
            case "AssociateBroker":
                return "Associate Broker";
            default:
        }
        return val;
    }

    const renderProfileField = (field) => {
        if (!agent || null === agent[field] || undefined === agent[field] || "" === agent[field]) {
            return "-";
        }
        if (field === LICENSE_TYPE) {
            return prettifyLicenseType(agent[field]);
        }
        return agent[field];
    }

    const renderWorkingStyle = (workingStyle) => {
        if (!agent || !agent[WORKING_STYLE] || null === agent[WORKING_STYLE]) {
            return "-";
        }
        switch(agent[WORKING_STYLE]) {
            case "PartOfTeam":
                return "Part of a team";
            case "SoleLead":
                return "Sole lead";
            default:
                return "-";
        };
    }

    const renderSaleExpertise = () => {
        if (!agent || !agent[SALE_EXPERTISE] || null === agent[SALE_EXPERTISE]) {
            return "-";
        }
        let saleExpertise = agent[SALE_EXPERTISE];

        let result = '';
        for (var i = 0; i < saleExpertise.length; i++) {
            if (result === '') {
                result = getPrettifySaleExpertise(saleExpertise[i]);
            } else {
                result = result + ', ' + getPrettifySaleExpertise(saleExpertise[i]);
            }
        }
        return result;
    }

    const getPrettifySaleExpertise = (val) => {
        switch (val) {
            case "ShortSale":
                return "Short Sale";
            default:
                return val;
        }
    }

    const renderAssetSpecialty = () => {
        if (!agent || !agent[ASSET_SPECIALTY] || null === agent[ASSET_SPECIALTY]) {
            return "-";
        }
        let assetSpecialty = agent[ASSET_SPECIALTY];

        let result = '';
        for (var i = 0; i < assetSpecialty.length; i++) {
            if (result === '') {
                result = getPrettifyAssetSpecialty(assetSpecialty[i]);
            } else {
                result = result + ', ' + getPrettifyAssetSpecialty(assetSpecialty[i]);
            }
        }
        return (
            <Flex>
                {result}
            </Flex>
        );
    }

    const getPrettifyAssetSpecialty = (val) => {
        switch (val) {
            case SINGLE_FAMILY_HOME:
                return "Single Family Home";
            default:
                return val;
        }
    }

    if (userLoading || agentLoading) return;

    return (
        <AgentDashboardParentPage user={user} tab={PROFILE} agent={agent}>
            {
                finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormAgent ?
                        <AgentSignUpInformationModal
                            user={finishSignUpFormAgent}
                            setFinishSignUpFormAgent={setFinishSignUpFormAgent}
                        /> : null
                    }
                    <MainPanel>
                        <Stack gap={8} style={{marginTop: "25px"}}>
                            <Stack gap={8}>
                                <Flex gap={6} justifyContent='space-between'>
                                    <Flex gap={6} style={{position: "relative"}}>
                                        <div 
                                            onMouseEnter={() => {
                                                setShowEditProfilePictureButton(true);
                                            }}
                                            onMouseLeave={() => {
                                                setShowEditProfilePictureButton(false);
                                            }}
                                            style={{
                                                borderRadius: "50%",
                                                position: "relative",
                                                pointerEvents: profilePictureUploadLoading ? "none" : "",
                                                textAlign: "center",
                                                width: "125px",
                                            }}
                                        >
                                            <div style={{
                                                height: "125px",
                                                opacity: profilePictureUploadLoading ? "0.3" : "1.0",
                                                width: "125px",
                                            }}>
                                                {
                                                    profilePictureUploadLoading ?
                                                    // Default values shown
                                                    <l-chaotic-orbit
                                                        size="35"
                                                        speed="1.5" 
                                                        color="black" 
                                                        style={{
                                                            position: "absolute",
                                                            left: "calc(50% - 17.5px)",
                                                            top: "calc(50% - 17.5px)",
                                                        }}
                                                    ></l-chaotic-orbit> : null
                                                }
                                                <div style={{
                                                    backgroundColor: "#f6f6f6",
                                                    borderRadius: "50%",
                                                    height: "125px",
                                                    width: "125px",
                                                }}>
                                                    {
                                                        user && user[PROFILE_PICTURE_URL] ?
                                                        <Image
                                                            borderRadius='full'
                                                            src={user[PROFILE_PICTURE_URL]}
                                                            alt={user[FIRST_NAME] + " " + user[LAST_NAME]}
                                                            style={{
                                                                objectFit: "cover",
                                                                overflow: "hidden",
                                                                height: "125px",
                                                                width: "100%",
                                                            }}
                                                        /> :
                                                        <Avatar 
                                                            boxSize='125px'    
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            {
                                                showEditProfilePictureButton ?
                                                <div style={{
                                                    position: "relative",
                                                    width: "100%",
                                                }}>
                                                    <label htmlFor="upload-profile-picture">
                                                        <MdEdit
                                                            component="label"
                                                            style={{
                                                                backgroundColor: "#dddddd",
                                                                borderRadius: "25px",
                                                                color: "black",
                                                                cursor: "pointer",
                                                                margin: "-40px 0px 0px 0px",
                                                                padding: "5px",
                                                                height: "30px",
                                                                width: "30px",
                                                                position: "absolute",
                                                                right: "10px",
                                                            }}
                                                        />
                                                        
                                                    </label>

                                                </div> : null
                                            }
                                            <input 
                                                accept="image/png,image/jpg,image/jpeg"
                                                id="upload-profile-picture"
                                                onChange={handleProfilePictureInputChange}
                                                style={{
                                                    display: "none",
                                                }}
                                                type="file"
                                            ></input>
                                        </div>
                                        <Stack gap={2} style={{bottom: "10px", position: "absolute", left: "145px"}}>
                                            <Heading fontWeight='bold' fontSize='2xl'>
                                                {user[FIRST_NAME]} {user[LAST_NAME]}
                                            </Heading>
                                            <Stack gap={0}>
                                                <Flex gap={2}>
                                                    <Icon boxSize={4} color='gray' style={{marginTop: "4px"}}>
                                                        <EmailIcon/>
                                                    </Icon>
                                                    <Text fontSize='md' color='gray'>
                                                        {user[EMAIL]}
                                                    </Text>
                                                </Flex>
                                                <Flex gap={2}>
                                                    <Icon boxSize={4} color='gray' style={{marginTop: "4px"}}>
                                                        <PhoneIcon/>
                                                    </Icon>
                                                    <Text fontSize='md' color='gray'>
                                                        {user[PHONE_NUMBER]}
                                                    </Text>
                                                </Flex>
                                            </Stack>
                                        </Stack>
                                    </Flex>
                                </Flex>
                            </Stack>
                            <Stack gap={12}>
                                <Stack>
                                    <Heading fontWeight='bold' fontSize='lg'>
                                        <Flex gap={1} justifyContent='space-between'>
                                            <Flex gap={1}>
                                                <Icon boxSize='25px'>
                                                    <FaUser/>
                                                </Icon>
                                                <Text fontWeight='bold'>
                                                    Profile
                                                </Text>
                                            </Flex>
                                            <EditProfileModal 
                                                user={user}
                                                agent={agent}
                                                handleUpdateUserProfile={handleUpdateUserProfile}
                                                showEditProfileModal={showEditProfileModal} 
                                                setShowEditProfileModal={setShowEditProfileModal}
                                            />
                                            <Button className='positive_button hover' size='sm' onMouseDown={() => setShowEditProfileModal(true)}>
                                                Edit Profile
                                            </Button>
                                        </Flex>
                                    </Heading>
                                    <Stack gap={4}>
                                        <Stack gap={0}>
                                            <Text color='gray' fontSize='xs'>
                                                ABOUT
                                            </Text>
                                            <Text>
                                                {renderProfileField(ABOUT_DESCRIPTION)}
                                            </Text>
                                        </Stack>
                                        <HStack spacing='42px'>
                                            <Stack gap={0}>
                                                <Text color='gray' fontSize='xs'>
                                                    FIRM
                                                </Text>
                                                <Text>
                                                    {renderProfileField(FIRM)}
                                                </Text>
                                            </Stack>
                                            <Stack gap={0}>
                                                <Text color='gray' fontSize='xs'>
                                                    LICENSE
                                                </Text>
                                                <Text>
                                                    {renderProfileField(LICENSE_TYPE)}
                                                </Text>
                                            </Stack>
                                            <Stack gap={0}>
                                                <Text color='gray' fontSize='xs'>
                                                    REPRESENTATION STYLE
                                                </Text>
                                                <Text>
                                                    {renderProfileField(REPRESENTATION_STYLE)}
                                                </Text>
                                            </Stack>
                                            <Stack gap={0}>
                                                <Text color='gray' fontSize='xs'>
                                                    WORKING STYLE
                                                </Text>
                                                <Text>
                                                    {renderWorkingStyle()}
                                                </Text>
                                            </Stack>
                                            <Stack gap={0}>
                                                <Text color='gray' fontSize='xs'>
                                                    SALE EXPERTISE
                                                </Text>
                                                <Text>
                                                    {renderSaleExpertise()}
                                                </Text>
                                            </Stack>
                                            <Stack gap={0}>
                                                <Text color='gray' fontSize='xs'>
                                                    ASSET SPECIALTIES
                                                </Text>
                                                <Text>
                                                    {renderAssetSpecialty()}
                                                </Text>
                                            </Stack>
                                        </HStack>
                                    </Stack>
                                </Stack>
                                <Stack>
                                    <Heading fontWeight='bold' fontSize='lg'>
                                        <Flex gap={1} justifyContent='space-between'>
                                            <Stack gap={1}>
                                                <Flex gap={1}>
                                                    <Icon boxSize='25px' style={{marginTop: "2px"}}>
                                                        <FaReceipt/>
                                                    </Icon>
                                                    <Text fontWeight='bold'>
                                                        Transactions
                                                    </Text>
                                                </Flex>
                                                <Text color='gray' fontSize='xs'>
                                                    Historical transactions you've completed
                                                </Text>
                                            </Stack>
                                            <Button className='positive_button hover' size='sm' onMouseDown={() => setShowManualTransactionInputModal(true)}>
                                                Add Transaction
                                            </Button>
                                        </Flex>
                                    </Heading>
                                    {
                                        showManualTransactionInputModal ?
                                        <AddTransactionModal
                                            userId={userId}
                                            showManualTransactionInputModal={showManualTransactionInputModal}
                                            setShowManualTransactionInputModal={(toShow) => setShowManualTransactionInputModal(toShow)}
                                            triggerFetchTransactions={() => setTriggerFetchTransactions(!triggerFetchTransactions)}
                                        /> : null
                                    }
                                    {
                                        transactionToEdit !== null ?
                                        <EditTransactionModal 
                                            userId={userId}
                                            closeEditTransactionModel={() => setTransactionToEdit(null)}
                                            triggerFetchTransactions={() => setTriggerFetchTransactions(!triggerFetchTransactions)}
                                            transaction={transactionToEdit}
                                        />
                                        : null
                                    }
                                    {
                                        transactions && transactions.length > 0 ? 
                                        <TransactionsTable 
                                            transactions={transactions}
                                            setTransaction={(transaction) => setTransactionToEdit(transaction)}
                                            readOnlyView={false}
                                        /> :
                                        <NoObjectsDisplay
                                            icon={FaHandshake}
                                            titleText={"No Transactions Found"}
                                            subtitleText={"No transactions were found. Add transactions to increase your conversion rate."}
                                            buttonText={"Add Transaction"}
                                            buttonCallback={() => setShowManualTransactionInputModal(true)}
                                        />
                                    }
                                </Stack>
                                <Stack gap={0}>
                                    <Heading fontWeight='bold' fontSize='lg'>
                                        <Flex gap={1}>
                                            <FaLocationDot/>
                                            <Text fontWeight='bold'>
                                                Areas of Expertise
                                            </Text>
                                        </Flex>
                                    </Heading>
                                    <Text color='gray' fontSize='xs'>
                                        Areas you've marked as being an expert and familiar in
                                    </Text>
                                    <AboutPanel user={user} agent={agent}/>
                                </Stack>
                            </Stack>
                        </Stack>
                    </MainPanel>
                </>
            }
        </AgentDashboardParentPage>
    );
}

export default AgentProfileDashboardPage;