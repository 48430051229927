import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    Checkbox,
    InputGroup,
    Input,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalOverlay,
    ModalBody,
    ModalFooter,
    Select,
    Tooltip
} from '@chakra-ui/react';

import {
    DEFAULT_POST_TIMEOUT,
    POST,
    LOAN_TYPE,
    PREAPPROVAL_AMOUNT,
    FULLY_INDEXED_INTEREST_RATE,
    UPFRONT_MIP,
    DOWN_PAYMENT_PERCENT,
    INTEREST_RATE,
    APR,
    POINTS,
    IS_DEFAULT,
    THIRTY_YEAR_FIXED,
    FIFTEEN_YEAR_FIXED,
    FIVE_ONE_ARM,
    SEVEN_ONE_ARM,
    TEN_ONE_ARM,
    THIRTY_YEAR_FHA,
} from '../../../utils/constants.js';

import { dollarfyPrice } from '../../../utils/utility.js';

function AddLoanModal(props) {

    const userId = props.userId;
    const showAddLoanModal = props.showAddLoanModal;
    const closeAddLoanModal = props.closeAddLoanModal;
    const triggerFetchLoans = props.triggerFetchLoans;

    const [addLoanForm, setAddLoanForm] = useState([]);

    const [loanType, setLoanType] = useState(null);

    const [preapprovalAmountError, setPreapprovalAmountError] = useState(null);
    const [downPaymentPercentError, setDownPaymentPercentError] = useState(null);
    const [interestRatePercentError, setInterestRatePercentError] = useState(null);
    const [aprError, setAprError] = useState(null);
    const [pointsError, setPointsError] = useState(null);

    const handleAddLoanFormChange = (event) => {
        addLoanForm[event.target.name] = event.target.value;
        return;
    }

    const handleAddLoanPreapprovalField  = (event) => {
        setPreapprovalAmountError(null);
        event.target.value = event.target.value.replace(/[^0-9 \,]/, '');
        event.target.value = dollarfyPrice(event.target.value.replace(/,/g, ''));
        addLoanForm[event.target.name] = event.target.value;
        return;
    }

    const handleAddLoanPercentFormChange = (event) => {
        if (event.target.name === DOWN_PAYMENT_PERCENT) {
            setDownPaymentPercentError(null);
        } else if (event.target.name === INTEREST_RATE) {
            setInterestRatePercentError(null);
        } else if (event.target.name === APR) {
            setAprError(null);
        } else if (event.target.name === POINTS) {
            setPointsError(null);
        }

        event.target.value = event.target.value.replace(/[^0-9.]/g, '');
        if(event.target.value.split('.').length > 2) 
             event.target.value = event.target.value.replace(/\.+$/, '');
            
        if (parseFloat(event.target.value) > 100.0) {
            event.target.value = 100.0;
        }
        addLoanForm[event.target.name] = event.target.value;
        return;
    }

    const checkAndAlertFormErrors = () => {

        if (addLoanForm === null || addLoanForm === undefined) {
            return true;
        }

        var hasErrors = false;

        // Check pre-approval amount errors.
        if (!addLoanForm[PREAPPROVAL_AMOUNT] || addLoanForm[PREAPPROVAL_AMOUNT].length === 0) {
            hasErrors = true;
            setPreapprovalAmountError("Required");
        } else if (parseFloat(addLoanForm[PREAPPROVAL_AMOUNT]) > 999999999) {
            hasErrors = true;
            setPreapprovalAmountError("Preapproval amount invalid - too high");
        } else if (parseFloat(addLoanForm[PREAPPROVAL_AMOUNT]) < 0) {
            hasErrors = true;
            setPreapprovalAmountError("Preapproval cannot be negative");
        }

        // Check down payment percent.
        if (!addLoanForm[DOWN_PAYMENT_PERCENT] || addLoanForm[DOWN_PAYMENT_PERCENT].length === 0) {
            hasErrors = true;
            setDownPaymentPercentError("Required");
        } else if (parseFloat(addLoanForm[DOWN_PAYMENT_PERCENT]) >= 100) {
            hasErrors = true;
            setDownPaymentPercentError("Can't be greater than 100");
        } else if (parseFloat(addLoanForm[DOWN_PAYMENT_PERCENT]) <= 0) {
             hasErrors = true;
             setDownPaymentPercentError("Must be non-zero")
        }

        // Check interest rate percent.
        if (!addLoanForm[INTEREST_RATE] || addLoanForm[INTEREST_RATE].length === 0) {
            hasErrors = true;
            setInterestRatePercentError("Required");
        } else if (parseFloat(addLoanForm[INTEREST_RATE]) >= 100) {
            hasErrors = true;
            setInterestRatePercentError("Can't be greater than 100");
        } else if (parseFloat(addLoanForm[INTEREST_RATE]) <= 0) {
            hasErrors = true;
            setInterestRatePercentError("Must be non-zero")
        }

        // Check interest rate percent.
        if (!addLoanForm[APR] || addLoanForm[APR].length === 0) {
            hasErrors = true;
            setAprError("Required");
        }else if (parseFloat(addLoanForm[APR]) >= 100) {
            hasErrors = true;
            setAprError("Can't be greater than 100");
        } else if (parseFloat(addLoanForm[APR]) <= 0) {
            hasErrors = true;
            setAprError("Must be non-zero")
        }

        // Check interest rate percent.
        if (!addLoanForm[POINTS] || addLoanForm[POINTS].length === 0) {
            hasErrors = true;
            setPointsError("Required");
        } else if (parseFloat(addLoanForm[POINTS]) >= 100.0) {
            hasErrors = true;
            setPointsError("Can't be greater than 100");
        } else if (parseFloat(addLoanForm[POINTS]) <= 0) {
            hasErrors = true;
            setPointsError("Must be non-zero")
        }

        return hasErrors;
    }

    /*
     * Name: handleAddLoanFormSubmit
     * Description: Handles when the user adds a loan using our addLoanForm.
     * Returns: None
     */
    const handleAddLoanFormSubmit = () => {

        if (checkAndAlertFormErrors()) {
            return;
        }

        // Since we have a default of THIRTY_YEAR_FIXED in our Select element,
        // handleAddLoanFormChange never gets triggered for that element if the
        // value is not changed. Set that default here.
        if (addLoanForm[LOAN_TYPE] == null){
            addLoanForm[LOAN_TYPE] = THIRTY_YEAR_FIXED;
        }

        console.log(addLoanForm);

        axios({
            method: POST,
            url: '/api/v1/user/buyer/loans/' + userId,
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                loan_type: addLoanForm[LOAN_TYPE],
                down_payment_percent: parseFloat(addLoanForm[DOWN_PAYMENT_PERCENT]),
                interest_rate: parseFloat(addLoanForm[INTEREST_RATE]),
                apr: parseFloat(addLoanForm[APR]),
                points: parseFloat(addLoanForm[POINTS]),
                fully_indexed_interest_rate: parseFloat(addLoanForm[FULLY_INDEXED_INTEREST_RATE]),
                upfront_mip: addLoanForm[UPFRONT_MIP] === true,
                is_default: addLoanForm[IS_DEFAULT] === true
            }
        }).then(response => {
            // Successful response. Close the modal and load the loan.
            if (response.status === 200) {
                closeAddLoanModal();
                triggerFetchLoans();
                setAddLoanForm([]);
            }
        }).catch(error => {
            // TODO
        })
    }

    return (
        <Modal 
            size='sm' 
            isOpen={showAddLoanModal} 
            onClose={closeAddLoanModal} 
            isCentered
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "10px",
                }}>Add a Loan</ModalHeader>
                <ModalCloseButton onClose={closeAddLoanModal} onMouseDown={closeAddLoanModal}/>
                <ModalBody style={{
                    paddingTop: "0",
                }}>
                    <p className="loan_page__add_loan_modal__label">
                        LOAN TYPE
                    </p>
                    <Select
                        className="default_input"
                        name={LOAN_TYPE}
                        onChange={(e) => {
                            setLoanType(e.target.value);
                            handleAddLoanFormChange(e);
                        }}
                        variant='outline'
                        size='md'
                    >
                        <option value={THIRTY_YEAR_FIXED}>30 Year Fixed</option>
                        <option value={FIFTEEN_YEAR_FIXED}>15 Year Fixed</option>
                        <option value={FIVE_ONE_ARM}>5/1 ARM</option>
                        <option value={SEVEN_ONE_ARM}>7/1 ARM</option>
                        <option value={TEN_ONE_ARM}>10/1 ARM</option>
                        <option value={THIRTY_YEAR_FHA}>30 Year FHA</option>
                    </Select>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <p className="loan_page__add_loan_modal__label">
                            HOW MUCH ARE YOU PRE-APPROVED FOR?
                        </p>
                        <Tooltip
                            bg='red'
                            hasArrow
                            label={preapprovalAmountError}
                            isOpen={preapprovalAmountError !== null}
                            placement='top-end'
                        >
                            <InputGroup>
                                <Input 
                                    size='md'
                                    type='text'
                                    placeholder='Enter a dollar amount ($)'
                                    name={PREAPPROVAL_AMOUNT}
                                    onChange={handleAddLoanPreapprovalField}
                                    className="default_input"
                                />
                            </InputGroup>
                        </Tooltip>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_page__add_loan_modal__label">
                                DOWN PAYMENT PERCENT
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={downPaymentPercentError}
                                isOpen={downPaymentPercentError !== null}
                                placement='top-start'
                            >
                                <InputGroup>
                                    <Input
                                        size='md'
                                        type='text'
                                        placeholder='e.g. 20%'
                                        name={DOWN_PAYMENT_PERCENT}
                                        onChange={handleAddLoanPercentFormChange}
                                        className="default_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_page__add_loan_modal__label">
                                INTEREST RATE
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={interestRatePercentError}
                                isOpen={interestRatePercentError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <Input
                                        size='md'
                                        type='text'
                                        placeholder='e.g. 3.5%'
                                        name={INTEREST_RATE}
                                        onChange={handleAddLoanPercentFormChange}
                                        className="default_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_page__add_loan_modal__label">
                                APR
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={aprError}
                                isOpen={aprError !== null}
                                placement='top-start'
                            >
                                <InputGroup>
                                    <Input
                                        size='md'
                                        type='text'
                                        placeholder='e.g. 4.9%'
                                        name={APR}
                                        onChange={handleAddLoanPercentFormChange}
                                        className="default_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_page__add_loan_modal__label">
                                POINTS
                            </p>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={pointsError}
                                isOpen={pointsError !== null}
                                placement='top-end'
                            >
                                <InputGroup>
                                    <Input
                                        size='md'
                                        type='text'
                                        placeholder='e.g. 0.5%'
                                        name={POINTS}
                                        onChange={handleAddLoanPercentFormChange}
                                        className="default_input"
                                    />
                                </InputGroup>
                            </Tooltip>
                        </div>
                    </div>
                    {
                        loanType && 
                        (
                            TEN_ONE_ARM === loanType ||
                            FIVE_ONE_ARM === loanType ||
                            SEVEN_ONE_ARM === loanType
                        ) ?
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px 0px 0px 0px",
                        }}>
                            <div style={{
                                width: "calc(50% - 10px)",
                            }}>
                                <p className="loan_page__add_loan_modal__label">
                                    FULLY INDEXED INTEREST RATE
                                </p>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={downPaymentPercentError}
                                    isOpen={downPaymentPercentError !== null}
                                    placement='top-start'
                                >
                                    <InputGroup>
                                        <Input
                                            size='md'
                                            type='text'
                                            placeholder='e.g. 20%'
                                            name={FULLY_INDEXED_INTEREST_RATE}
                                            onChange={handleAddLoanPercentFormChange}
                                            className="default_input"
                                        />
                                    </InputGroup>
                                </Tooltip>
                            </div>
                            <div style={{
                                width: "calc(50% - 10px)",
                            }}>
                                <p className="loan_page__add_loan_modal__label">
                                    UPFRONT MIP
                                </p>
                                <Tooltip
                                    bg='red'
                                    hasArrow
                                    label={interestRatePercentError}
                                    isOpen={interestRatePercentError !== null}
                                    placement='top-end'
                                >
                                    <InputGroup>
                                        <Input
                                            size='md'
                                            type='text'
                                            name={UPFRONT_MIP}
                                            onChange={handleAddLoanPercentFormChange}
                                            className="default_input"
                                        />
                                    </InputGroup>
                                </Tooltip>
                            </div>
                        </div> : null
                    }
                    <Checkbox
                        name={IS_DEFAULT}
                        onChange={(e) => {
                            addLoanForm[IS_DEFAULT] = e.target.checked;
                        }}
                        style={{
                            color: "grey",
                            fontStyle: "italic",
                            margin: "10px 0px 0px 0px"
                        }}
                    >
                        <p style={{
                            fontSize: "0.9em",
                        }}>
                            Set as my default loan
                        </p>
                    </Checkbox>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="negative_button hover"
                        mr={3}
                        onMouseDown={closeAddLoanModal}
                        size='sm'
                    >
                        Cancel
                    </Button>
                    <Button 
                        className="positive_button hover"
                        onMouseDown={() => handleAddLoanFormSubmit()}
                        size='sm'
                    >
                        Add Loan
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default AddLoanModal;