import React, { useEffect, useState, createRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from '@chakra-ui/react';

import {
    DEFAULT_GET_TIMEOUT,
    GET,
    ID,
    LOANS,
    PATCH,
    IS_DEFAULT,
    DEFAULT_PATCH_TIMEOUT,
} from '../../../utils/constants.js';

import { convertLoanTypeEnumToReadableText, dollarfyPrice } from '../../../utils/utility.js';
import { axiosGetUser, axiosGetBuyer } from '../../common/methods/methods.js';
import { checkRequireBuyerSignUp } from '../../../utils/utility.js';

import { FaMarker, FaPlus } from 'react-icons/fa6';
import { MdEdit } from 'react-icons/md';
import { FaRegStar, FaStar } from 'react-icons/fa';
import AddLoanModal from './AddLoanModal.js';
import LoanModal from './LoanModal.js';
import NoObjectsDisplay from '../../common/NoObjectsDisplay.js';

import BuyerDashboardParentPage from '../utils/BuyerDashboardParentPage.js';
import MainPanel from '../../common/MainPanel.js';
import BuyerSignUpInformationModal from '../utils/BuyerSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';
import FlipMove from 'react-flip-move';

import { dotPulse } from 'ldrs'

import './Page.css';
import '../../Generic.css';
import LoansTableRow from './LoansTableRow.js';

function BuyerLoansDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    const [buyer, setBuyer] = useState(null);
    const [buyerLoading, setBuyerLoading] = useState(true);

    const [loans, setLoans] = useState([]);
    const [loansLoading, setLoansLoading] = useState(true);
    const [updateDefaultLoanLoading, setUpdateDefaultLoanLoading] = useState(false);
    // Our add loan form. We use this object to track the fields as the user
    // sets them.

    // Control our modals and their visibility.
    const [showAddLoanModal, setShowAddLoanModal] = useState(false);
    const [showLoanModal, setShowLoanModal] = useState(false);
    const [triggerFetchLoans, setTriggerFetchLoans] = useState(false);

    const [loanModalLoan, setLoanModalLoan] = useState(null);

    const [finishSignUpFormBuyer, setFinishSignUpFormBuyer] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    dotPulse.register()

    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (null !== user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            // TODO
        });
    }, [userId]);

    useEffect(() => {
        axiosGetBuyer(userId).then(buyer => {
            console.log(buyer);
            if (buyer && checkRequireBuyerSignUp(buyer) === true) {
                setFinishSignUpFormBuyer(buyer);
            } 
            setBuyer(buyer);
            setBuyerLoading(false);
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId]);

    // Fetch our loans from the server for this buyer.
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/loans/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                let responseLoans = response.data;
                setLoans(responseLoans);
                setLoansLoading(false);
            }
        }).catch(error => {
            setLoansLoading(false);
            // TODO: Handle our error codes.
        })
    }, [userId, triggerFetchLoans]);

    const handleUpdateLoan = (loan, data, callback) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/buyer/loans/' + userId + '/' + loan[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data
        }).then(response => {
            // Successful response. Close the modal and load the loan.
            if (response.status === 200) {
                callback();
                setTriggerFetchLoans(!triggerFetchLoans);
                setLoanModalLoan(null);
                setShowLoanModal(false);
            }
        }).catch(error => {
            // TODO
        })
        setTimeout(() => {
            setUpdateDefaultLoanLoading(false);
        }, 500);
    }

    const isDefaultLoan = (loan) => {
        return loan && loan[IS_DEFAULT] && loan[IS_DEFAULT] === true;
    }

    if (userLoading || buyerLoading) return;

    return (
        <BuyerDashboardParentPage user={user} tab={LOANS} buyer={buyer}>
            {
                loansLoading || finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormBuyer ?
                        <BuyerSignUpInformationModal
                            user={finishSignUpFormBuyer}
                            setFinishSignUpFormBuyer={setFinishSignUpFormBuyer}
                        />: null
                    }
                    <MainPanel>
                        {/* {
                            showToolTip && loans && loans.length > 0 ? 
                            (
                                <Alert status='success' variant='left-accent' style={{
                                    borderRadius: "4px",
                                    justifyContent: "space-between",
                                    margin: "15px 0px 0px 0px",
                                }}>
                                    <div style={{
                                        display: "flex",
                                    }}>
                                        <AlertIcon as={FaLightbulb}/>
                                        Tip: Effective APR is the annual cost of taking out your loan (the real interest rate of your loan), so the lower the better
                                    </div>
                                    <CloseButton onMouseDown={() => {
                                        setShowToolTip(false);
                                    }}/>
                                </Alert>
                            ) : null
                        } */}
                        <AddLoanModal
                            userId={userId}
                            closeAddLoanModal={() => setShowAddLoanModal(false)}
                            showAddLoanModal={showAddLoanModal}
                            triggerFetchLoans={() => setTriggerFetchLoans(!triggerFetchLoans)}
                        />
                        <LoanModal
                            userId={userId}
                            loanModalLoan={loanModalLoan}
                            showLoanModal={showLoanModal}
                            closeLoanModal={() => setShowLoanModal(false)}
                            handleUpdateLoan={handleUpdateLoan}
                            setLoanModalLoan={() => setLoanModalLoan}
                            triggerFetchLoans={() => setTriggerFetchLoans(!triggerFetchLoans)}
                        />
                        
                        {
                            loans && loans.length > 0 ? 
                            <>
                                <div className="loans_page__filters_parent">
                                    <div style={{
                                        display: "flex",
                                    }}>
                                        <p style={{
                                            fontSize: "1.6em",
                                            fontWeight: "bold",
                                        }}>
                                            Loans
                                        </p>
                                    </div>
                                    <Button className="positive_button hover loans_page__add_loan_button" leftIcon={<FaPlus/>} size='sm' onMouseDown={() => setShowAddLoanModal(true)}>
                                        Add Loan
                                    </Button>
                                </div>
                                <div style={{
                                    margin: "10px 0px 0px 0px",
                                    overflowX: "scroll",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "0px 0px 10px 0px",
                                        padding: "0px 15px 0px 15px",
                                        width: "100%"
                                    }}>
                                        <p className="loan_page__table_title" style={{
                                            minWidth: "50px",
                                            width: "10%",
                                        }}>
                                            DEFAULT
                                        </p>
                                        <p className="loan_page__table_title" style={{
                                            minWidth: "80px",
                                            width: "30%",
                                        }}>
                                            LOAN TYPE
                                        </p>
                                        <p className="loan_page__table_title" style={{
                                            minWidth: "100px",
                                            width: "15%",
                                        }}>
                                            DOWN PAYMENT
                                        </p>
                                        <p className="loan_page__table_title" style={{
                                            minWidth: "115px",
                                            width: "15%",
                                        }}>
                                            MONTHLY PAYMENT
                                        </p>
                                        <p className="loan_page__table_title" style={{
                                            minWidth: "115px",
                                            width: "15%",
                                        }}>
                                            TOTAL LOAN COSTS
                                        </p>
                                        <p className="loan_page__table_title" style={{
                                            minWidth: "115px",
                                            width: "15%",
                                        }}>
                                            EFFECTIVE APR
                                        </p>
                                        <div style={{width: "50px"}}></div>
                                    </div>
                                    <FlipMove>
                                        {loans.map(loan => (
                                            <LoansTableRow
                                                key={loan[ID]}
                                                loan={loan}
                                                handleUpdateLoan={handleUpdateLoan}
                                                setShowAddLoanModal={setShowAddLoanModal}
                                                setLoanModalLoan={setLoanModalLoan}
                                                setUpdateDefaultLoanLoading={setUpdateDefaultLoanLoading}
                                                ref={createRef()}
                                            >
                                                <div key={loan[ID]}
                                                    onMouseDown={() => {
                                                        setShowLoanModal(true);
                                                        setLoanModalLoan({...loan});
                                                    }}
                                                    style={{
                                                        backgroundColor: isDefaultLoan(loan) ? "#efefef" : "transparent",
                                                        borderRadius: "12px",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                        padding: "15px 15px 15px 15px",
                                                    }}  
                                                >
                                                    <div className="loan_page__table_text" style={{
                                                        minWidth: "50px",
                                                        width: "10%",
                                                    }}>
                                                        {isDefaultLoan(loan) ? 
                                                        <FaStar 
                                                            style={{
                                                                cursor: "pointer",
                                                                margin: "2.5px 0px 0px 0px",
                                                            }}
                                                            onMouseDown={(e) => {
                                                                setUpdateDefaultLoanLoading(true);
                                                                e.stopPropagation();
                                                                // Is current default loan. Make it non-default and save to server.
                                                                loan[IS_DEFAULT] = false;
                                                                handleUpdateLoan(loan, {is_default: false}, () => {});
                                                            }}/> : 
                                                        <FaRegStar 
                                                            style={{
                                                                cursor: "pointer",
                                                                margin: "2.5px 0px 0px 0px",
                                                            }}
                                                            onMouseDown={(e) => {
                                                                setUpdateDefaultLoanLoading(true);
                                                                e.stopPropagation();
                                                                // Is current default loan. Make it non-default and save to server.
                                                                loan[IS_DEFAULT] = true;
                                                                handleUpdateLoan(loan, {is_default: true}, () => {});
                                                            }}
                                                        />}
                                                    </div>
                                                    <p className="loan_page__table_text" style={{
                                                        minWidth: "80px",
                                                        width: "30%"
                                                    }}>{convertLoanTypeEnumToReadableText(loan["loan_type"])}</p>
                                                    <p className="loan_page__table_text" style={{
                                                        minWidth: "100px",
                                                        width: "15%"
                                                    }}>{loan["down_payment_percent"]}%</p>
                                                    <p className="loan_page__table_text" style={{
                                                        minWidth: "115px",
                                                        width: "15%"
                                                    }}>${dollarfyPrice(loan["monthly_payment"])}</p>
                                                    <p className="loan_page__table_text" style={{
                                                        minWidth: "115px",
                                                        width: "15%"
                                                    }}>${dollarfyPrice(loan["total_loan_costs"])}</p>
                                                    <p className="loan_page__table_text" style={{
                                                        minWidth: "115px",
                                                        width: "15%"
                                                    }}>{loan["apr"]}%</p>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        width: "50px",
                                                    }}>
                                                        <MdEdit style={{
                                                            height: "17.5px",
                                                            margin: "1.5px 0px 1.5px 0px",
                                                            width: "17.5px",
                                                        }}/>
                                                    </div>
                                                    
                                                </div>
                                            </LoansTableRow>
                                            ))
                                        }
                                    </FlipMove>
                                </div>
                            </> :
                            <NoObjectsDisplay
                                icon={FaMarker}
                                buttonCallback={() => setShowAddLoanModal(true)}
                                buttonText={'Add a Loan'}
                                titleText={'NO LOANS FOUND'}
                                subtitleText={'Add a loan here to track financing options. Add multiple to easily compare between them.'}
                            />
                        }
                    </MainPanel>
                </>
            }
        </BuyerDashboardParentPage>
    );
}

export default BuyerLoansDashboardPage;