import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    Input,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Stack,
    Tooltip,
    useToast,
} from '@chakra-ui/react';

import {
    PATCH,
    RESIROOTS_DEFAULT_OFFER,
    COMMISSION_PERCENTAGE,
    NUMBER_OF_MONTHS,
    RETAINER_FEE_DOLLARS,
    REPRESENTATION,
    IS_EXCLUSIVE,
    DEFAULT_PATCH_TIMEOUT,
} from '../../../utils/constants.js';

import './Page.css';
import '../../Generic.css';

function DefaultOfferModal(props) {

    const userId = props.userId;
    const defaultOffer = props.defaultOffer;
    const triggerFetchDefaultOffer = props.triggerFetchDefaultOffer;
    const showDefaultOfferModal = props.showDefaultOfferModal;
    const setShowDefaultOfferModal = props.setShowDefaultOfferModal;
    const setTriggerFetchDefaultOffer = props.setTriggerFetchDefaultOffer;

    const [defaultOfferForm, setDefaultOfferForm] = useState(props.defaultOfferForm);

    // Errors
    const [commissionError, setCommissionError] = useState(null);
    const [termError, setTermError] = useState(null);
    const [retainerFeeError, setRetainerFeeError] = useState(null);

    const toast = useToast();

    const handleSubmitDefaultOfferChange = () => {

        if (checkAndAlertDefaultOfferFormErrors()) {
            return;
        }

        axios({
            method: PATCH,
            url: '/api/v1/user/agent/offer/default/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: {
                COMMISSION_PERCENTAGE: parseInt(defaultOfferForm[COMMISSION_PERCENTAGE]),
                NUMBER_OF_MONTHS: parseInt(defaultOfferForm[NUMBER_OF_MONTHS]),
                RETAINER_FEE_DOLLARS: parseInt(defaultOfferForm[RETAINER_FEE_DOLLARS]),
                REPRESENTATION: defaultOfferForm[REPRESENTATION],
                IS_EXCLUSIVE: defaultOfferForm[IS_EXCLUSIVE] === 'No',
            }
        }).then(response => {
            if (200 === response.status) {
                setShowDefaultOfferModal(false);
                setTriggerFetchDefaultOffer(!triggerFetchDefaultOffer);
                toast({
                    title: 'Default Offer saved',
                    description: "Successfully saved your new default offer.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).catch(error => {
            if (400 === error.response.status) {
                toast({
                    title: 'Invalid default offer',
                    description: "Unable to save default offer. " + error.response.data,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        })
    }

    const checkAndAlertDefaultOfferFormErrors = () => {

        if (defaultOfferForm === null || defaultOfferForm === undefined) {
            return true;
        }

        var hasErrors = false;

        // Check commission errors.
        if (!defaultOfferForm[COMMISSION_PERCENTAGE] || defaultOfferForm[COMMISSION_PERCENTAGE].length === 0) {
            hasErrors = true;
            setCommissionError("Commission % is required");
        } else if (parseInt(defaultOfferForm[COMMISSION_PERCENTAGE]) > 100) {
            hasErrors = true;
            setCommissionError("Commission % cannot be greater than 100%");
        } else if (parseInt(defaultOfferForm[COMMISSION_PERCENTAGE]) < 0) {
            hasErrors = true;
            setCommissionError("Commission % cannot be smaller than 0%");
        }

        // Check term errors.
        if (!defaultOfferForm[NUMBER_OF_MONTHS] || defaultOfferForm[NUMBER_OF_MONTHS].length === 0) {
            hasErrors = true;
            setTermError("Term is required");
        } else if (parseInt(defaultOfferForm[NUMBER_OF_MONTHS]) > 60) {
            hasErrors = true;
            setTermError("Term can't' be longer than 60 months");
        } else if (parseInt(defaultOfferForm[NUMBER_OF_MONTHS]) < 0) {
            hasErrors = true;
            setTermError("Term can't be smaller than 0 months");
        }

        // Check retainer fee errors.
        if (!defaultOfferForm[RETAINER_FEE_DOLLARS] || defaultOfferForm[RETAINER_FEE_DOLLARS].length === 0) {
            hasErrors = true;
            setRetainerFeeError("Retainer fee is required")
        } else if (parseInt(defaultOfferForm[RETAINER_FEE_DOLLARS]) < 0) {
            hasErrors = true;
            setRetainerFeeError("Retainer fee cannot be lower than $0");
        }

        return hasErrors;
    }

    const handleDefaultOfferFormChange = (event) => {
        if (event.target.name === COMMISSION_PERCENTAGE) {
            setCommissionError(null);
        } else if (event.target.name === NUMBER_OF_MONTHS) {
            setTermError(null);
        } else if (event.target.name === RETAINER_FEE_DOLLARS) {
            setRetainerFeeError(null);
        }
        defaultOfferForm[event.target.name] = event.target.value;
    }

    return(
        <Modal size='sm' isOpen={showDefaultOfferModal} onClose={() => setShowDefaultOfferModal(false)} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "0px",
                }}>
                    Instant Offer
                </ModalHeader>
                <ModalCloseButton onClose={() => {
                    setShowDefaultOfferModal(false)
                }} onMouseDown={() => {
                    setShowDefaultOfferModal(false);
                }}/>
                <ModalBody style={{
                    paddingBottom: "20px",
                    paddingTop: "0px" 
                }}>
                    <p style={{
                        color: "grey",
                        fontSize: "0.9em",
                        lineHeight: "20px",
                        margin: "7.5px 0px 0px 0px",
                        padding: "0px 0px 0px 0px",
                    }}>
                        Set your default offer for faster outreach. You can always modify any offer you make before final submission.
                    </p>
                    <p style={{
                        color: "grey",
                        fontSize: "0.9em",
                        fontWeight: "bold",
                        lineHeight: "20px",
                        margin: "7.5px 0px 0px 0px",
                        padding: "0px 0px 0px 0px",
                    }}>
                        Note: This will be the default offer prospective buyers see
                    </p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                        width: "100%"
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <label className="agent_leads_dashboard_page_default_offer_title">
                                COMMISION (%)
                            </label>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={commissionError}
                                isOpen={commissionError !== null}
                                placement='top-end'
                            >
                                <Input 
                                    className="default_input"
                                    name={COMMISSION_PERCENTAGE}
                                    defaultValue={defaultOffer ? defaultOffer[COMMISSION_PERCENTAGE] : RESIROOTS_DEFAULT_OFFER[COMMISSION_PERCENTAGE]}
                                    type='number'
                                    size='sm'
                                    onChange={handleDefaultOfferFormChange}
                                />
                            </Tooltip>
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <label className="agent_leads_dashboard_page_default_offer_title">
                                TERM (NO. OF MONTHS)
                            </label>
                            <Tooltip
                                bg='red'
                                hasArrow
                                label={termError}
                                isOpen={termError !== null}
                                placement='top-end'
                            >
                                <Input
                                    className="default_input"
                                    name={NUMBER_OF_MONTHS}
                                    defaultValue={defaultOffer ? defaultOffer[NUMBER_OF_MONTHS] : RESIROOTS_DEFAULT_OFFER[NUMBER_OF_MONTHS]}
                                    type='number'
                                    size='sm'
                                    onChange={handleDefaultOfferFormChange}
                                    style={{
                                        width: "100%"
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{
                        margin: "5px 0px 0px 0px",
                        width: "100%"
                    }}>
                        <label className="agent_leads_dashboard_page_default_offer_title">
                            RETAINER FEE ($)
                        </label>
                        <Tooltip
                                bg='red'
                                hasArrow
                                label={retainerFeeError}
                                isOpen={retainerFeeError !== null}
                                placement='top-end'
                            >
                            <Input
                                className="default_input"
                                name={RETAINER_FEE_DOLLARS}
                                defaultValue={defaultOffer ? defaultOffer[RETAINER_FEE_DOLLARS] : RESIROOTS_DEFAULT_OFFER[RETAINER_FEE_DOLLARS]}
                                type='number'
                                size='sm'
                                onChange={handleDefaultOfferFormChange}
                                style={{
                                    margin: "0px 0px 0px 0px",
                                    width: "100%"
                                }}
                            />
                        </Tooltip>
                    </div>
                    {/* <div style={{
                        margin: "5px 0px 0px 0px",
                        width: "100%"
                    }}>
                        <label className="agent_leads_dashboard_page_default_offer_title">
                            RETAINER PART OF COMMISSION
                        </label>
                        <Stack style={{
                            width: "100%"
                        }}>
                            <Select 
                                size='sm'
                                className="default_input"
                                name={RETAINER_PART_OF_COMMISSION}
                                defaultValue={defaultOffer ? (
                                         defaultOffer[RETAINER_PART_OF_COMMISSION] === true ? "Yes" : "No") : 
                                         RESIROOTS_DEFAULT_OFFER[RETAINER_PART_OF_COMMISSION] === true ? "Yes" : "No"}
                                onChange={handleDefaultOfferFormChange}
                            >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                            </Select>
                        </Stack>
                    </div> */}
                    <div style={{
                        margin: "5px 0px 0px 0px",
                        width: "100%"
                    }}>
                        <label className="agent_leads_dashboard_page_default_offer_title">
                            REPRESENTATION
                        </label>
                        <Stack style={{
                            width: "100%",
                        }}>
                            <Select
                                size='sm'
                                className="default_input"
                                name={REPRESENTATION}
                                defaultValue={defaultOffer ? defaultOffer[REPRESENTATION] : RESIROOTS_DEFAULT_OFFER[REPRESENTATION]}
                                onChange={handleDefaultOfferFormChange}
                            >
                                <option value='ClientFiduciary'>Client (Fiduciary)</option>
                                <option value='Customer(NonFiduciary)'>Customer (Non-Fiduciary)</option>
                            </Select>
                        </Stack>
                    </div>
                    <div style={{
                        margin: "5px 0px 0px 0px",
                        width: "100%"
                    }}>
                        <label className="agent_leads_dashboard_page_default_offer_title">
                            EXCLUSIVITY
                        </label>
                        <Stack style={{
                            width: "100%",
                        }}>
                            <Select
                                size='sm'
                                className="default_input"
                                name={IS_EXCLUSIVE}
                                onChange={handleDefaultOfferFormChange}
                                defaultValue={defaultOffer ? (defaultOffer[IS_EXCLUSIVE] === true ? 'Yes' : 'No') : RESIROOTS_DEFAULT_OFFER[IS_EXCLUSIVE] === true ? 'Yes' : 'No'}
                            >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                            </Select>
                        </Stack>
                    </div>
                    <div style={{
                        margin: "15px 0px 0px 0px",
                    }}>
                        <Button
                            className="positive_button hover"
                            onMouseDown={() => handleSubmitDefaultOfferChange()}
                            size='sm'
                            style={{
                                float: "right",
                            }}>
                            Save
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default DefaultOfferModal;