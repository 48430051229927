import react, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Alert,
    AlertIcon,
    Avatar,
    Button,
    Icon,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ButtonGroup,
    Center,
    Flex,
    Text,
    Stack
} from '@chakra-ui/react';

import {
    CONNECTION,
    CONNECTION_REQUEST_BY_BUYER_ACCEPTED,
    CONNECTION_REQUEST_BY_AGENT_ACCEPTED,
    CONNECTION_REQUEST_BY_AGENT_PENDING,
    CONNECTION_REQUEST_BY_BUYER_PENDING,
    CONNECTION_REMOVED_BY_BUYER,
    FIRST_NAME,
    LAST_NAME,
    GET,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_POST_TIMEOUT,
    NOT_INTERESTED_BY_BUYER,
    ID,
    POST,
    STATUS,
    STATUSES,
    FIRM,
    PROFILE_PICTURE_URL,
    NOT_INTERESTED_BY_BUYER_REMOVED,
} from '../../../utils/constants.js';

import {
    FaArrowLeftLong,
    FaArrowRight,
    FaUsers,
    FaCircleInfo,
    FaUser,
    FaEyeSlash,
    FaArrowRightLong,
    FaBolt,
    FaPaperPlane
} from "react-icons/fa6";

import { prettifyAgentRepresentation } from '../../../utils/utility.js';

function ConnectionsModal(props) {

    const navigate = useNavigate();

    const user = props.user;
    const userId = user[ID];

    const showConnectionsModal = props.showConnectionsModal;
    const setShowConnectionsModal = props.setShowConnectionsModal;
    const contactedByAgentsWithOffer = props.contactedByAgentsWithOffer;
    const contactedAgents = props.contactedAgents;
    const notInterestedConnections = props.notInterestedConnections;
    const handleAddConnection = props.handleAddConnection;
    const setContactedByAgent = props.setContactedByAgent;

    const TOGGLE_AGENT_OFFERS = 0;
    const TOGGLE_CONTACTED_AGENTS = 1;
    const TOGGLE_NOT_INTERESTED = 2;

    const [showContactedAgents, setShowContactedAgents] = useState(TOGGLE_AGENT_OFFERS);

    const renderBody = () => {
        switch (showContactedAgents) {
            case TOGGLE_CONTACTED_AGENTS: 
                return renderContactedAgents();
            case TOGGLE_AGENT_OFFERS:
                return renderAgentOffers();
            case TOGGLE_NOT_INTERESTED:
                return renderNotInterestedAgents();
            default:
                return;
        }
    }

    const renderAgentOffers = () => {

        // No Contacted agents.
        if (!contactedByAgentsWithOffer || contactedByAgentsWithOffer.length === 0) {
            return (
                <Center style={{marginTop: "75px"}}>
                    <Flex gap='1'>
                        <FaCircleInfo style={{
                            color: "#555555",
                            height: "12.5px",
                            width: "12.5px",
                        }}/>
                        <Text style={{
                            color: "#555555",
                            fontSize: "0.8em",
                            lineHeight: "12.5px",
                        }}>
                            Agents who contact you will appear here
                        </Text>
                    </Flex>
                </Center>
            )
        }

        let agents = [];

        for (let i = 0; i < contactedByAgentsWithOffer.length; i++) {
            let agent = contactedByAgentsWithOffer[i];
            agents.push(
                <Flex onMouseDown={() => setContactedByAgent(agent)} gap='3' className='hover'>
                    <Icon color='#05b96b' as={FaBolt} boxSize='1.2em' style={{marginTop: "7.5px"}}/>
                    <Stack>
                        <Flex gap='2'>
                            <Avatar size='sm' src={agent && agent.profile_picture_url ? agent.profile_picture_url : null}></Avatar>
                            <Stack gap='0'>
                                <Text fontWeight='bold' fontSize='sm'
                                    // onMouseDown={() => navigate("/user/buyer/" + userId + "/agent-profile/" + agent.agent_id)}
                                >
                                    {agent[FIRST_NAME]} {agent[LAST_NAME]}
                                </Text>
                                <Text color='gray' fontSize='xs' style={{marginTop: "-5px"}}>
                                    {agent[FIRM] ? agent[FIRM] : "-"}
                                </Text>
                            </Stack>
                        </Flex>
                        <Text fontSize='sm'>
                            {agent.offer_commission_percentage}% Commission | {prettifyAgentRepresentation(agent.offer_representation)} | {agent.offer_number_of_months} mo.
                        </Text>
                    </Stack>
                </Flex>
            );
        }
        return (
            <Stack gap='5' style={{marginTop: "25px"}}>
                {agents}
            </Stack>
        );
    }

    const renderContactedAgents = () => {
        
        // No Contacted agents.
        if (!contactedAgents || contactedAgents.length === 0) {
            return (
                <Center style={{marginTop: "75px"}}>
                    <Flex gap='1'>
                        <FaCircleInfo style={{
                            color: "#555555",
                            height: "12.5px",
                            width: "12.5px",
                        }}/>
                        <Text style={{
                            color: "#555555",
                            fontSize: "0.8em",
                            lineHeight: "12.5px",
                        }}>
                            Agents you Contact will appear here
                        </Text>
                    </Flex>
                </Center>
            )
        }
        let agents = [];
        for (let i = 0; i < contactedAgents.length; i++) {
            let agent = contactedAgents[i];
            agents.push(
                <div className="agents_feed__buyer_agents_panel__agent_card">
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <div style={{
                            display: "flex",
                        }}>
                            <Avatar size='sm' src={agent && agent.profile_picture_url ? agent.profile_picture_url : null}></Avatar>
                            <div style={{
                                margin: "0px 0px 0px 10px",
                            }}>
                                <p 
                                    className="agents_feed__buyer_agents_panel__agent_card__name"
                                    onMouseDown={() => navigate("/user/buyer/" + userId + "/agent-profile/" + agent.agent_id)}
                                >
                                    {agent[FIRST_NAME]} {agent[LAST_NAME]}
                                </p>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.8em",
                                    padding: "0px",
                                }}>
                                    {agent[FIRM] ? agent[FIRM] : "N/A"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return agents;
    }

    const renderNotInterestedAgents = () => {

        // No Contacted agents.
        if (!notInterestedConnections || notInterestedConnections.length === 0) {
            return (
                <Center style={{marginTop: "75px"}}>
                    <Flex gap='1'>
                        <FaCircleInfo style={{
                            color: "#555555",
                            height: "12.5px",
                            width: "12.5px",
                        }}/>
                        <Text style={{
                            color: "#555555",
                            fontSize: "0.8em",
                            lineHeight: "12.5px",
                        }}>
                            Not interested agents will appear here
                        </Text>
                    </Flex>
                </Center>
            );
        }

        let agents = [];

        for (let i = 0; i <notInterestedConnections.length; i++) {
            let agent = notInterestedConnections[i];
            agents.push(
                <div style={{
                    borderRadius: "12px",
                    margin: "10px 0px 0px 0px",
                    padding: "10px 10px 10px 10px",
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <Avatar size='xs' src={agent ? agent[PROFILE_PICTURE_URL] : ""}/>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}>
                            <div style={{
                                margin: "0px 0px 0px 7.5px",
                            }}>
                                <p style={{
                                    fontSize: "0.9em",
                                    lineHeight: "24px",
                                }}>
                                    {agent[FIRST_NAME]} {agent[LAST_NAME]}
                                </p>
                            </div>
                            <div>
                                <p className="agents_feed__not_interested_connections__unhide_button hover" onMouseDown={
                                    () => handleAddConnection(agent.id, NOT_INTERESTED_BY_BUYER_REMOVED, () => {})
                                }>
                                    Unhide
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div style={{marginTop: "25px"}}>
                {agents}
            </div>
        );
    }

    return (
        <Modal size='2xl' isOpen={showConnectionsModal} onClose={() => setShowConnectionsModal(false)} isCentered>
            <ModalOverlay/>
            <ModalContent minHeight='500px' maxHeight='500px'>
                <ModalHeader>
                    My Agents    
                </ModalHeader>
                <ModalCloseButton onClose={() => setShowConnectionsModal(false)} onMouseDown={() => setShowConnectionsModal(false)}/>
                <ModalBody overflow='scroll'>
                   <ButtonGroup>
                        <Button 
                            size='sm' 
                            className={TOGGLE_AGENT_OFFERS === showContactedAgents ? "positive_button hover" : "negative_grey_minimal_full_button hover"}
                            onMouseDown={() => setShowContactedAgents(TOGGLE_AGENT_OFFERS)}
                            leftIcon={<FaBolt/>}
                        >
                            Agent Offers
                        </Button>
                        <Button 
                            size='sm' 
                            className={TOGGLE_CONTACTED_AGENTS === showContactedAgents ? "positive_button hover" : "negative_grey_minimal_full_button hover"}
                            onMouseDown={() => setShowContactedAgents(TOGGLE_CONTACTED_AGENTS)}
                            leftIcon={<FaPaperPlane/>}
                        >
                            Contacted Agents
                        </Button>
                        <Button
                            leftIcon={<FaEyeSlash/>}
                            className={TOGGLE_NOT_INTERESTED === showContactedAgents ? "positive_button hover" : "negative_grey_minimal_full_button hover"}
                            onMouseDown={() => setShowContactedAgents(TOGGLE_NOT_INTERESTED)}
                            size='sm'
                        >
                            Not Interested
                        </Button>
                    </ButtonGroup>
                    {renderBody()}
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ConnectionsModal;