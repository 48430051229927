import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Flex,
    IconButton,
    Input,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalOverlay,
    ModalBody,
    Select,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react';

import {
    DELETE,
    ID,
    LOAN_TYPE,
    DOWN_PAYMENT_PERCENT,
    INTEREST_RATE,
    APR,
    POINTS,
    THIRTY_YEAR_FIXED,
    FIFTEEN_YEAR_FIXED,
    FIVE_ONE_ARM,
    SEVEN_ONE_ARM,
    TEN_ONE_ARM,
    THIRTY_YEAR_FHA,
    DEFAULT_DELETE_TIMEOUT,
} from '../../../utils/constants.js';

import { convertLoanTypeEnumToReadableText } from '../../../utils/utility.js';

import { MdClose, MdEdit } from "react-icons/md";
import { FaTrashAlt } from 'react-icons/fa';

function LoanModal(props) {

    const userId = props.userId;
    const loanModalLoan = props.loanModalLoan;
    const showLoanModal = props.showLoanModal;
    const closeLoanModal = props.closeLoanModal;
    const handleUpdateLoan = props.handleUpdateLoan;
    const setLoanModalLoan = props.setLoanModalLoan;
    const triggerFetchLoans = props.triggerFetchLoans;

    const [editLoan, setEditLoan] = useState(false);
    const [loanModalForm ] = useState([]);

    const toast = useToast();

    const handleDeleteLoan = (loan) => {
        axios({
            method: DELETE,
            url: '/api/v1/user/buyer/loans/' + userId + '/' + loan[ID],
            timeout: DEFAULT_DELETE_TIMEOUT,
        }).then(response => {
            if (response.data === 200) {
                // Successful delete, so we need to remove the loan from our state.
                triggerFetchLoans();
                closeLoanModal();
                setLoanModalLoan(null);
                toast({
                    title: 'Deleted Loan',
                    description: "Successfully deleted your loan",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).catch(error => {
            // TODO
        })
    }

    const handleLoanModalFormChange = (event) => {
        loanModalForm[event.target.name] = event.target.value;
    }

    if (null === loanModalLoan || undefined === loanModalLoan) {
        return;
    }
    return (
        <Modal size='xs' isOpen={showLoanModal} onClose={() => {
            closeLoanModal();
            setLoanModalLoan(null);
            setEditLoan(false);
        }} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "10px"
                }}>Loan</ModalHeader>
                <ModalCloseButton onClose={() => {
                    closeLoanModal();
                    setLoanModalLoan(null);
                    setEditLoan(false);
                }} onMouseDown={() => {
                    closeLoanModal();
                    setLoanModalLoan(null);
                    setEditLoan(false);
                }}/>
                <ModalBody style={{
                    paddingBottom: "20px",
                    paddingTop: "0px",
                }}>
                    <Stack gap={4}>
                        <Stack gap={3}>
                            <Stack gap={1}>
                                <Text fontSize='xs' color='gray'>
                                    LOAN TYPE
                                </Text>
                                {
                                    editLoan ?
                                    <Select
                                        className='default_input'
                                        name={LOAN_TYPE}
                                        onChange={handleLoanModalFormChange}
                                        variant='outline'
                                        size='sm'
                                    >
                                        <option selected={loanModalLoan[LOAN_TYPE] === THIRTY_YEAR_FIXED} value={THIRTY_YEAR_FIXED}>30 Year Fixed</option>
                                        <option selected={loanModalLoan[LOAN_TYPE] === FIFTEEN_YEAR_FIXED} value={FIFTEEN_YEAR_FIXED}>15 Year Fixed</option>
                                        <option selected={loanModalLoan[LOAN_TYPE] === FIVE_ONE_ARM} value={FIVE_ONE_ARM}>5/1 ARM</option>
                                        <option selected={loanModalLoan[LOAN_TYPE] === SEVEN_ONE_ARM} value={SEVEN_ONE_ARM}>7/1 ARM</option>
                                        <option selected={loanModalLoan[LOAN_TYPE] === TEN_ONE_ARM} value={TEN_ONE_ARM}>10/1 ARM</option>
                                        <option selected={loanModalLoan[LOAN_TYPE] === THIRTY_YEAR_FHA} value={THIRTY_YEAR_FHA}>30 Year FHA</option>
                                    </Select> 
                                        :
                                    <Text>
                                        {convertLoanTypeEnumToReadableText(loanModalLoan[LOAN_TYPE])}
                                    </Text>
                                }
                            </Stack>
                            <Stack gap={1}>
                                <Text fontSize='xs' color='gray'>
                                    DOWN PAYMENT
                                </Text>
                                {
                                    editLoan ? 
                                    <Input 
                                        className="default_input loan_page__loan_modal__input"
                                        name={DOWN_PAYMENT_PERCENT}
                                        onChange={(e) => {
                                            loanModalForm[e.target.name] = parseFloat(e.target.value);
                                        }}
                                        placeholder={loanModalLoan[DOWN_PAYMENT_PERCENT] + "%"}
                                        size='sm'
                                    ></Input> :
                                    <Text>
                                        {loanModalLoan[DOWN_PAYMENT_PERCENT]}%
                                    </Text>
                                }
                            </Stack>
                            <Stack gap={1}>
                                <Text fontSize='xs' color='gray'>
                                    INTEREST RATE
                                </Text>
                                {
                                    editLoan ? 
                                    <Input 
                                        className="default_input loan_page__loan_modal__input"
                                        name={INTEREST_RATE}
                                        onChange={(e) => {
                                            loanModalForm[e.target.name] = parseFloat(e.target.value);
                                        }}
                                        placeholder={loanModalLoan[INTEREST_RATE] + "%"}
                                        size='sm'
                                    ></Input> :
                                    <p className="loan_modal__text">
                                        {loanModalLoan[INTEREST_RATE]}%
                                    </p>
                                }
                            </Stack>
                            <Stack gap={1}>
                                <Text fontSize='xs' color='gray'>
                                    APR
                                </Text>
                                {
                                    editLoan ? 
                                    <Input 
                                        className="default_input loan_page__loan_modal__input"
                                        name={APR}
                                        onChange={(e) => {
                                            loanModalForm[e.target.name] = parseFloat(e.target.value);
                                        }}
                                        placeholder={loanModalLoan[APR] + "%"}
                                        size='sm'
                                    ></Input> :
                                    <Text>
                                        {loanModalLoan[APR]}%
                                    </Text>
                                }
                            </Stack>
                            <Stack gap={1}>
                                <Text fontSize='xs' color='gray'>
                                    POINTS
                                </Text>
                                {
                                    editLoan ? 
                                    <Input 
                                        className="default_input loan_page__loan_modal__input"
                                        name={POINTS}
                                        onChange={(e) => {
                                            loanModalForm[e.target.name] = parseInt(e.target.value);
                                        }}
                                        placeholder={loanModalLoan[POINTS] + "%"}
                                        size='sm'
                                    ></Input> :
                                    <Text>
                                        {loanModalLoan[POINTS]}%
                                    </Text>
                                }
                            </Stack>
                        </Stack>
                        <Flex justifyContent='flex-end'>
                            <ButtonGroup>
                                {
                                    editLoan ?
                                    <>
                                        <Button
                                            className="negative_button hover"
                                            onMouseDown={() => setEditLoan(false)}
                                            size='sm' 
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onMouseDown={() => handleUpdateLoan(loanModalLoan, {...loanModalForm}, () => {
                                                setLoanModalLoan(null);
                                                setEditLoan(false);
                                                closeLoanModal();
                                            })}
                                            className="positive_button hover"
                                            size='sm'
                                        >
                                            Save
                                        </Button>
                                    </> : 
                                    <>
                                        <Button
                                            className="negative_button hover"
                                            onMouseDown={() => setEditLoan(true)}
                                            size='sm' 
                                        >
                                            Edit
                                        </Button>
                                        <Button 
                                            className="positive_button hover"
                                            leftIcon={<FaTrashAlt/>}
                                            onMouseDown={() => handleDeleteLoan(loanModalLoan)}
                                            size='sm'
                                        >
                                            Delete
                                        </Button>
                                    </>
                                }
                            </ButtonGroup>
                        </Flex>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default LoanModal;