import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { ping } from 'ldrs'

import AgentCard from './AgentCard.js';
import {
    Avatar,
    IconButton,
    ButtonGroup,
    Button,
    Heading,
    InputGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Textarea,
    useToast,
    PopoverTrigger,
    Popover,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Flex,
    Stack
} from '@chakra-ui/react';
import {
    FaArrowLeftLong,
    FaArrowRight,
    FaUsers,
    FaUserGroup,
    FaCircleInfo,
    FaUser,
    FaEyeSlash,
    FaArrowRightLong
} from "react-icons/fa6";
import {
    CONNECTION,
    CONNECTION_REQUEST_BY_BUYER_ACCEPTED,
    CONNECTION_REQUEST_BY_AGENT_ACCEPTED,
    CONNECTION_REQUEST_BY_AGENT_PENDING,
    CONNECTION_REQUEST_BY_BUYER_PENDING,
    CONNECTION_REMOVED_BY_BUYER,
    FIRST_NAME,
    LAST_NAME,
    GET,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_POST_TIMEOUT,
    NOT_INTERESTED_BY_BUYER,
    ID,
    POST,
    STATUS,
    STATUSES,
    FIRM,
    PROFILE_PICTURE_URL,
    NOT_INTERESTED_BY_BUYER_REMOVED,
} from '../../../utils/constants.js';

import { prettifyAgentRepresentation } from '../../../utils/utility.js';

import './Page.css';
import '../../Generic.css';
import './AgentsFeed.css';
import NoObjectsDisplay from '../../common/NoObjectsDisplay.js';
import ContactedByAgentModal from './ContactedByAgentModal.js';
import ConnectionsModal from './ConnectionsModal.js';

function AgentsFeed(props) {

    const navigate = useNavigate();

    const user = props.user;
    const userId = user[ID];
    const [showOffers, setShowOffers] = useState(props.showOffers);

    const handleAddConnection = props.handleAddConnection;
    const triggerFetchConnections = props.triggerFetchConnections;
    const setTriggerFetchConnections = props.setTriggerFetchConnections;

    const [agentsLoading, setAgentsLoading] = useState(true);
    const [agents, setAgents] = useState(null);

    const [agentIdToDefaultOfferMap, setAgentIdToDefaultOfferMap] = useState(null);

    const [triggerFetchAgents, setTriggerFetchAgents] = useState(false);
    
    const [contactAgent, setContactAgent] = useState(null);

    const [currentAgentCardIdx, setCurrentAgentCardIdx] = useState(0);
    const [agentCardLoading, setAgentCardLoading] = useState(false);

    const [connection, setConnections] = useState([]);
    const [connectedConnections, setConnectedConnections] = useState([]);
    const [requestedByAgentConnections, setRequestedByAgentConnections] = useState([]);
    const [contactedByBuyerConnections, setContactedByBuyerConnections] = useState([]);
    const [notInterestedConnections, setNotInterestedConnections] = useState([]);

    const [contactedByAgentsWithOffer, setContactedByAgentsWithOffer] = useState(null);
    const [contactedAgents, setContactedAgents] = useState(null);

    const [contactedByAgent, setContactedByAgent] = useState(null);

    const [showConnectionsModal, setShowConnectionsModal] = useState(props.showOffers);

    // Which page of agents to show the users.
    const [pageNumber, setPageNumber] = useState(1);

    const toast = useToast();
    ping.register();

    // Fetch our agents from the server for this buyer.
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/feed/agents/' + userId + "?page=" + pageNumber,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let agents = response.data;
            // No agents to show.
            if (agents === null) {
                setAgentsLoading(false);
                return;
            }
            setAgents(agents);
            
            let agentIds = [];
            for (let i = 0; i < agents.length; i++) {
                let agent = agents[i];
                agentIds.push(agent[ID]);
            }
            // Make a call to our server to fetch the default offers for every agent
            // we see.
            axios({
                method: POST,
                url: '/api/v1/user/buyer/agents/default_offers/' + userId,
                timeout: DEFAULT_POST_TIMEOUT,
                data: { agent_ids: agentIds }
            }).then(response => {
                if (200 === response.status) {
                    let defaultOffers = response.data;
                    let agentIdToDefaultOfferMap = new Map();
                    for (let i = 0; i < defaultOffers.length; i++) {
                        let defaultOffer = defaultOffers[i];
                        agentIdToDefaultOfferMap.set(defaultOffer["agent_id"], defaultOffer);
                    }
                    setAgentIdToDefaultOfferMap(agentIdToDefaultOfferMap);
                    setAgentsLoading(false);
                }
            }).catch(error => {
                // TODO
            });
        }).catch(error => {
            // TODO: Handle our error codes.
        })
    }, [pageNumber, userId, triggerFetchAgents]);

    useEffect(() =>{
        axios({
            method: GET,
            url: '/api/v1/user/connections/' + userId + '?user_type=Buyer&verbose=true',
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                let connections = response.data;
                setConnections(connections);

                let connectedConnections = [];
                let requestedByAgentConnections = [];
                let contactedByBuyerConnections = [];
                let notInterestedConnections = [];

                for (let i = 0; i < connections.length; i++) {
                    let connection = connections[i][CONNECTION];
                    let statuses = connection[STATUSES];
                    let lastStatus = statuses[statuses.length - 1];
                    let status = lastStatus[STATUS];
                    switch (status) {
                        case CONNECTION_REQUEST_BY_BUYER_ACCEPTED:
                        case CONNECTION_REQUEST_BY_AGENT_ACCEPTED:
                            connectedConnections.push(connection);
                            // setWorkingAgent(connection);
                            // setBuyersActiveAgent(connection);
                            break;
                        case CONNECTION_REQUEST_BY_AGENT_PENDING:
                            requestedByAgentConnections.push(connection);
                            break;
                        case CONNECTION_REQUEST_BY_BUYER_PENDING:
                            contactedByBuyerConnections.push(connection);
                            break;
                        case NOT_INTERESTED_BY_BUYER:
                        case CONNECTION_REMOVED_BY_BUYER:
                            notInterestedConnections.push(connection);
                            break;
                        default:
                            break;
                    }
                }
                setConnectedConnections(connectedConnections);
                setRequestedByAgentConnections(requestedByAgentConnections);
                setContactedByBuyerConnections(contactedByBuyerConnections);
                setNotInterestedConnections(notInterestedConnections);
                let defaultIndices = [];
                if (connectedConnections.length > 0) {
                    defaultIndices.push(0);
                }
                if (requestedByAgentConnections.length > 0) {
                    defaultIndices.push(1);
                }
                if (contactedByBuyerConnections.length > 0) {
                    defaultIndices.push(2);
                }
                // setPanelLoading(false);
            }
        }).catch(error => {
            // TODO
        })
    }, [userId, triggerFetchConnections]) // triggerFetchConnections

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/connections/agents/contacted/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            setContactedByAgentsWithOffer(response.data);
        }).catch(error => { 
            // TODO
        })
    }, [userId]);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/connections/contacted/agents/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            setContactedAgents(response.data);
        }).catch(error => {
            // TODO
        })
    }, [userId]);

    const setModalContactAgent = (agent) => {
        setContactAgent(agent);
    }

    const ContactAgentModal = () => {

        const [contactAgentTextareaValue, setContactAgentTextareaValue] = useState('');

        const handleSendConnect = () => {
            axios({
                method: POST,
                url: '/api/v1/user/buyer/agent/connect/' + userId,
                timeout: DEFAULT_GET_TIMEOUT,
                data: {
                    agent_id: contactAgent[ID],
                    buyer_id: userId,
                    message: contactAgentTextareaValue,
                }
            }).then(response => {
                if (200 === response.status) {
                    toast({
                        title: 'Request sent',
                        description: "We've sent " + contactAgent[FIRST_NAME] + " your request.",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        position: 'top'
                    });
                    setContactAgent(null);
                    setTriggerFetchAgents(!triggerFetchAgents);
                    setTriggerFetchConnections();
                }
            }).catch(error => {
                if (error.response.status === 500) {
                    toast({
                        title: 'Internal server error',
                        description: "We've encountered an internal server error. Try again later or reach out to us",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                        position: 'top'
                    });
                }
            })
        }

        let handleContactAgentTextareaChange = (e) => {
            let inputValue = e.target.value;
            setContactAgentTextareaValue(inputValue);
        }

        return (
            <Modal size='sm' isOpen={contactAgent !== null} onClose={() => setContactAgent(null)} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader style={{
                        paddingBottom: "0px",
                    }}>Send {props.contactAgent ? props.contactAgent[FIRST_NAME] : "them"}  a Contact!</ModalHeader>
                    <ModalCloseButton onClose={() => setContactAgent(null)} onMouseDown={() => setContactAgent(null)}/>
                    <ModalBody style={{
                        paddingTop: "0px",
                    }}>
                        <label style={{
                            color: "#a1a9ba",
                            fontSize: "0.8em",
                            paddingTop: "0px",
                        }}>
                            Reach out with a friendly message!
                        </label>
                        <InputGroup style={{
                            marginTop: "10px"
                        }}>
                            <Textarea
                                placeholder="Message..."
                                variant='outline'
                                resize='none'
                                size='sm'
                                height={150}
                                onChange={handleContactAgentTextareaChange}
                                style={{
                                    border: "1px solid #cccccc",
                                    borderRadius: "4px",
                                }}
                            >
                            </Textarea>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter style={{
                        paddingTop: "10px"
                    }}>
                        <Button className="negative_button hover" onMouseDown={() => setContactAgent(null)} mr={3} size='sm'>Cancel</Button>
                        <Button className="positive_button hover" size='sm' onMouseDown={() => handleSendConnect()}>
                            Send Note
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }

    const renderAgentCards = () => {
    
        if (agentCardLoading) {
            return (
                <div style={{
                    borderRadius: "12px",
                    height: "calc(100vh - 200px)",
                    position: "relative",
                    width: "100%",
                }}>
                    <div style={{
                        position: "absolute",
                        left: "calc(50% - 40px)",
                        top: "calc(50% - 40px)",
                    }}>
                        <l-ping
                            size="100"
                            speed="2" 
                            color="grey" 
                        ></l-ping>
                    </div>
                </div>
            )
        }

        let idx = currentAgentCardIdx;

        if (currentAgentCardIdx >= agents.length) {
            setCurrentAgentCardIdx(0);
            idx = 0;
        }

        if (currentAgentCardIdx < 0) {
            setCurrentAgentCardIdx(agents.length - 1);
            idx = agents.length - 1;
        }

        let agent = agents[idx];

        return (
            <div style={{
                width: "100%"
            }}>
                <AgentCard
                    key={"agent_card" + agent[ID]}
                    agent={agent}
                    user={user}
                    defaultOffer={agentIdToDefaultOfferMap.get(agent[ID])}
                    setContactAgent={setContactAgent}
                    handleAddConnection={props.handleAddConnection}
                    triggerFetchAgents={() => setTriggerFetchAgents(!triggerFetchAgents)}
                />
            </div>
        );
    }


    // const renderAgentOffers = () => {

    //     // No Contacted agents.
    //     if (!contactedByAgentsWithOffer || contactedByAgentsWithOffer.length === 0) {
    //         return (
    //             <div className="agents_feed__buyer_agents_panel__agent_card" style={{
    //                 display: "flex",
    //                 margin: "10px 0px 0px 0px",
    //                 padding: "15px 15px 15px 15px",
    //             }}>
    //                 <FaCircleInfo style={{
    //                     color: "#555555",
    //                     height: "12.5px",
    //                     width: "12.5px",
    //                 }}/>
    //                 <p style={{
    //                     color: "#555555",
    //                     fontSize: "0.8em",
    //                     lineHeight: "15px",
    //                     margin: "0px 0px 0px 7.5px",
    //                 }}>
    //                     Agents who contact you will appear here
    //                 </p>
    //             </div>
    //         )
    //     }

    //     let agents = [];

    //     for (let i = 0; i < contactedByAgentsWithOffer.length; i++) {
    //         let agent = contactedByAgentsWithOffer[i];
    //         agents.push(
    //             <div className="agents_feed__buyer_agents_panel__agent_card hover" onMouseDown={() => setContactedByAgent(agent)}>
    //                 <div style={{
    //                     display: "flex",
    //                     justifyContent: "space-between",
    //                 }}>
    //                     <div style={{
    //                         display: "flex",
    //                     }}>
    //                         <Avatar size='sm' src={agent && agent.profile_picture_url ? agent.profile_picture_url : null}></Avatar>
    //                         <div style={{
    //                             margin: "0px 0px 0px 10px",
    //                         }}>
    //                             <div style={{
    //                             }}>
    //                                 <p 
    //                                     className="agents_feed__buyer_agents_panel__agent_card__name"
    //                                     // onMouseDown={() => navigate("/user/buyer/" + userId + "/agent-profile/" + agent.agent_id)}
    //                                 >
    //                                     {agent[FIRST_NAME]} {agent[LAST_NAME]}
    //                                 </p>
    //                                 <p style={{
    //                                     color: "grey",
    //                                     fontSize: "0.8em",
    //                                 }}>
    //                                     {agent[FIRM] ? agent[FIRM] : "-"}
    //                                 </p>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div>
    //                         {/* <Button className="positive_button hover" size='xs'>
    //                             Accept
    //                         </Button> */}
    //                             {/* <p style={{
    //                                 color: "green",
    //                                 fontWeight: "bold"
    //                             }}>
    //                                 7.4
    //                             </p> */}
    //                     </div>
    //                 </div>
    //                 <div style={{
    //                     margin: "5px 0px 0px 0px",
    //                 }}>
    //                     <p style={{
    //                         fontSize: "0.8em",
    //                     }}>
    //                         {agent.offer_commission_percentage}% Commission | {prettifyAgentRepresentation(agent.offer_representation)} | {agent.offer_number_of_months} mo.
    //                     </p>
                        
    //                     {/* <p style={{
    //                         fontSize: "0.8em",
    //                     }}>
    //                         {agent.offer_is_exclusivity === true ? "Exclusive" : "Non-Exclusive"} | {agent.offer_representation} | Retainer: {agent.offer_retainer_part_of_commission === true ? "Yes" : "No"}
    //                     </p> */}
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return agents;
    // }

    // const renderContactedAgents = () => {
        
    //     // No Contacted agents.
    //     if (!contactedAgents || contactedAgents.length === 0) {
    //         return (
    //             <div className="agents_feed__buyer_agents_panel__agent_card" style={{
    //                 display: "flex",
    //                 padding: "25px 20px 25px 20px",
    //             }}>
    //                 <FaCircleInfo style={{
    //                     color: "#555555",
    //                     height: "12.5px",
    //                     width: "12.5px",
    //                 }}/>
    //                 <p style={{
    //                     color: "#555555",
    //                     fontSize: "0.8em",
    //                     lineHeight: "12.5px",
    //                     margin: "0px 0px 0px 7.5px",
    //                 }}>
    //                     Agents you Contact will appear here
    //                 </p>
    //             </div>
    //         )
    //     }
    //     let agents = [];
    //     for (let i = 0; i < contactedAgents.length; i++) {
    //         let agent = contactedAgents[i];
    //         agents.push(
    //             <div className="agents_feed__buyer_agents_panel__agent_card">
    //                 <div style={{
    //                     display: "flex",
    //                     justifyContent: "space-between",
    //                 }}>
    //                     <div style={{
    //                         display: "flex",
    //                     }}>
    //                         <Avatar size='sm' src={agent && agent.profile_picture_url ? agent.profile_picture_url : null}></Avatar>
    //                         <div style={{
    //                             margin: "0px 0px 0px 10px",
    //                         }}>
    //                             <p 
    //                                 className="agents_feed__buyer_agents_panel__agent_card__name"
    //                                 onMouseDown={() => navigate("/user/buyer/" + userId + "/agent-profile/" + agent.agent_id)}
    //                             >
    //                                 {agent[FIRST_NAME]} {agent[LAST_NAME]}
    //                             </p>
    //                             <p style={{
    //                                 color: "grey",
    //                                 fontSize: "0.8em",
    //                                 padding: "0px",
    //                             }}>
    //                                 {agent[FIRM] ? agent[FIRM] : "N/A"}
    //                             </p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return agents;
    // }

    // const renderNotInterestedAgents = () => {

    //     // No Contacted agents.
    //     if (!notInterestedConnections || notInterestedConnections.length === 0) {
    //         return (
    //             <div className="agents_feed__buyer_agents_panel__agent_card" style={{
    //                 display: "flex",
    //                 margin: "10px 0px 0px 0px",
    //                 padding: "15px",
    //             }}>
    //                 <FaCircleInfo style={{
    //                     color: "#555555",
    //                     height: "12.5px",
    //                     width: "12.5px",
    //                 }}/>
    //                 <p style={{
    //                     color: "#555555",
    //                     fontSize: "0.8em",
    //                     lineHeight: "12.5px",
    //                     margin: "0px 0px 0px 7.5px",
    //                 }}>
    //                     Not interested agents will appear here
    //                 </p>
    //             </div>
    //         );
    //     }

    //     let agents = [];

    //     for (let i = 0; i <notInterestedConnections.length; i++) {
    //         let agent = notInterestedConnections[i];
    //         agents.push(
    //             <div style={{
    //                 backgroundColor: "#efefef",
    //                 borderRadius: "12px",
    //                 margin: "10px 0px 0px 0px",
    //                 padding: "10px 10px 10px 10px",
    //             }}>
    //                 <div style={{
    //                     display: "flex",
    //                 }}>
    //                     <Avatar size='xs' src={agent ? agent[PROFILE_PICTURE_URL] : ""}/>
    //                     <div style={{
    //                         display: "flex",
    //                         justifyContent: "space-between",
    //                         width: "100%",
    //                     }}>
    //                         <div style={{
    //                             margin: "0px 0px 0px 7.5px",
    //                         }}>
    //                             <p style={{
    //                                 fontSize: "0.9em",
    //                                 lineHeight: "24px",
    //                             }}>
    //                                 {agent[FIRST_NAME]} {agent[LAST_NAME]}
    //                             </p>
    //                         </div>
    //                         <div>
    //                             <p className="agents_feed__not_interested_connections__unhide_button hover" onMouseDown={
    //                                 () => handleAddConnection(agent.id, NOT_INTERESTED_BY_BUYER_REMOVED, () => {})
    //                             }>
    //                                 Unhide
    //                             </p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         );
    //     }
    //     return agents;
    // }

    // const AgentPopover = () => {

    //     const TOGGLE_CONTACTED_AGENTS = 0;
    //     const TOGGLE_AGENT_OFFERS = 1;
    //     const TOGGLE_NOT_INTERESTED = 2;

    //     const [showContactedAgents, setShowContactedAgents] = useState(TOGGLE_CONTACTED_AGENTS);

    //     const renderBody = () => {
    //         switch (showContactedAgents) {
    //             case TOGGLE_CONTACTED_AGENTS: 
    //                 return renderContactedAgents();
    //             case TOGGLE_AGENT_OFFERS:
    //                 return renderAgentOffers();
    //             case TOGGLE_NOT_INTERESTED:
    //                 return renderNotInterestedAgents();
    //             default:
    //                 return;
    //         }
    //     }

    //     return (
    //         <Popover isOpen={showOffers} onOpen={() => setShowOffers(true)} onClose={() => setShowOffers(false)} placement="top-start" size='sm' style={{
    //             backgroundColor: "grey",
    //             width: "500px !important"
    //         }}>
    //             <PopoverTrigger>
    //                 <Button leftIcon={<FaUser/>} className="hover" size='sm' style={{
    //                     backgroundColor: "#e0e0e0",
    //                     borderRadius: "12px"
    //                 }}>
    //                     My Agents
    //                 </Button>
    //             </PopoverTrigger>
    //             <PopoverContent style={{
    //                 boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    //             }}>
    //                 <PopoverArrow />
    //                 <PopoverCloseButton />
    //                 <PopoverHeader style={{
    //                     borderBottom: "none",
    //                     fontSize: "1.3em",
    //                 }}><b>Agents</b></PopoverHeader>
    //                 <PopoverBody style={{
    //                     backgroundColor: "white",
    //                     borderRadius: "0px 0px 8px 8px",
    //                     padding: "0px 15px 15px 15px",
    //                     width: "500px !important"
    //                 }}>
    //                     <ButtonGroup>
    //                         <Button 
    //                             size='sm' 
    //                             className={TOGGLE_CONTACTED_AGENTS === showContactedAgents ? "positive_button_round hover" : "negative_grey_minimal_button button_round hover"}
    //                             onMouseDown={() => setShowContactedAgents(TOGGLE_CONTACTED_AGENTS)}
    //                         >
    //                             Contacted Agents
    //                         </Button>
    //                         <Button 
    //                             size='sm' 
    //                             className={TOGGLE_AGENT_OFFERS === showContactedAgents ? "positive_button_round hover" : "negative_grey_minimal_button button_round hover"}
    //                             onMouseDown={() => setShowContactedAgents(TOGGLE_AGENT_OFFERS)}
    //                         >
    //                             Agent Offers
    //                         </Button>
    //                         <IconButton
    //                             icon={<FaEyeSlash/>}
    //                             className={TOGGLE_NOT_INTERESTED === showContactedAgents ? "positive_button_round hover" : "negative_grey_minimal_button button_round hover"}
    //                             onMouseDown={() => setShowContactedAgents(TOGGLE_NOT_INTERESTED)}
    //                             size='sm'
    //                         />
    //                     </ButtonGroup>
    //                     {renderBody()}
    //                 </PopoverBody>
    //             </PopoverContent>
    //         </Popover>
    //     );
    // }

    if (agentsLoading) return;

    return (
        <>
            {
                agents && agents.length > 0 ?  
                <>
                    <Flex justifyContent='space-between'>
                        <Flex>
                            <Heading fontWeight='bold' fontSize='2xl'>
                                Agents
                            </Heading>
                        </Flex>
                        <Flex gap={2}>
                            <Button
                                className="hover"
                                size='sm'
                                onMouseDown={() => {
                                    setCurrentAgentCardIdx(currentAgentCardIdx-1)
                                }}
                                style={{
                                    backgroundColor: "#05b96b",
                                    borderRadius: "12px",
                                    color: "white",
                                }}
                                leftIcon={<FaArrowLeftLong/>}
                            >
                                Previous
                            </Button>
                            <Button
                                className="hover"
                                size='sm'
                                onMouseDown={() => {
                                    setShowConnectionsModal(true);
                                }}
                                style={{
                                    backgroundColor: "#e0e0e0",
                                    borderRadius: "12px",
                                    color: "black",
                                }}
                                leftIcon={<FaUserGroup/>}
                            >
                                My Agents
                            </Button>
                            <ConnectionsModal 
                                user={user}
                                showConnectionsModal={showConnectionsModal}
                                setShowConnectionsModal={setShowConnectionsModal}
                                contactedByAgentsWithOffer={contactedByAgentsWithOffer}
                                contactedAgents={contactedAgents}
                                notInterestedConnections={notInterestedConnections}
                                handleAddConnection={handleAddConnection}
                                setContactedByAgent={setContactedByAgent}
                            />
                            {/* <AgentPopover/> */}
                            <Button 
                                className="hover"
                                size='sm'
                                onMouseDown={() => {
                                    setAgentCardLoading(true);
                                    setTimeout(() => {
                                        setCurrentAgentCardIdx(currentAgentCardIdx+1)
                                        setAgentCardLoading(false);
                                    }, 750);
                                }}
                                style={{
                                    backgroundColor: "#05b96b",
                                    borderRadius: "12px",
                                    color: "white",
                                }}
                                rightIcon={<FaArrowRightLong/>}
                            >
                                Next
                            </Button>
                        </Flex>
                    </Flex>
                    
                    <Stack>
                        <ContactAgentModal/>
                        {contactedByAgent !== null ? 
                            <ContactedByAgentModal
                                handleAddConnection={handleAddConnection}
                                contactedByAgent={contactedByAgent}
                                setContactedByAgent={setContactedByAgent}
                                setShowConnectionsModal={setShowConnectionsModal}
                            /> : 
                        null}
                        <div style={{
                            margin: "25px 0px 0px 0px",
                        }}>
                            {renderAgentCards()}
                        </div>
                    </Stack>
                </> : 
                <NoObjectsDisplay
                    icon={FaUsers}
                    titleText={"NO AGENTS FOUND"}
                    subtitleText={"We're currently unable to find any agents. Check back later"}
                    buttonText={"Refresh"}
                    buttonCallback={() => navigate(0)}
                />
            }
        </>
    )

}

export default AgentsFeed;