import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Drawer,
    Link,
} from '@chakra-ui/react';
import { FaHouse, FaSignature, FaMessage, FaStar, FaUser, FaUsers, FaGear } from 'react-icons/fa6';

import {
    HOMES,
    LOANS,
    AGENTS,
    MESSAGES,
    PROFILE,
    FIRST_NAME,
    ID,
    LAST_NAME,
    SETTINGS,
} from '../../../utils/constants.js';

import './NavigationBar.css';

function BuyerNavigationBar(props) {

    const user = props.user;
    const userId = user[ID];
    const currTab = props.currTab;

    const getAccordionDefaultIndex = () => {
        switch (currTab) {
            case PROFILE:
                return [0];
        }
        return [-1];
    }

    return (
        <div className="buyer_navigation_bar__parent">
            <p className="buyer_navigation_bar__name_title">
                {user[FIRST_NAME]} {user[LAST_NAME]}
            </p>                    
            <label className="buyer_navigation_bar__label">
                DASHBOARD
            </label>
            
            <Accordion defaultIndex={getAccordionDefaultIndex()} allowMultiple style={{
                margin: "5px 0px 0px 0px",
            }}>
                <AccordionItem style={{
                    border: "none",
                }}>
                    <Link
                        href = {"/user/buyer/dashboard/agents/" + userId}
                        className={currTab === AGENTS ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <FaUsers className="buyer_navigation_bar_tab_icon" style={
                            currTab === AGENTS ? 
                            {
                                color: "#05b96b",
                            } :
                            {}}
                        />
                        <p className="buyer_navigation_bar_tab_text" style={
                            currTab === AGENTS ? 
                            {
                                color: "#05b96b",
                                fontWeight: "bold",
                            } :
                            {}}>
                            Agents
                        </p>
                    </Link>
                </AccordionItem>
                <AccordionItem className="buyer_navigation_bar__link_box" style={{
                    border: "none",
                }}>
                    <Link
                        href = {"/user/buyer/dashboard/homes/" + userId}
                        className={currTab === HOMES ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                        style={{
                            borderTop: "none",
                            textDecoration: "none",
                        }}
                    >
                        <FaHouse className="buyer_navigation_bar_tab_icon" style={
                            currTab === HOMES ? 
                            {
                                color: "#05b96b",
                            } :
                            {}}
                        />
                        <p className="buyer_navigation_bar_tab_text" style={
                            currTab === HOMES ? 
                            {
                                color: "#05b96b",
                                fontWeight: "bold"
                            } :
                            {}}>
                            Homes
                        </p>
                    </Link>
                </AccordionItem>
                <AccordionItem style={{
                    border: "none",
                }}>
                    <Link
                        href = {"/user/buyer/dashboard/loans/" + userId}
                        className={currTab === LOANS ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <FaSignature className="buyer_navigation_bar_tab_icon" style={
                            currTab === LOANS ? 
                            {
                                color: "#05b96b",
                            } :
                            {}}
                        />
                        <p className="buyer_navigation_bar_tab_text" style={
                            currTab === LOANS ? 
                            {
                                color: "#05b96b",
                                fontWeight: "bold",
                            } :
                            {}}>
                            Loans
                        </p>
                    </Link>
                </AccordionItem>
                <AccordionItem style={{
                    backgroundColor: "transparent",
                    border: "none",
                }}>
                    <Link
                        href={"/user/buyer/dashboard/messages/" + userId}
                        className={currTab === MESSAGES ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                        style={{
                            textDecoration: "none"
                        }}
                    >
                        <FaMessage className="buyer_navigation_bar_tab_icon" style={
                            currTab === MESSAGES ? 
                            {
                                color: "#05b96b",
                            } :
                            {}}
                        />
                        <Box as="span" flex='1' textAlign='left' className="buyer_navigation_bar_tab_text" style={
                            currTab === MESSAGES ? 
                            {
                                color: "#05b96b",
                                fontWeight: "bold"
                            } :
                            {}}>
                            Messages
                        </Box>
                    </Link>
                </AccordionItem>
                <AccordionItem style={{
                    border: "none",
                }}>
                    <Link
                        href={"/user/buyer/dashboard/profile/" + userId}
                        className={currTab === PROFILE ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <FaUser className="buyer_navigation_bar_tab_icon" style={
                            currTab === PROFILE ? 
                            {
                                color: "#05b96b",
                            } :
                            {}}
                        />
                        <p className="buyer_navigation_bar_tab_text" style={
                            currTab === PROFILE ? 
                            {
                                color: "#05b96b",
                                fontWeight: "bold",
                            } :
                            {}}>
                            Profile
                        </p>
                    </Link>
                </AccordionItem>
                <AccordionItem style={{
                    border: "none",
                }}>
                    <Link
                        href = {"/user/settings/" + userId + "?user_type=Buyer"}
                        className={currTab === SETTINGS ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <FaGear className="buyer_navigation_bar_tab_icon" style={
                            currTab === SETTINGS ? 
                            {
                                color: "#05b96b",
                            } :
                            {}}
                        />
                        <p className="buyer_navigation_bar_tab_text" style={
                            currTab === SETTINGS ? 
                            {
                                color: "#05b96b",
                                fontWeight: "bold",
                            } :
                            {}}>
                            Settings
                        </p>
                    </Link>
                </AccordionItem>
            </Accordion>
        </div>
    );

}

export default BuyerNavigationBar;