import React, { useState } from 'react';
import axios from 'axios';
import {
    Button,
    IconButton,
    Input,
    Select,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Stepper,
    StepIndicator,
    StepIcon,
    StepNumber,
    StepTitle,
    StepSeparator,
    StepStatus,
    Stack,
    Step,
    Box,
    Switch,
    Textarea,
    Tabs,
    TabPanels,
    TabPanel,
    Tooltip,
    useSteps,
    useToast,
} from '@chakra-ui/react';

import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

import {
    FIRST_NAME,
    ID,
    POST,
    DEFAULT_POST_TIMEOUT,
    COMMISSION_PERCENTAGE,
    NUMBER_OF_MONTHS,
    RETAINER_FEE_DOLLARS,
    REPRESENTATION,
    IS_EXCLUSIVE,
} from '../../../utils/constants.js';

import './Page.css';
import '../../Generic.css';

function ContactLeadModal(props) {

    const userId = props.userId;
    const lead = props.lead;
    const defaultOfferForm = props.defaultOfferForm;
    const showContactLeadModal = props.showContactLeadModal;
    const setShowContactLeadModal = props.setShowContactLeadModal;
    const setTriggerFetchConnections = props.setTriggerFetchConnections;
    const setTriggerFetchLeads = props.setTriggerFetchLeads;

    const [submitOfferLoading, setSubmitOfferLoading] = useState(false);
    const [contactLeadTextareaValue, setContactLeadTextareaValue] = useState('');
    const [requestToConnectTabIndex, setRequestToConnectTabIndex] = useState(0);
    const [connectionUseDefaultOffer, setConnectionUseDefaultOffer] = useState(false);

    const [connectionOfferForm, setConnectionOfferForm] = useState([]);
    const [connectionOfferFormCopy, setConnectionOfferFormCopy] = useState([]);

    const [commissionError, setCommissionError] = useState(null);
    const [numberOfMonthsError, setNumberOfMonthsError] = useState(null);
    const [retainerFeeError, setRetainerFeeError] = useState(null);
    const [representationError, setRepresentationError] = useState(null);
    const [retainerPartOfCommissionError, setRetainerPartOfCommissionError] = useState(null);
    const [exclusivityError, setExclusivityError] = useState(null);

    const toast = useToast();

    const steps = [
        { title: 'Offer', description: '' },
        { title: 'Add a Message', description: '' },
    ];

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,  
    });

    let handleContactLeadTextareaChange = (e) => {
        let inputValue = e.target.value;
        setContactLeadTextareaValue(inputValue);
    }

    const checkAndAlertOfferFormTabOneErrors = () => {

        if (!connectionOfferForm) {
            return true;
        }

        var hasErrors = false;

        // Check commission errors.
        if (!connectionOfferForm[COMMISSION_PERCENTAGE]) {
            hasErrors = true;
            setCommissionError("Required");
        } else if (parseFloat(connectionOfferForm[COMMISSION_PERCENTAGE]) >= 100.0 ||
                parseFloat(connectionOfferForm[COMMISSION_PERCENTAGE]) < 0.0) {
            hasErrors = true;
            setCommissionError("Invalid percentage")
        }

        if (!connectionOfferForm[NUMBER_OF_MONTHS]) {
            hasErrors = true;
            setNumberOfMonthsError("Required");
        } else if (parseInt(connectionOfferForm[NUMBER_OF_MONTHS]) < 1) {
            hasErrors = true;
            setNumberOfMonthsError("Cannot be 0");
        } else if (parseInt(connectionOfferForm[NUMBER_OF_MONTHS] > 24)) {
            hasErrors = true;
            setNumberOfMonthsError("Cannot be more than 24");
        }

        if (!connectionOfferForm[RETAINER_FEE_DOLLARS]) {
            hasErrors = true;
            setRetainerFeeError("Required");
        } else if (parseFloat(connectionOfferForm[RETAINER_FEE_DOLLARS]) > 1000000.0) {
            hasErrors = true;
            setRetainerFeeError("Cannot be more than 1,000,000");
        } else if (parseFloat(connectionOfferForm[RETAINER_FEE_DOLLARS]) < 0.0) {
            hasErrors = true;
            setRetainerFeeError("Cannot be negative");
        }

        if (!connectionOfferForm[REPRESENTATION] || connectionOfferForm[REPRESENTATION].length === 0) {
            hasErrors = true;
            setRepresentationError("Required");
        }

        // if (connectionOfferForm[RETAINER_PART_OF_COMMISSION] === null || 
        //         connectionOfferForm[RETAINER_PART_OF_COMMISSION] === undefined || 
        //         connectionOfferForm[RETAINER_PART_OF_COMMISSION].length === 0) {
        //     hasErrors = true;
        //     setRetainerPartOfCommissionError("Required");
        // }

        if (connectionOfferForm[IS_EXCLUSIVE] === null || 
                connectionOfferForm[IS_EXCLUSIVE] === undefined ||
                connectionOfferForm[IS_EXCLUSIVE].length === 0){
            hasErrors = true;
            setExclusivityError("Required");
        }

        return hasErrors;
    }

    const handleMakeOfferSubmit = (buyerId) => {

        // if (checkAndAlertOfferFormErrors()) {
        //     return;
        // }
        // If textarea is empty, display an error to user.
        if (0 === contactLeadTextareaValue.length) {
            toast({
                title: 'Empty message body',
                description: "Please send a message with your connect request",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
            setSubmitOfferLoading(false);
            return;
        }

        // Make our POST request.
        axios({
            method: POST,
            url: '/api/v1/user/agent/offer/' + userId,
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                agent_id: userId,
                buyer_id: buyerId,
                offer: {
                    commission_percentage: parseFloat(connectionOfferForm[COMMISSION_PERCENTAGE]),
                    number_of_months: parseInt(connectionOfferForm[NUMBER_OF_MONTHS]),
                    retainer_fee_dollars: parseInt(connectionOfferForm[RETAINER_FEE_DOLLARS]),
                    // retainer_part_of_commission: connectionOfferForm[RETAINER_PART_OF_COMMISSION] === true,
                    representation: connectionOfferForm[REPRESENTATION],
                    is_exclusive: connectionOfferForm[IS_EXCLUSIVE] === true
                },
                message: contactLeadTextareaValue,
            }
        }).then(response => {
            // Successful response. Close the modal and load the property. Also
            // reset our form.
            if (response.status === 200) {
                toast({
                    title: 'Request sent',
                    description: "We've sent " + lead[FIRST_NAME] + " your offer.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                setTriggerFetchConnections();
                setTriggerFetchLeads();
                setShowContactLeadModal(false);
                setContactLeadTextareaValue('');
                setSubmitOfferLoading(false);
            }
        }).catch(error => {
            if (error.response.status === 500) {
                toast({
                    title: 'Internal server error',
                    description: "We've encountered an internal server error. Try again later or reach out to us",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
            setSubmitOfferLoading(false);
        });
    }

    const handleConnectionOfferFormChange = (event) => {

        if (event.target.name === COMMISSION_PERCENTAGE) {
            setCommissionError(null);
        }
        if (event.target.name === NUMBER_OF_MONTHS) {
            setNumberOfMonthsError(null);
        }
        if (event.target.name === REPRESENTATION) {
            setRepresentationError(null);
        }
        // if (event.target.name === RETAINER_PART_OF_COMMISSION) {
        //     setRetainerPartOfCommissionError(null);
        // }
        if (event.target.name === IS_EXCLUSIVE) {
            setExclusivityError(null);
        }

        // Need to copy over into a new object or state will
        // not update the value of our input.
        const newConnectionOfferForm = {...connectionOfferForm};
        newConnectionOfferForm[event.target.name] = event.target.value;
        setConnectionOfferForm(newConnectionOfferForm);
        // If this is checked, dont copy over as we dont want to
        // maintain the changes we make while default is checked.
        if (!connectionUseDefaultOffer) {
            setConnectionOfferFormCopy(newConnectionOfferForm);
        }
    }

    const handlePercentageConnectionOfferFormChange = (event) => {
        if (event.target.name === COMMISSION_PERCENTAGE) {
            setCommissionError(null);
        }

        // Need to copy over into a new object or state will
        // not update the value of our input.
        event.target.value = event.target.value.replace(/[^0-9 \, \.]/, '');
        let val = event.target.value;
        if (event.target.value.length > 5) {
            event.target.value = val.substring(0, 5);
        }
        const newConnectionOfferForm = {...connectionOfferForm};
        newConnectionOfferForm[event.target.name] = event.target.value;
        setConnectionOfferForm(newConnectionOfferForm);
        // If this is checked, dont copy over as we dont want to
        // maintain the changes we make while default is checked.
        if (!connectionUseDefaultOffer) {
            setConnectionOfferFormCopy(newConnectionOfferForm);
        }
    }

    const handleDollarFormChange = (event) => {

        if (event.target.name === RETAINER_FEE_DOLLARS) {
            setRetainerFeeError(null);
        }
        // event.target.value = event.target.value.replace(/[^0-9 \,]/, '');
        // event.target.value = event.target.value.replaceAll(/,/g, '');
        // // TODO: Does not support currencies that uses commas as decimal point.
        // connectionOfferForm[event.target.name] = parseFloat(event.target.value.replace(',', ''));
        // event.target.value = dollarfyPrice(event.target.value);


        // Need to copy over into a new object or state will
        // not update the value of our input.
        const newConnectionOfferForm = {...connectionOfferForm};
        event.target.value = event.target.value.replace(/[^0-9 \,]/, '');
        event.target.value = event.target.value.replaceAll(/,/g, '');
        newConnectionOfferForm[event.target.name] = event.target.value;
        setConnectionOfferForm(newConnectionOfferForm);
        // If this is checked, dont copy over as we dont want to
        // maintain the changes we make while default is checked.
        if (!connectionUseDefaultOffer) {
            setConnectionOfferFormCopy(newConnectionOfferForm);
        }

        return;
    } 

    return (
        <Modal size='sm' isOpen={showContactLeadModal} onClose={() => {
            setShowContactLeadModal(false);
            setConnectionOfferForm([]);
            setConnectionOfferFormCopy([]);
        }} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "0px",
                }}>Make {lead[FIRST_NAME]} a Buyer's agent offer</ModalHeader>
                <ModalCloseButton onClose={() => {
                    setShowContactLeadModal(false)
                    setActiveStep(0);
                    setRequestToConnectTabIndex(0);
                    setConnectionOfferForm([]);
                    setConnectionOfferFormCopy([]);
                }} onMouseDown={() => {
                    setShowContactLeadModal(false);
                    setActiveStep(0);
                    setRequestToConnectTabIndex(0);
                    setConnectionOfferForm([]);
                    setConnectionOfferFormCopy([]);
                }}/>
                <ModalBody style={{
                    paddingBottom: "20px",
                    paddingTop: "0",
                }}>
                    <Stepper colorScheme='green' index={activeStep} style={{
                        margin: "20px calc((100% - 240px)/2) 15px calc((100% - 240px)/2",
                        width: "240px",
                    }} size='sm'>
                        {steps.map((step, index) => (
                            <Step key={index} onClick={() => {
                                setActiveStep(index);
                                setRequestToConnectTabIndex(index);
                            }}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon/>}
                                        incomplete={<StepNumber/>}
                                        active={<StepNumber/>}
                                    />
                                </StepIndicator>
                                <Box flexShrink='0'>
                                    <StepTitle style={{
                                        fontSize: "0.9em",
                                    }}>{step.title}</StepTitle>
                                </Box>
                                <StepSeparator/>
                            </Step>
                        ))}
                    </Stepper>
                    <Tabs index={requestToConnectTabIndex} onChange={(index) => setRequestToConnectTabIndex(index)}>
                        <TabPanels>
                            <TabPanel style={{
                                padding: "0px",
                            }}>
                                <label style={{
                                    color: "grey",
                                    fontSize: "0.9em",
                                }}>
                                    Make a competitive offer to stand out to {lead[FIRST_NAME]}
                                </label>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "5px 0px 0px 0px",
                                    width: "100%",
                                }}>
                                    <div style={{
                                        width: "calc(50% - 10px)"
                                    }}>
                                        <label style={{
                                            color: "grey",
                                            fontSize: "0.6em",
                                        }}>
                                            COMMISSION %
                                        </label>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={commissionError}
                                            isOpen={commissionError !== null}
                                            placement='top-end'
                                        >
                                            <Input 
                                                size='sm'
                                                className="default_input"
                                                placeholder={'%'} 
                                                value={connectionOfferForm && connectionOfferForm[COMMISSION_PERCENTAGE] !== undefined ? connectionOfferForm[COMMISSION_PERCENTAGE] : ''}
                                                onChange={handlePercentageConnectionOfferFormChange}
                                                name={COMMISSION_PERCENTAGE}
                                                type='text' 
                                            >
                                            </Input>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        width: "calc(50% - 10px)"
                                    }}>
                                        <label style={{
                                            color: "grey",
                                            fontSize: "0.6em",
                                        }}>
                                            NUMBER OF MONTHS
                                        </label>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={numberOfMonthsError}
                                            isOpen={numberOfMonthsError !== null}
                                            placement='top-end'
                                        >
                                            <Input
                                                size='sm'
                                                className="default_input"
                                                placeholder={'1'}
                                                value={connectionOfferForm && connectionOfferForm[NUMBER_OF_MONTHS] !== undefined ? connectionOfferForm[NUMBER_OF_MONTHS] : ''}
                                                onChange={handleConnectionOfferFormChange}
                                                name={NUMBER_OF_MONTHS}
                                                type='text'
                                            >
                                            </Input>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "5px 0px 0px 0px",
                                    width: "100%",
                                }}>
                                    <div style={{
                                        width: "calc(50% - 10px)"
                                    }}>
                                        <label style={{
                                            color: "grey",
                                            fontSize: "0.6em",
                                        }}>
                                            RETAINER FEE
                                        </label>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={retainerFeeError}
                                            isOpen={retainerFeeError !== null}
                                            placement='top-end'
                                        >
                                            <Input
                                                size='sm'
                                                className="default_input"
                                                placeholder={'$'}
                                                value={connectionOfferForm && connectionOfferForm[RETAINER_FEE_DOLLARS] !== undefined ? connectionOfferForm[RETAINER_FEE_DOLLARS] : null}
                                                onChange={handleDollarFormChange}
                                                name={RETAINER_FEE_DOLLARS}
                                                type='text' 
                                            >
                                            </Input>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        width: "calc(50% - 10px)"
                                    }}>
                                        <label style={{
                                            color: "grey",
                                            fontSize: "0.6em",
                                        }}>
                                            REPRESENTATION
                                        </label>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={representationError}
                                            isOpen={representationError !== null}
                                            placement='top-end'
                                        >
                                            <Select
                                                size='sm'
                                                className="default_input"
                                                value={connectionOfferForm && connectionOfferForm[REPRESENTATION] !== undefined ? connectionOfferForm[REPRESENTATION] : ''}
                                                onChange={handleConnectionOfferFormChange}
                                                name={REPRESENTATION}
                                            >
                                                <option value='' hidden disabled>-</option>
                                                <option value='ClientFiduciary'>Client (Fiduciary)</option>
                                                <option value='Customer(NonFiduciary)'>Customer (Non-Fiduciary)</option>
                                            </Select>
                                        </Tooltip>
                                    </div>
                                </div>
                                {/* <div style={{
                                    margin: "5px 0px 0px 0px",
                                    width: "100%",
                                }}>
                                    <label style={{
                                        color: "grey",
                                        fontSize: "0.6em",
                                    }}>
                                        RETAINER PART OF COMMISSION
                                    </label>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={retainerPartOfCommissionError}
                                        isOpen={retainerPartOfCommissionError !== null}
                                        placement='top-end'
                                    >
                                        <Stack style={{
                                            width: "100%"
                                        }}>
                                            <Select 
                                                size='sm'
                                                className="default_input"
                                                value={connectionOfferForm && connectionOfferForm[RETAINER_PART_OF_COMMISSION] !== undefined ? connectionOfferForm[RETAINER_PART_OF_COMMISSION]: ''}
                                                onChange={handleConnectionOfferFormChange}
                                                name={RETAINER_PART_OF_COMMISSION}
                                            >
                                                <option value='' hidden disabled>-</option>
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </Select>
                                        </Stack>
                                    </Tooltip>
                                </div> */}
                                <div style={{
                                    margin: "5px 0px 0px 0px",
                                    width: "100%",
                                }}>
                                    <label style={{
                                        color: "grey",
                                        fontSize: "0.6em",
                                    }}>
                                        EXCLUSIVITY
                                    </label>
                                    <Tooltip
                                        bg='red'
                                        hasArrow
                                        label={exclusivityError}
                                        isOpen={exclusivityError !== null}
                                        placement='top-end'
                                    >
                                        <Stack style={{
                                            width: "100%",
                                        }}>
                                            <Select
                                                size='sm'
                                                value={connectionOfferForm && connectionOfferForm[IS_EXCLUSIVE] !== undefined ? connectionOfferForm[IS_EXCLUSIVE] : ''}
                                                onChange={handleConnectionOfferFormChange}
                                                name={IS_EXCLUSIVE}
                                                className="default_input"
                                            >
                                                <option value='' hidden disabled>-</option>
                                                <option value={true}>Exclusive</option>
                                                <option value={false}>Non-Exclusive</option>
                                            </Select>
                                        </Stack>
                                    </Tooltip>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "12.5px 0px 0px 0px",
                                    width: "100%"
                                }}>
                                    <div>
                                        <Switch size='md' id="make_lead_an_offer_use_default_toggle" onChange={(e) => {
                                            setConnectionUseDefaultOffer(e.target.checked);
                                            if (e.target.checked) {
                                                setCommissionError(null);
                                                setNumberOfMonthsError(null);
                                                setRetainerFeeError(null);
                                                setRepresentationError(null);
                                                setRetainerPartOfCommissionError(null);
                                                setExclusivityError(null);
                                                setConnectionOfferForm(defaultOfferForm);
                                            } else {
                                                setConnectionOfferForm(connectionOfferFormCopy);
                                            }
                                        }}/>
                                        <label style={{
                                            color: "grey",
                                            fontSize: "0.8em",
                                            margin: "0px 0px 0px 7.5px",
                                        }}>
                                            Use default offer
                                        </label>
                                    </div>
                                    <IconButton 
                                        className="hover"
                                        size='sm' style={{
                                        backgroundColor: "#05b96b",
                                        color: "white",
                                        borderRadius: "50px",
                                        margin: "15px 0px 0px 0px",
                                    }} icon={<ArrowForwardIcon/>} isRound={true} onMouseDown={() => {
                                        if (checkAndAlertOfferFormTabOneErrors()) {
                                            return;
                                        }
                                        setRequestToConnectTabIndex(1);
                                        setActiveStep(1);
                                    }}>
                                    </IconButton>
                                </div>
                            </TabPanel>
                            <TabPanel style={{
                                padding: "0px",
                            }}>
                                <label style={{
                                    color: "grey",
                                    fontSize: "0.9em",
                                    margin: "0px 0px 0px 0px",
                                    padding: "0px 0px 0px 0px",
                                }}>
                                    Sending a personalized message will help you stand out.
                                </label>
                                <Textarea
                                    maxLength={1000}
                                    onChange={handleContactLeadTextareaChange}
                                    placeholder="Include your message here..."
                                    resize={"none"}
                                    style={{
                                        border: "1px solid #cccccc",
                                        borderRadius: "4px",
                                        fontSize: "0.9em",
                                        height: "198px",
                                        margin: "10px 0px 0px 0px",
                                    }}
                                />
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.8em",
                                    float: "right",
                                    margin: "5px 0px 0px 0px",
                                }}>
                                    {1000 - parseInt(contactLeadTextareaValue.length)} / 1000 characters left
                                </p>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "40px 0px 0px 0px",
                                    width: "100%"
                                }}>
                                    <IconButton className="hover" size='sm' style={{
                                        backgroundColor: "#05b96b",
                                        color: "white",
                                    }} icon={<ArrowBackIcon/>} isRound={true} onMouseDown={() => {
                                        setRequestToConnectTabIndex(0);
                                        setActiveStep(0);
                                    }}>
                                    </IconButton>
                                    <Button
                                        className="positive_button hover"
                                        onMouseDown={() => {
                                            setSubmitOfferLoading(true);
                                            // recieverId is lead[ID]
                                            handleMakeOfferSubmit(lead[ID])
                                        }}
                                        size="sm"
                                        style={submitOfferLoading ? {
                                            opacity: "0.7"
                                        } : {}}
                                    >
                                        {
                                            submitOfferLoading ?
                                                // Default values shown
                                            <l-dot-pulse
                                                size="25"
                                                speed="1.3" 
                                                color="white" 
                                            ></l-dot-pulse> :
                                            "Submit offer"
                                        }
                                    </Button>
                                </div>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ContactLeadModal;