import React from 'react';
import {
    ADDRESS_CITY,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    PURCHASE_PRICE,
    REPRESENTATION,
    TRANSACTION_DATE,
} from '../../../../utils/constants.js';

import { prettifyDate } from '../../../../utils/utility.js';

import { FaBolt, FaCircleInfo } from 'react-icons/fa6';
import { MdEdit } from "react-icons/md";

import { dollarfyPrice } from '../../../../utils/utility.js';
import { Center } from '@chakra-ui/react';

function TransactionsTable(props) {

    const transactions = props.transactions;
    const readOnlyView = props.readOnlyView;
    const maxTransactionsToDisplay = props.maxTransactionsToDisplay;
    const setTransaction = props.setTransaction;

    if (!transactions || transactions.length === 0) {
        return (
            <div style={{
                backgroundColor: "#f4f4f4",
                borderRadius: "8px",
                padding: "20px 0px 20px 0px",
            }}>
                <Center>
                    <FaCircleInfo style={{
                        color: "grey",
                        height: "12.5px",
                        width: "12.5px",
                    }}/>
                    <p style={{
                        color: "grey",
                        fontSize: "0.9em",
                        margin: "0px 0px 0px 7.5px",
                        textAlign: "center",
                    }}>
                        This agent does not have any existing transactions yet
                    </p>
                </Center>
            </div>
        );
    }

    let tableRows = [];
    for (let i = 0; i < transactions.length; i++) {
        let transaction = transactions[i];
        tableRows.push(
            <div 
                onMouseDown={() => {
                    if (readOnlyView) return;
                    setTransaction(transaction)
                }}
                style={{
                    cursor: readOnlyView ? "" : "pointer",
                    display: "flex",
                    padding: "7.5px 0px 7.5px 0px",
                }}
            >
                <FaBolt style={{
                    height: "15px",
                    marginLeft: "25px",
                    margin: "5px 0px 5px 25px",
                    width: "15px",
                }}/>
                <p style={{
                    marginLeft: "25px",
                    width: "calc(55% - 15px - 20px - 25px - 20px)",
                }}>
                    {
                        // readOnlyView can't show full address. Show a subset.
                        readOnlyView ?
                        transaction[ADDRESS_CITY] + ", " + transaction[ADDRESS_STATE] :
                        transaction[ADDRESS_LINE_ONE] + " " + transaction[ADDRESS_LINE_TWO] + " " + transaction[ADDRESS_CITY] + ", " + transaction[ADDRESS_STATE] + " " + transaction[ADDRESS_ZIP_CODE]
                    }
                </p>
                <p style={{
                    width: "15%",
                }}>{prettifyDate(transaction[TRANSACTION_DATE])}</p>
                <p style={{
                    width: "15%",
                }}>${dollarfyPrice(transaction[PURCHASE_PRICE])}</p>
                <p style={{
                    marginRight: "25px",
                    width: "15%",
                }}>{transaction[REPRESENTATION]}</p>
                {
                    readOnlyView === true ?
                    null :
                    <MdEdit style={{
                        height: "20px",
                        marginRight: "25px",
                        width: "20px",
                    }}/>
                }
                
            </div>
        );
    }

    return (
        <div style={{marginTop: "10px"}}>
            <div style={{
                display: "flex",
                margin: "auto",
            }}>
                <div style={{
                    height: "15px",
                    marginLeft: "25px",
                    margin: "0px 0px 0px 25px",
                    width: "15px",
                }}/>
                <p style={{
                    color: "grey",
                    fontSize: "0.7em",
                    marginLeft: "25px",
                    width: "calc(55% - 15px - 20px - 25px - 20px)",
                }}>ADDRESS</p>
                <p style={{
                    color: "grey",
                    fontSize: "0.7em",
                    width: "15%"
                }}>CLOSING DATE</p>
                <p style={{
                    color: "grey",
                    fontSize: "0.7em",
                    width: "15%"
                }}>SALES PRICE</p>
                <p style={{
                    color: "grey",
                    fontSize: "0.7em",
                    marginRight: "25px",
                    width: "15%"
                }}>REPRESENTED</p>
                {
                    readOnlyView ?
                    null :
                    <div style={{
                        height: "20px",
                        marginRight: "25px",
                        width: "20px",
                    }}/>
                }
                
            </div>
            <div style={{
                backgroundColor: "#f4f4f4",
                borderRadius: "12px",
                margin: "5px 0px 0px 0px",
                padding: "5px 0px 5px 0px",
            }}>
                {tableRows}
            </div>
        </div>
    );
}

export default TransactionsTable;